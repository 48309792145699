import { customCondition } from '@administration/codelists/codelists.interface';
import { Component, inject, ViewChild } from '@angular/core';
import { IconKey } from '@common/classes/icons';
import { CodelistInputComponent } from '@common/components/input/codelist-input/codelist-input.component';
import { FleetTypeCodes } from '@common/known-types/fleet-type.codes';
import { TranslateService } from '@ngx-translate/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

export interface DialogChangeBookingOptions {
    title?: string;
    titleIcons?: IconKey[];
    message?: string;
    cancelText?: string;
    cancelIcon?: IconKey;
    cancelAction?: Function;
    confirmText?: string;
    confirmIcon?: IconKey;
    confirmMessage?: string;
    model?: any[];
}

@Component({
    selector: 'app-dialog-change-booking',
    templateUrl: './dialog-change-booking.component.html'
})
export class DialogChangeBookingComponent extends DialogContentBase {
    override dialog = inject<DialogRef>(DialogRef);
    translateService = inject(TranslateService);

    model: any;
    options: DialogChangeBookingOptions = {
        title: this.translateService.instant('Change booking data'),
        message: ''
    };
    truckFilter = `FleetType:==:${FleetTypeCodes.Truck};`;
    trailerFilter = `FleetType:==:${FleetTypeCodes.Trailer};`;

    @ViewChild('driver') driver: CodelistInputComponent;
    @ViewChild('truck') truck: CodelistInputComponent;
    @ViewChild('trailer') trailer: CodelistInputComponent;

    public initialize(options: DialogChangeBookingOptions) {
        for (const [key, value] of Object.entries(options)) {
            if (![undefined, null].includes(value)) this.options[key] = value;
        }
        this.model = this.options.model;
    }

    confirm(): void {
        // if (this.appControls.some((x) => !!x.error)) {
        //     this.toastrNotificationService.show({ type: 'error', message: 'Some required fields are empty' });
        //     return;
        // }
        this.dialog.close(this.model);
    }

    close(result: boolean | undefined = undefined) {
        this.dialog.close(result);
    }

    public triggerDriverChange(_event: any): void {
        const filterValue = '';
        this.driver.customCondition = [
            {
                condition: customCondition.Equal,
                name: 'Organization',
                value: this.model.organizationId
            }
        ];
        this.driver.queryCodelistDebounced(filterValue);
        this.model.driverId = null;
    }

    public triggerPlateChange(_event: any): void {
        this.truck.customCondition = [
            {
                condition: customCondition.Equal,
                name: 'FleetType',
                value: FleetTypeCodes.Truck
            },
            {
                condition: customCondition.Equal,
                name: 'Organization',
                value: this.model.organizationId
            }
        ];
        this.trailer.customCondition = [
            {
                condition: customCondition.Equal,
                name: 'FleetType',
                value: FleetTypeCodes.Trailer
            },
            {
                condition: customCondition.Equal,
                name: 'Organization',
                value: this.model.organizationId
            }
        ];
        this.getData();
    }

    async getData() {
        //firefox kills query, NS_BINDING_ABORTED
        try {
            const filterValue = '';
            await this.trailer.queryCodelistDebounced(filterValue);
            await this.truck.queryCodelistDebounced(filterValue);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    }

    organizationCondition = () => {
        return [{ name: 'Organization', condition: customCondition.Equal, value: this.model.organizationId }];
    };
}
