import { Component, ViewEncapsulation, inject } from '@angular/core';
import { IconKey } from '@common/classes/icons';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { UploadActionButtonsComponent } from './upload-action-buttons.component';

export interface DialogErrorOptions {
    title: string;
    titleIcons?: IconKey[];
    message: string;
    stackTrace?: string;
    isServerSideError?: boolean;
    validationErrors?: any[];
}

@Component({
    selector: 'app-dialog-error',
    template: `
        <kendo-dialog-titlebar class="bg-background pb-5 pl-5 pr-3 pt-6 font-bold text-primary">
            <div class="flex w-full flex-row items-center gap-2">
                @for (icon of options?.titleIcons; track $index) {
                    <ng-icon [name]="icon" />
                }
                {{ options?.title | translate }}
                <div class="flex w-full justify-end">
                    <app-button
                        *ngIf="canDownload()"
                        variant="link"
                        icon="faSolidDownload"
                        [tooltip]="'Download' | translate"
                        (clicked)="download()" />
                </div>
            </div>
        </kendo-dialog-titlebar>
        <div class="flex h-full flex-col gap-4 overflow-hidden p-4">
            <div>
                <div class="mb-1 font-medium" translate>Error message</div>
                <div [innerHTML]="options.message"></div>
            </div>
            <div *ngIf="hasStackTrace()" class="grow overflow-scroll">
                <div class="mb-1 font-medium" translate>Stack trace</div>
                <pre class="h-full whitespace-pre-wrap p-4">{{ options?.stackTrace }}</pre>
            </div>
        </div>
        <kendo-dialog-actions class="px-4" layout="end">
            <app-button class="w-20" variant="primary" (clicked)="dialog.close(true)">
                {{ 'Ok' | translate }}
            </app-button>
        </kendo-dialog-actions>
    `,
    encapsulation: ViewEncapsulation.None
})
export class DialogErrorComponent extends DialogContentBase {
    override dialog = inject<DialogRef>(DialogRef);
    translateService = inject(TranslateService);
    options: DialogErrorOptions = {
        title: 'Error',
        message: 'An error occurred'
    };
    public initialize(options: DialogErrorOptions) {
        for (const [key, value] of Object.entries(options)) {
            if (![undefined, null].includes(value)) this.options[key] = value;
        }

        this.setMessage(this.options.message);
        this.setStackTrace(this.options.stackTrace);
    }

    close(result: boolean | undefined = undefined) {
        this.dialog.close(result);
    }

    get titleText() {
        return this.options.title || this.options?.isServerSideError
            ? this.translateService.instant('System Error')
            : this.translateService.instant('Client side error occurred');
    }

    hasStackTrace() {
        return !!this.options?.stackTrace && ['local', 'development'].includes(environment.name);
    }

    setMessage(message) {
        if (!message || typeof message !== 'string') {
            console.error(message);
            message = this.translateService.instant('Check the console for error details');
        }

        const lines = message.indexOf('\r\n') < 0 ? message.split('\n') : message.split('\r\n');
        let html = `<span> ${lines[0]}</span>`;

        // Client side error message contains also the stacktrace which we do not want to display
        if (lines.length === 1 || !this.options?.isServerSideError) {
            this.options.message = html;
            return;
        }

        html += '<br />';
        for (let i = 1; i < lines.length; i++) {
            html += `<span>${lines[i]}</span><br />`;
        }

        this.options.message = html;
    }

    setStackTrace(stackTrace) {
        if (stackTrace && typeof stackTrace !== 'string') {
            console.error(stackTrace);
            stackTrace = stackTrace.toString();
        }

        this.options.stackTrace = stackTrace;
    }

    canDownload() {
        // return !!this.options?.validationErrors;
        return true;
    }

    download() {
        let content = 'Property name;Error message\r\n';
        this.options?.validationErrors?.forEach((error) => {
            content += `${error.propertyName};${error.errorMessage}\r\n`;
        });

        UploadActionButtonsComponent.download('errors.csv', window.btoa(content));
    }
}
