<div *subscribe="query$ as query">
    <app-loader [isBusy]="query?.isLoading">
        <ng-template>
            @if (isDriver) {
                <app-panel-driver-button-view
                    [model]="model"
                    (confirm)="confirm()"
                    (changeTime)="changeTime()"
                    (changePlates)="changePlates()"
                    (addSecondDriver)="addSecondDriver()"
                    (mergeBooking)="mergeBooking()"
                    (unmergeBooking)="unmergeBooking()"
                    (cancel)="cancel()" />
            }
            <app-content-header [title]="getTitle()" [icon]="icon" [actionBar]="actionBar" />
            @if (viewMode) {
                <app-card>
                    <div class="grid grid-cols-12 gap-x-4 gap-y-2" setEditMode [isEditMode]="editMode">
                        <div class="col-span-3">
                            <app-codelist-input
                                label="Booking status"
                                id="bookingStatus"
                                codelist="BookingStatus"
                                [(value)]="model.bookingStatusId" />
                        </div>
                        <div class="col-span-3">
                            <app-datetime-input
                                label="Scheduled time"
                                id="timeFrom"
                                [isEditMode]="editMode"
                                [(value)]="model.timeFrom" />
                        </div>
                        <div class="col-span-3">
                            <app-text-input
                                label="Terminal"
                                id="terminal"
                                [isEditMode]="editMode"
                                [(value)]="model.terminal" />
                        </div>
                        <div class="col-span-1">
                            <app-boolean-input
                                label="Blocked"
                                id="blocked"
                                [isEditMode]="false"
                                [(value)]="model.blocked" />
                        </div>

                        <div class="col-span-3">
                            <app-codelist-input
                                label="Responsible haulier"
                                id="responsibleHaulier"
                                codelist="Organization"
                                [(value)]="model.responsibleHaulierId" />
                        </div>
                        <div class="col-span-3">
                            <app-codelist-input
                                label="Actual haulier"
                                id="actualHaulier"
                                codelist="Organization"
                                [(value)]="model.actualHaulierId" />
                        </div>
                        <div class="col-span-3">
                            <app-text-input
                                label="Driver"
                                id="driverId"
                                [isEditMode]="editMode"
                                [(value)]="model.driver" />
                        </div>
                        <div class="col-span-3">
                            <app-text-input
                                label="Additional driver"
                                id="additionalDriver"
                                [isEditMode]="editMode"
                                [(value)]="model.additionalDriver" />
                        </div>
                        <div class="col-span-3">
                            <app-text-input
                                label="Truck"
                                id="truckPlate"
                                [isEditMode]="editMode"
                                [(value)]="model.truckPlateNumber" />
                        </div>
                        <div class="col-span-3">
                            <app-text-input
                                label="Trailer"
                                id="trailerPlate"
                                [isEditMode]="editMode"
                                [(value)]="model.trailerPlateNumber" />
                        </div>
                    </div>
                </app-card>
                <app-truck-visit-bar [model]="model"></app-truck-visit-bar>
                <app-panel-booking-data
                    class="mt-2"
                    bookingDataTitle="Private cargo list"
                    [title]="'Linked bookings'"
                    [model]="model.bookings"
                    [dataColumns]="privateColumns"></app-panel-booking-data>
            } @else {
                <div class="flex gap-2">
                    <app-card class="w-2/5">
                        <div class="flex flex-col gap-6">
                            <h3 class="text-xl font-semibold text-foreground">
                                {{ 'Booking details' | translate }}
                            </h3>
                            <div class="grid grid-cols-4 gap-x-4 gap-y-2">
                                <div class="col-span-2">
                                    <app-datetime-input
                                        label="Date"
                                        id="lastModifiedDate"
                                        [isRequired]="true"
                                        [isEditMode]="editMode"
                                        [isDisabled]="!isPropertyEditable('slot')"
                                        [(value)]="model.timeFrom" />
                                </div>
                                <div class="col-span-2 col-start-1">
                                    <app-codelist-input
                                        label="Responsible haulier"
                                        #responsiblehaulier
                                        id="organization"
                                        codelist="Organization"
                                        labelFormat="LongCode"
                                        [isEditMode]="editMode"
                                        [isDisabled]="createMode"
                                        [(value)]="model.responsibleHaulierId" />
                                </div>
                                <div class="col-span-2">
                                    <app-codelist-input
                                        label="Actual haulier"
                                        #haulier
                                        id="organization"
                                        codelist="Organization"
                                        [isEditMode]="editMode"
                                        [isDisabled]="!isPropertyEditable('haulier')"
                                        [(value)]="model.actualHaulierId"
                                        (valueChange)="onHaulierChange($event)" />
                                </div>
                                <div class="col-span-2">
                                    <app-codelist-input
                                        label="Driver"
                                        #driver
                                        id="driver"
                                        codelist="Driver"
                                        [customCondition]="util.organizationCondition(model)"
                                        [isEditMode]="editMode"
                                        [isDisabled]="!isPropertyEditable('driver')"
                                        [(value)]="model.driverId"
                                        (valueChange)="onDriverChange($event)" />
                                </div>
                                <div class="col-span-2">
                                    <app-codelist-input
                                        label="Additional Driver"
                                        #driver
                                        id="additionalDriver"
                                        codelist="Driver"
                                        [isDisabled]="!isPropertyEditable('additionalDriver') && !model.driverId"
                                        [customCondition]="util.organizationCondition(model)"
                                        [isEditMode]="editMode"
                                        [(value)]="model.additionalDriverId" />
                                </div>
                                @if (selectedHaulier.isTemporary) {
                                    <div class="col-span-4 md:col-span-2">
                                        <app-text-input
                                            label="Truck"
                                            #truck
                                            id="truckTmp"
                                            [isDisabled]="!isPropertyEditable('truck') && !model.actualHaulierId"
                                            [isEditMode]="editMode"
                                            [(value)]="model.tempTruck" />
                                    </div>
                                    <div class="col-span-4 md:col-span-2">
                                        <app-text-input
                                            label="Trailer"
                                            id="trailerTmp"
                                            [isDisabled]="!isPropertyEditable('trailer') && !model.actualHaulierId"
                                            [isEditMode]="editMode"
                                            [(value)]="model.tempTrailer" />
                                    </div>
                                } @else {
                                    <div class="col-span-4 md:col-span-2">
                                        <app-codelist-input
                                            label="Truck"
                                            #truck
                                            id="truck"
                                            codelist="Fleet"
                                            [customCondition]="util.plateTruckCodelistCondition(model)"
                                            [isDisabled]="!isPropertyEditable('truck') && !model.actualHaulierId"
                                            [isEditMode]="editMode"
                                            [(value)]="model.truckId" />
                                    </div>
                                    <div class="col-span-4 md:col-span-2">
                                        <app-codelist-input
                                            label="Trailer"
                                            #trailer
                                            id="trailer"
                                            codelist="Fleet"
                                            [customCondition]="util.plateTrailerCodelistCondition(model)"
                                            [isDisabled]="!isPropertyEditable('trailer') && !model.actualHaulierId"
                                            [isEditMode]="editMode"
                                            [(value)]="model.trailerId" />
                                    </div>
                                }
                            </div>
                            <div class="flex w-full flex-wrap gap-2">
                                <h3 class="mb-2 text-xl font-semibold text-foreground">
                                    {{ 'Bookings' | translate }}
                                </h3>
                                <div class="w-full">
                                    @if (!model.bookings || model.bookings?.length === 0) {
                                        <div
                                            class="rounded-md border border-dashed border-muted p-3 text-center italic text-muted">
                                            {{ 'No bookings' | translate }}
                                        </div>
                                    } @else {
                                        <table class="w-full border-separate border-spacing-x-0.5 border-spacing-y-1">
                                            <tr class="text-xs text-neutral-400 *:px-3 *:text-start *:font-normal">
                                                <th translate>Type</th>
                                                <th translate>Booking</th>
                                                <th translate>Pin</th>
                                                <th translate>Status</th>
                                                <th></th>
                                            </tr>
                                            @for (item of model.bookings; track item) {
                                                <tr class="*:bg-background *:py-2">
                                                    <td class="rounded-bl-md rounded-tl-md px-3">
                                                        <ng-icon
                                                            class="text-lg text-muted"
                                                            [name]="util.showIconForPrivateCargoType(item)" />
                                                    </td>
                                                    <td class="px-3">
                                                        {{ item?.id }}
                                                    </td>
                                                    <td class="px-3">
                                                        {{ item?.pin }}
                                                    </td>
                                                    <td class="px-3">
                                                        {{ item?.bookingStatusId | codelist: 'BookingStatus' | async }}
                                                    </td>
                                                    <td class="w-5 rounded-br-md rounded-tr-md">
                                                        <app-button
                                                            class="min-w-fit px-3 shadow-none"
                                                            icon="faSolidXmark"
                                                            tooltip="Remove booking"
                                                            size="sm"
                                                            variant="link"
                                                            [disabled]="!isPropertyEditable('cargo')"
                                                            (click)="removeBooking(item)" />
                                                    </td>
                                                </tr>
                                            }
                                        </table>
                                    }
                                </div>
                            </div>
                        </div>
                    </app-card>
                    <app-card class="w-3/5">
                        <h3 class="mb-4 text-xl font-semibold text-foreground">
                            {{ 'Link to booking' | translate }}
                        </h3>
                        <div class="mb-2 grid grid-cols-12 gap-x-4 gap-y-2">
                            <div class="col-span-3">
                                <app-text-input
                                    label="Bookings"
                                    id="bookings"
                                    [isEditMode]="editMode"
                                    [(value)]="filter.bookingId" />
                            </div>
                            <div class="col-span-3">
                                <app-text-input label="Vin" id="vin" [isEditMode]="editMode" [(value)]="filter.vin" />
                            </div>
                            <div class="col-span-3">
                                <app-text-input
                                    label="Container"
                                    id="container"
                                    [isEditMode]="editMode"
                                    [(value)]="filter.container" />
                            </div>
                            <div class="col-span-3 flex items-end gap-4">
                                <div class="w-full">
                                    <app-text-input
                                        label="Pin"
                                        id="pin"
                                        [isEditMode]="editMode"
                                        [(value)]="filter.pin" />
                                </div>
                                <app-button
                                    class="min-w-fit p-2"
                                    icon="faSolidMagnifyingGlass"
                                    variant="primary"
                                    tooltip="Search for booking"
                                    size="sm"
                                    [disabled]="util.isFilterEmpty(filter)"
                                    (click)="search()" />
                            </div>
                        </div>
                        <app-grid
                            [data]="data"
                            [cols]="privateEditColumns"
                            [defaultViewMode]="defaultViewMode"
                            [queryData]="search"
                            [autoFitColumns]="false"
                            (saveGridSettings)="saveGridSettings($event)"
                            (deleteGridSettings)="deleteGridSettings()"></app-grid>
                    </app-card>
                </div>
            }
        </ng-template>
    </app-loader>
</div>
