export enum ContainerTypeCodes {
    AutoFrame = 'AFR',
    BulkContainer = 'BLK',
    CattFrame = 'CFR',
    PaletWide = 'DHV',
    DryBox = 'DRY',
    FlatRack = 'FRK',
    HardTop = 'HTP',
    Insulated = 'INS',
    OpenTop = 'OTP',
    Platform = 'PLT',
    Reefer = 'REF',
    SideOpen = 'SOP',
    TankContainer = 'TNK',
    Ventilated = 'VNT'
}
