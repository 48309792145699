import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BookingStatusCodes } from '@common/known-types/booking-status.codes';
import { BookingTypeCodes } from '@common/known-types/booking-type.codes';
import { CargoTypeCodes } from '@common/known-types/cargo-type.codes';

@Component({
    selector: 'app-panel-driver-button-view',
    templateUrl: './panel-driver-button-view.component.html'
})
export class PanelDriverButtonViewComponent {
    @Input() model: any;
    @Output() confirm = new EventEmitter<void>();
    @Output() changeTime = new EventEmitter<void>();
    @Output() changePlates = new EventEmitter<void>();
    @Output() addSecondDriver = new EventEmitter<void>();
    @Output() mergeBooking = new EventEmitter<void>();
    @Output() unmergeBooking = new EventEmitter<void>();
    @Output() cancel = new EventEmitter<void>();
    constructor() {}
    confirmVisible() {
        return true;
    }
    changeTimeVisible() {
        if (this.model.cargoTypeId == CargoTypeCodes.CAR) {
            //if any vehicle for loading('P') ChangeDate Disable
            return !this.model.vehicles?.some((item) => item.bookingType === BookingTypeCodes.PICK_UP);
        }

        if (this.model.terminal == 'CNT') {
            if (
                this.model.bookingStatusId == BookingStatusCodes.New ||
                this.model.bookingStatusId == BookingStatusCodes.Reserved ||
                this.model.bookingStatusId == BookingStatusCodes.Booked
            ) {
                return true;
            }
        }
        if (this.model.terminal == 'TA') {
            if (
                this.model.bookingTypeId == BookingTypeCodes.PICK_UP &&
                (this.model.bookingStatusId == BookingStatusCodes.New ||
                    this.model.bookingStatusId == BookingStatusCodes.Reserved ||
                    this.model.bookingStatusId == BookingStatusCodes.Booked)
            ) {
                return true;
            }
        }
        return false;
    }
    changePlatesVisible() {
        return !(
            this.model.bookingStatusId == BookingStatusCodes.OnTerminalFromInPort ||
            this.model.bookingStatusId == BookingStatusCodes.OnTerminalFromInPortDelayed ||
            this.model.bookingStatusId == BookingStatusCodes.Finished ||
            this.model.bookingStatusId == BookingStatusCodes.FinishedDelayed ||
            this.model.bookingStatusId == BookingStatusCodes.FinishedUnregistered ||
            this.model.bookingStatusId == BookingStatusCodes.FinishedDelayedUnregistered
        );
    }
    addSecondDriverVisible() {
        return !(
            this.model.bookingStatusId == BookingStatusCodes.OnTerminalFromInPort ||
            this.model.bookingStatusId == BookingStatusCodes.OnTerminalFromInPortDelayed ||
            this.model.bookingStatusId == BookingStatusCodes.Finished ||
            this.model.bookingStatusId == BookingStatusCodes.FinishedDelayed ||
            this.model.bookingStatusId == BookingStatusCodes.FinishedUnregistered ||
            this.model.bookingStatusId == BookingStatusCodes.FinishedDelayedUnregistered
        );
    }
    mergeBookingVisible() {
        return (
            this.model.terminal != 'EVN' &&
            (this.model.bookingStatusId == BookingStatusCodes.Reserved ||
                this.model.bookingStatusId == BookingStatusCodes.Booked)
        );
    }
    unmergeBookingVisible() {
        return (
            this.model.cargoTypeId == CargoTypeCodes.GENERAL_CARGO &&
            this.model.cargo == null &&
            (this.model.bookingStatusId == BookingStatusCodes.Reserved ||
                this.model.bookingStatusId == BookingStatusCodes.Booked)
        );
    }
    cancelVisible() {
        return true;
    }

    confirmBtn() {
        this.confirm.emit();
    }
    changeTimeBtn() {
        this.changeTime.emit();
    }
    changePlatesBtn() {
        this.changePlates.emit();
    }
    addSecondDriverBtn() {
        this.addSecondDriver.emit();
    }
    mergeBookingBtn() {
        this.mergeBooking.emit();
    }
    unmergeBookingBtn() {
        this.unmergeBooking.emit();
    }
    cancelBtn() {
        this.cancel.emit();
    }
}
