import { Component, Inject, Input } from '@angular/core';
import { CommonModule } from '@common/common.module';
import { GridCellContext, GridTemplateComponent } from '@common/interfaces/column-settings.interface';
import { ToastrNotificationService } from '@common/services/toastr-notification.service';
import { TranslateService } from '@ngx-translate/core';

export interface CheckButtonInputs {
    id: string;
}

@Component({
    standalone: true,
    imports: [CommonModule],
    template: `
        <app-button size="sm" variant="primary" tooltip="{{ 'Check status' | translate }}" (clicked)="checkStatus(id)">
            {{ 'Check' | translate }}
        </app-button>
    `
})
export class CellCheckButtonComponent implements GridTemplateComponent, CheckButtonInputs {
    @Input() id!: string;
    @Input() context!: GridCellContext;
    toastrNotificationService = Inject(ToastrNotificationService);
    t = Inject(TranslateService);

    checkStatus(row) {
        //TODO
        // this.toastrNotificationService.show({
        //     type: 'danger',
        //     title: 'Error',
        //     message: 'Not Ready, Missing move!'
        // });
    }
}
