import { CustomCondition, customCondition } from '@administration/codelists/codelists.interface';
import { DatePipe } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { BaseViewComponent } from '@common/classes/base-view';
import { IconKey } from '@common/classes/icons';
import { ActionBarGroup } from '@common/components/action-bar/action-bar.interface';
import { CodelistInputComponent } from '@common/components/input/codelist-input/codelist-input.component';
import { BookingStatusCodes } from '@common/known-types/booking-status.codes';
import { CargoTypeCodes } from '@common/known-types/cargo-type.codes';
import { FleetTypeCodes } from '@common/known-types/fleet-type.codes';
import { OrganizationTypeCodes } from '@common/known-types/organization-type.codes';
import { PropertyFunction } from '@common/models/util.interface';
import { CommonService } from '@common/services/common.service';
import { DialogCommunicationService } from '@common/services/dialog-communication.service';
import { QueryService } from '@common/services/query.service';
import { takeUntilResultFinalize, tapSuccessResult } from '@ngneat/query';
import { DialogResult } from '@progress/kendo-angular-dialog';
import { BookingDriver, BookingHaulier, BookingsBase } from 'app/booking/bookings.interface';
import { EquipmentReadyCodes } from 'app/container/container.interface';
import { twJoin } from 'tailwind-merge';
import { BookingService } from '../booking.service';
import { EditablePropertyCode, EditablePropertyMap } from './booking-view-definitions.service';
import { DialogAcceptGoToPortComponent } from './dialog-accept-go-to-port/dialog-accept-go-to-port.component';
import { DialogAddAdditionalDriverComponent } from './dialog-add-additional-driver/dialog-add-additional-driver.component';
import { DialogChangeEnterTimeComponent } from './dialog-change-enter-time/dialog-change-enter-time.component';
import { DialogChangePlateComponent } from './dialog-change-plate/dialog-change-plate.component';
import { DialogMergeComponent } from './dialog-merge/dialog-merge.component';

export enum confirmDriverResult {
    nothing = 'nothing',
    saveAndClose = 'saveAndClose',
    saveGoToPort = 'saveGoToPort'
}

@Injectable({
    providedIn: 'root'
})
export class BookingViewUtilsService {
    queryService = inject(QueryService);
    communicationService = inject(DialogCommunicationService);
    common = inject(CommonService);
    bookingService = inject(BookingService);
    date = inject(DatePipe);

    /**
     * Change color of icon
     * @param row - row in table
     * @returns - class for green and red color
     */
    setEqReadyBooleanIconColor: PropertyFunction<string> = (row: { eqReady: boolean }) => {
        if (row.eqReady) {
            return twJoin('text-success');
        } else {
            return twJoin('text-danger');
        }
    };

    setEqReadyIconColor: PropertyFunction<string> = (row: { eqReady: string }) => {
        if (!row?.eqReady) {
            return twJoin('text-muted');
        }

        if (row.eqReady == EquipmentReadyCodes.Ready) {
            return twJoin('text-success');
        } else if (row.eqReady == EquipmentReadyCodes.Announceable) {
            return twJoin('text-info');
        } else if (row.eqReady == EquipmentReadyCodes.Blocked) {
            return twJoin('text-danger');
        } else {
            return twJoin('text-muted');
        }
    };

    /**
     * Get appropriate icon based on cargoType
     * @param data get row from grid
     * @returns returns IconKey for appropriate icon
     */
    showIconForPrivateCargoType: PropertyFunction<IconKey> = (data: BookingsBase) => {
        switch (data.cargoTypeId) {
            case CargoTypeCodes.CAR:
                return 'faSolidCarSide' as IconKey;
            case CargoTypeCodes.CONTAINER:
                return 'faSolidTruckMoving' as IconKey;
            case CargoTypeCodes.GENERAL_CARGO:
                return 'faSolidWarehouse' as IconKey;
            default:
                return '' as IconKey;
        }
    };

    getActionBar(view: BaseViewComponent, model: BookingsBase = null): ActionBarGroup {
        return {
            label: 'Actions',
            items: [
                {
                    label: 'Leave',
                    icon: 'faSolidArrowRightFromBracket',
                    isVisible: () => view.viewMode,
                    isDisabled: () => true,
                    onClick: () => view.saveChanges()
                },
                {
                    label: 'Delete',
                    icon: 'faSolidTrash',
                    isVisible: () => view.viewMode,
                    isDisabled: () => !this.canDelete(view.model ?? model),
                    onClick: () => view.navigateToEditMode()
                },
                {
                    label: 'Back',
                    icon: 'faSolidArrowLeftLong',
                    isVisible: () => view.viewMode,
                    onClick: () => view.navigateToList()
                }
                //PDF (Finish => only pdf  and back)
            ]
        };
    }

    //Delete when BookingStatusCode (R,B)
    canDelete(model: BookingsBase): boolean {
        if (!model) return false;
        if (model.bookingStatusId == BookingStatusCodes.Booked || model.bookingStatusId == BookingStatusCodes.Reserved)
            return true;
        else return false;
    }

    //Edit when BookingStatusCode (R,B,P,D)
    canEdit(model: BookingsBase) {
        if (
            model.bookingStatusId == BookingStatusCodes.New ||
            model.bookingStatusId == BookingStatusCodes.Booked ||
            model.bookingStatusId == BookingStatusCodes.Reserved ||
            model.bookingStatusId == BookingStatusCodes.InPort ||
            model.bookingStatusId == BookingStatusCodes.InPortDelayed
        ) {
            return true;
        }
        return false;
    }

    isFilterEmpty = (filter: any): boolean =>
        Object.values(filter).every((value) => value === null || value === undefined || value === '');

    async confirm(model): Promise<confirmDriverResult> {
        return confirmDriverResult.saveGoToPort;

        if (
            model.cargoTypeId == CargoTypeCodes.CONTAINER &&
            model.actualHaulierId &&
            model.driverId &&
            model.timeFrom &&
            model.timeTo &&
            !model.truckId //&& !this.tempOrgTruck
        ) {
            return this.common.dialogService
                .confirm({
                    options: {
                        title: 'Booking not complete.',
                        message: 'Booking is not confirmed without plate numbers. Entering the port is not possible.',
                        cancelText: 'Back to booking',
                        confirmText: 'Close'
                    }
                })
                .then((res) => {
                    if (!res) {
                        return confirmDriverResult.nothing;
                    } else {
                        return confirmDriverResult.saveAndClose;
                    }
                });
        } else if (model.cargoTypeId == CargoTypeCodes.CONTAINER) {
            this.common.toastrNotificationService.show({
                type: 'error',
                title: this.common.translateService.instant('Error'),
                message: this.common.translateService.instant('Incomplete booking!')
            });
            return confirmDriverResult.nothing;
        } else {
            return confirmDriverResult.saveGoToPort;
        }
    }

    AcceptGoToPort(model: any) {
        this.common.dialogService
            .open({
                template: {
                    content: DialogAcceptGoToPortComponent,
                    data: {
                        title: this.common.translateService.instant('Booking information.'),
                        cancelText: 'Close',
                        cancelIcon: 'faSolidXmark',
                        model: model
                    }
                },
                dialogSettings: {
                    width: '100%',
                    height: '100%'
                }
            })
            .then(() => this.close());
    }

    changeTime(selection: any, enableSlotSelection: boolean = false): Promise<DialogResult> {
        return this.common.dialogService.open({
            template: {
                content: DialogChangeEnterTimeComponent,
                data: {
                    title: this.common.translateService.instant('Change enter time'),
                    cancelText: 'Close',
                    cancelIcon: 'faSolidXmark',
                    confirmText: 'Ok',
                    confirmIcon: 'faSolidCheck',
                    model: selection,
                    enableSlotSelection: enableSlotSelection
                }
            },
            dialogSettings: {
                width: 520,
                height: 220
            }
        });
    }

    changePlates(selection: any, textMode: boolean = true): Promise<DialogResult> {
        return this.common.dialogService.open({
            template: {
                content: DialogChangePlateComponent,
                data: {
                    title: this.common.translateService.instant('Change plate'),
                    cancelText: 'Close',
                    cancelIcon: 'faSolidXmark',
                    confirmText: 'Ok',
                    confirmIcon: 'faSolidCheck',
                    model: selection,
                    textMode: textMode
                }
            },
            dialogSettings: {
                width: 520,
                height: 280
            }
        });
    }

    addSecondDriver(selection: any): Promise<DialogResult> {
        return this.common.dialogService.open({
            template: {
                content: DialogAddAdditionalDriverComponent,
                data: {
                    title: this.common.translateService.instant('Add second driver'),
                    cancelText: 'Close',
                    cancelIcon: 'faSolidXmark',
                    confirmText: 'Ok',
                    confirmIcon: 'faSolidFloppyDisk',
                    model: selection
                }
            },
            dialogSettings: {
                width: 520,
                height: 220
            }
        });
    }

    mergeBooking(selection: BookingsBase) {
        return this.common.dialogService
            .open({
                template: {
                    content: DialogMergeComponent,
                    data: {
                        title: this.common.translateService.instant('Merge Booking'),
                        cancelText: 'Close',
                        cancelIcon: 'faSolidXmark',
                        confirmText: 'Confirm And Merge',
                        confirmIcon: 'faSolidCheck',
                        model: selection
                    }
                },
                dialogSettings: {
                    width: 520,
                    height: 220
                }
            })
            .then((res: BookingsBase) => {
                const today = new Date();
                const start = this.startOfDay(today);
                const bookingWithLowestDate = res.timeFrom < selection.timeFrom ? res : selection;
                this.bookingService.mergeBookings([selection.id, res.id]).then((res) => {
                    if (res == false) {
                        return;
                    } else {
                        this.common.userService
                            .loginDriver(bookingWithLowestDate.driverPermit, bookingWithLowestDate.pin, start)
                            .catch((_error) => {
                                this.common.toastrNotificationService.show({
                                    type: 'error',
                                    title: this.common.translateService.instant('Error'),
                                    message: this.common.translateService.instant('Failed to login, to merge booking!')
                                });
                                this.close();
                            });
                    }
                });
            });
    }

    unmergeBooking(id: number) {
        this.bookingService.unmergeBooking(id);
    }

    cancel() {
        this.common.dialogService
            .confirm({
                options: {
                    title: 'Do you wish to close booking.',
                    message: 'Changes will not be saved. Close booking.',
                    cancelText: 'No',
                    confirmText: 'Yes'
                }
            })
            .then((res) => {
                if (res) {
                    this.close();
                } else {
                    return;
                }
            });
    }

    close() {
        this.common.userService.logout(); //.then(() => this.router.navigate(['/login']));
    }

    startOfDay = (date: Date): Date => {
        return new Date(date.setHours(0, 0, 0, 0));
    };
    endOfDay = (date: Date): Date => {
        return new Date(date.setHours(23, 59, 59, 999));
    };

    reduceDateByYears = (date: Date, years: number): Date => {
        const newDate = new Date(date);
        newDate.setFullYear(newDate.getFullYear() - years);
        return newDate;
    };

    async onDriverChange(
        model: BookingsBase,
        actualHaulier: CodelistInputComponent,
        responsiblehaulier: CodelistInputComponent,
        isCreateMode: boolean
    ) {
        if (!model.actualHaulierId) {
            this.queryService
                .getQuery<BookingDriver>('Driver', { id: model.driverId }, { injector: this.common.injector })
                .result$.pipe(
                    tapSuccessResult((result: BookingDriver) => {
                        if (result) {
                            model.actualHaulierId = result.organizationId;
                            actualHaulier.value.set(result.organizationId.toString());
                            actualHaulier.queryCodelist('');
                            if (isCreateMode) {
                                responsiblehaulier.value.set(result.organizationId.toString());
                                responsiblehaulier.queryCodelist('');
                                model.responsibleHaulierId = result.organizationId;
                            }
                        }
                    }),
                    takeUntilResultFinalize()
                )
                .subscribe();
        }
    }

    async getHaulierOrganizationType(haulierId: number, selectedHaulier: BookingHaulier) {
        this.queryService
            .getQuery<BookingHaulier>('Organization', { id: haulierId }, { injector: this.common.injector })
            .result$.pipe(
                tapSuccessResult((result: BookingHaulier) => {
                    if (result) {
                        selectedHaulier.id = result.id;
                        selectedHaulier.types = result.types;
                        if (result.types.map((x) => x.organizationType?.id).includes(OrganizationTypeCodes.Temporary)) {
                            selectedHaulier.isTemporary = true;
                        } else {
                            selectedHaulier.isTemporary = false;
                        }
                    }
                }),
                takeUntilResultFinalize()
            )
            .subscribe();
    }

    organizationCondition = (model: BookingsBase): CustomCondition[] => {
        return [{ name: 'Organization', condition: customCondition.Equal, value: model.actualHaulierId }];
    };

    plateTruckCodelistCondition = (model: BookingsBase): CustomCondition[] => {
        return [
            { name: 'FleetType', condition: customCondition.Equal, value: FleetTypeCodes.Truck },
            { name: 'Organization', condition: customCondition.Equal, value: model.actualHaulierId }
        ];
    };

    plateTrailerCodelistCondition = (model: BookingsBase): CustomCondition[] => {
        return [
            { name: 'FleetType', condition: customCondition.Equal, value: FleetTypeCodes.Trailer },
            { name: 'Organization', condition: customCondition.Equal, value: model.actualHaulierId }
        ];
    };

    isPropertyEditable(propertyMap: EditablePropertyMap, model: BookingsBase, property: EditablePropertyCode): boolean {
        if (model.cargoTypeId && model.bookingStatusId && property) {
            return propertyMap[model.cargoTypeId]?.[model.bookingStatusId]?.[property] ?? false;
        }
        return false;
    }

    getAvailableSlots(date: Date = new Date()) {
        const start = this.startOfDay(date);
        const end = this.endOfDay(date);
        return this.common.queryService.getQuery<any>(
            'Slots',
            { timeFrom: start, timeTo: end, take: 50 },
            { injector: this.common.injector }
        ).result$;
    }
}
