import { Component, Input } from '@angular/core';
import { CommonModule } from '@common/common.module';
import { GridCellContext, GridTemplateComponent } from '@common/interfaces/column-settings.interface';

export interface CarAddButtonInputs {
    tooltip: string;
    row: any;
    onClick: () => void;
}

@Component({
    standalone: true,
    imports: [CommonModule],
    template: `
        <app-button
            variant="primary"
            size="sm"
            tooltip="{{ tooltip | translate }}"
            [disabled]="isDisabled"
            (clicked)="onClick()">
            <ng-icon name="faSolidPlus"></ng-icon>
        </app-button>
    `
})
export class AddButtonCellComponent implements GridTemplateComponent, CarAddButtonInputs {
    @Input() tooltip: string;
    @Input() context!: GridCellContext;
    @Input() row!: any;
    @Input() onClick!: () => void;
    @Input() isDisabled!: any;
}
