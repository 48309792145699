import { Component, inject } from '@angular/core';
import { IconKey } from '@common/classes/icons';
import { TranslateService } from '@ngx-translate/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

export interface DialogAcceptGoToPortOptions {
    title?: string;
    titleIcons?: IconKey[];
    message?: string;
    cancelText?: string;
    cancelIcon?: IconKey;
    cancelAction?: Function;
    confirmMessage?: string;
    model?: any[];
}

@Component({
    selector: 'app-dialog-accept-go-to-port',
    templateUrl: './dialog-accept-go-to-port.component.html'
})
export class DialogAcceptGoToPortComponent extends DialogContentBase {
    override dialog = inject<DialogRef>(DialogRef);
    translateService = inject(TranslateService);

    model: any;
    options: DialogAcceptGoToPortOptions = {
        title: this.translateService.instant('Booking information.'),
        message: ''
    };

    public initialize(options: DialogAcceptGoToPortOptions) {
        for (const [key, value] of Object.entries(options)) {
            if (![undefined, null].includes(value)) this.options[key] = value;
        }
        this.model = this.options.model;
    }

    confirm(): void {
        // if (this.appControls.some((x) => !!x.error)) {
        //     this.toastrNotificationService.show({ type: 'error', message: 'Some required fields are empty' });
        //     return;
        // }
        this.dialog.close(this.model);
    }

    close(result: boolean | undefined = undefined) {
        this.dialog.close(result);
    }
}
