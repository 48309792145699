import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { BaseViewComponent } from '@common/classes/base-view';
import { IconKey } from '@common/classes/icons';
import { BookingPermissions } from '@common/classes/permissions';
import { CodelistInputComponent } from '@common/components/input/codelist-input/codelist-input.component';
import { ColumnSettings } from '@common/interfaces/column-settings.interface';
import { BookingStatusCodes } from '@common/known-types/booking-status.codes';
import { BookingTypeCodes } from '@common/known-types/booking-type.codes';
import { CargoTypeCodes } from '@common/known-types/cargo-type.codes';
import { ContainerStatusCodes } from '@common/known-types/container-status.codes';
import { ContainerTypeCodes } from '@common/known-types/container-type.codes';
import { OrganizationTypeCodes } from '@common/known-types/organization-type.codes';
import { CommonService } from '@common/services/common.service';
import { takeUntilResultFinalize, tapSuccessResult } from '@ngneat/query';
import { BookingContainerEdit, BookingHaulier, BookingsContainer } from 'app/booking/bookings.interface';
import dayjs from 'dayjs';
import _ from 'lodash';
import { AddButtonCellComponent } from '../booking-car-view/add-button-cell.component';
import { BookingViewDefinitionsService, EditablePropertyCode } from '../booking-view-definitions.service';
import { BookingViewUtilsService, confirmDriverResult } from '../booking-view-utils.service';
import { SlotData } from '../scroll-slot-selector/scroll-slot-selector.component';
@Component({
    selector: 'app-booking-container-view',
    templateUrl: './booking-container-view.component.html',
    providers: [CommonService]
})
export class BookingContainerViewComponent extends BaseViewComponent implements OnInit {
    override editPermission = BookingPermissions.Default.Edit;
    override createPermission = BookingPermissions.Default.Edit;
    entityName = 'ContainerBooking';
    icon: IconKey = 'faSolidCube';
    def = inject(BookingViewDefinitionsService);
    filter = { serviceRequest: '', container: '' };
    containerDischarge: BookingContainerEdit[] = [];
    containerLoad: BookingContainerEdit[] = [];
    optionsLocation = [
        { value: 'K', label: 'K - Truck' },
        { value: 'P', label: 'P - Trailer' }
    ];
    haulierType = [OrganizationTypeCodes.Haulier, OrganizationTypeCodes.ForwarderHaulier];
    private userOrgId;
    private orgTypes;
    selectedHaulier: BookingHaulier = {
        //used to get organization type
        id: 0,
        types: [],
        isTemporary: false
    };

    override initialModel: Partial<BookingsContainer> = {
        bookingStatusId: BookingStatusCodes.New,
        cargoTypeId: CargoTypeCodes.CONTAINER,
        terminal: 'CNT'
    };
    containerData = [];
    isDriver: boolean = false;
    slots: SlotData[] = [];
    containerColumns: ColumnSettings[] = this.def.containerColumns;
    containerEditColumns: ColumnSettings[] = [
        ...this.def.containerEditColumns,
        {
            field: 'vehicle',
            title: '',
            templateComponent: AddButtonCellComponent,
            templateInputs: (row) => ({
                tooltip: 'Add Container',
                row,
                onClick: () => this.addContainer(row),
                isDisabled: !this.isPropertyEditable(EditablePropertyCode.cargo)
            })
        }
    ];

    @ViewChild('haulier', { static: false }) haulier: CodelistInputComponent;
    @ViewChild('responsiblehaulier', { static: false }) responsiblehaulier: CodelistInputComponent;

    constructor(
        common: CommonService,
        private util: BookingViewUtilsService
    ) {
        super(common);
        this.actionBar.push(this.util.getActionBar(this));
        this.common.userService.getCurrentUser();
        this.userOrgId = this.common.userService.currentUserSubject.value?.organization?.id;
        this.orgTypes =
            this.common.userService.currentUserSubject.value?.organization?.types.map((x) => x.organizationType?.id) ??
            [];

        if (this.user.fullName == 'DriverLogin' /* || this.user.isDriver*/) {
            this.actionBar = [];
            this.isDriver = true;
        }
    }

    override ngOnInit(): void {
        super.ngOnInit();

        if (this.createMode) {
            this.model.organizationId = this.userOrgId;
            this.model.haulierId =
                this.user.organization.organizationTypeCode == OrganizationTypeCodes.Haulier ||
                this.user.organization.organizationTypeCode == OrganizationTypeCodes.ForwarderHaulier
                    ? this.userOrgId
                    : null;
            this.model.bookingDate = new Date();
            this.getAvailableSlots(this.model.bookingDate);
        }
    }

    override getTitle() {
        const entityTitle = this.translateService.instant(this.entityName.replace(/([A-Z])/g, ' $1').trim());
        return this.createMode
            ? `${this.translateService.instant('New')} ${entityTitle}`
            : `${this.translateService.instant('Container Booking')} ${this.getIdentifier()}`;
    }

    override canEdit(): boolean {
        if (this.createMode) return true;
        return this.util.canEdit(this.model);
    }

    search() {
        this.common.queryService
            .getQuery<any>('Containers', this.filter, {
                injector: this.common.injector
            })
            .result$.pipe(
                tapSuccessResult((data) => {
                    this.containerData = data.results;
                }),
                takeUntilResultFinalize()
            )
            .subscribe();
    }

    addContainer(row: any): void {
        if (!this.model.containers) this.model.containers = [];

        this.validateContainers({
            bookingTypeId: row.bookingTypeId,
            containerBookingId: row.containerBookingId,
            containerCode: row.containerId,
            emptyFullId: row.emptyFullId,
            eqReady: row.eqReady,
            errorMessage: row.errorMessage,
            id: row.id,
            length: row.containerLengthId,
            loadedOnId: row.loadedOnId ?? 'P',
            location: row.location
        });
    }

    getContainer(type: BookingTypeCodes): BookingContainerEdit[] {
        return this.model.containers.filter((item) => item.bookingTypeId === type);
    }

    removeDischargeContainer(item) {
        this.model.containers = this.model.containers.filter((x) => x.id != item.id);
        this.containerDischarge = this.getContainer(BookingTypeCodes.DROP_OFF);
    }
    removeLoadContainer(item) {
        this.model.containers = this.model.containers.filter((x) => x.id != item.id);
        this.containerLoad = this.getContainer(BookingTypeCodes.PICK_UP);
    }

    override modelLoaded(): any {
        super.modelLoaded();
        this.containerDischarge = this.getContainer(BookingTypeCodes.DROP_OFF);
        this.containerLoad = this.getContainer(BookingTypeCodes.PICK_UP);
        if (this.model.timeFrom && dayjs(this.model.timeFrom).isAfter(dayjs())) {
            this.model.bookingDate = dayjs(this.model.timeFrom).toDate();
        } else {
            this.model.bookingDate = new Date();
        }
        this.getAvailableSlots(this.model.bookingDate);
    }

    override saveChanges(customData?: any): Promise<void> {
        if (!super.canSave()) return null;
        if (this.createMode) {
            this.model.timeFrom ??= null;
            this.model.timeTo ??= null;
            this.model.terminal ??= 'CNT';
            this.model.bookingStatusId = BookingStatusCodes.New;
            this.model.bookingTypeId = BookingTypeCodes.MIXED; //TODO where is set for new containerbooking
            this.model.organizationId = this.userOrgId;
            this.model.haulierId = this.haulierType.some((element) => this.orgTypes.includes(element))
                ? this.userOrgId
                : null;
            this.model.cargoTypeId = 'C';
            this.model.warehouse = 'CNT';
        }
        if (this.validateContainerBooking()) {
            this.model.warehouse ??= 'CNT'; //TODO all or just on create
            this.model.containers.forEach((x) => (x.eqReady == 'ready' ? (x.eqReady = true) : (x.eqReady = false)));
            return super.saveChanges(customData);
        }
        return null;
    }

    validateContainerBooking() {
        if (!this.model.containers || this.model.containers.length == 0) {
            this.toastrNotificationService.show({
                type: 'error',
                title: this.common.translateService.instant('Error'),
                message: this.common.translateService.instant("Can't create booking without containers.")
            });
            return false;
        } else {
            this.model.containerIds = this.model.containers.map((x) => x.id);
        }

        if (
            (this.model.bookingStatusId == BookingStatusCodes.Booked &&
                _.some(this.model.containers, (cnt) => !this.isContainerEqReady(cnt))) ||
            _.some(this.model.mergedBookings, (mb) => _.some(mb.containers, (cnt) => !this.isContainerEqReady(cnt)))
        ) {
            this.toastrNotificationService.show({
                type: 'error',
                title: this.common.translateService.instant('Error'),
                message: this.common.translateService.instant(
                    'You added container that is not equipment ready. Booking will no longer be confirmed.'
                )
            });
            return false;
        }

        if (!this.model?.slotId) {
            this.toastrNotificationService.show({
                type: 'error',
                title: this.common.translateService.instant('Error'),
                message: this.common.translateService.instant('Time slot not selected.')
            });
            return false;
        }

        if (
            (!this.selectedHaulier.isTemporary && !(this.model.truckId && this.model.trailerId)) ||
            (this.selectedHaulier.isTemporary && !(this.model.tempTruck && this.model.tempTrailer))
        ) {
            this.toastrNotificationService.show({
                type: 'error',
                title: this.common.translateService.instant('Error'),
                message: this.common.translateService.instant(
                    'Truck and trailer plates are mandatory. If truck does not have a trailer, enter the same plates for trailer as the truck.'
                )
            });
            return false;
        }

        return true;
    }

    validateContainers(container: any): boolean {
        if (this.model.containers.find((x) => x.id == container.id)) {
            this.toastrNotificationService.show({
                type: 'error',
                title: this.common.translateService.instant('Error'),
                message: this.common.translateService.instant('Container has been already added.')
            });
            return false;
        }

        if (
            container.bookingTypeId === BookingTypeCodes.DROP_OFF &&
            this.containerDischarge.length >= 4 &&
            _.every(this.containerDischarge, (cnt: any) => cnt.containerTypeId === ContainerTypeCodes.FlatRack)
        ) {
            this.toastrNotificationService.show({
                type: 'error',
                title: this.common.translateService.instant('Error'),
                message: this.common.translateService.instant('There can be maximum 4 out containers.')
            });
            return false;
        } else if (
            container.bookingTypeId === BookingTypeCodes.PICK_UP &&
            this.containerLoad.length >= 4 &&
            _.every(this.containerLoad, (cnt: any) => cnt.containerTypeId === ContainerTypeCodes.FlatRack)
        ) {
            this.toastrNotificationService.show({
                type: 'error',
                title: this.common.translateService.instant('Error'),
                message: this.common.translateService.instant('There can be maximum 4 in containers.')
            });
            return false;
        } else if (container.bookingTypeId == BookingTypeCodes.DROP_OFF && this.containerDischarge.length >= 2) {
            this.toastrNotificationService.show({
                type: 'error',
                title: this.common.translateService.instant('Error'),
                message: this.common.translateService.instant('There can be maximum 2 in containers.')
            });
            return false;
        } else if (container.bookingTypeId == BookingTypeCodes.PICK_UP && this.containerLoad.length >= 2) {
            this.toastrNotificationService.show({
                type: 'error',
                title: this.common.translateService.instant('Error'),
                message: this.common.translateService.instant('There can be maximum 2 in containers.')
            });
            return false;
        }
        this.model.containers.push(container);
        this.containerDischarge = this.getContainer(BookingTypeCodes.DROP_OFF);
        this.containerLoad = this.getContainer(BookingTypeCodes.PICK_UP);
        return true;
    }

    onSlotChange(event: any) {
        if (!event) {
            this.model.timeFrom = null;
            this.model.timeTo = null;
        } else {
            this.model.timeFrom = this.slots.find((x) => x.value == event).data.timeFrom;
            this.model.timeTo = this.slots.find((x) => x.value == event).data.timeTo;
            this.model.slotId = this.slots.find((x) => x.value == event).data.id;
        }
    }

    onDateChange(event: Date) {
        this.model.slotId = null;
        this.getAvailableSlots(event); //Get free slots for selected date or today
    }

    onActualHaulierChange() {
        if (this.createMode) {
            this.model.responsibleHaulierId = this.model.actualHaulierId;
            this.responsiblehaulier.value.set(this.model.actualHaulierId);
            this.responsiblehaulier.queryCodelist('');
        }
        if (this.model.actualHaulierId)
            this.util.getHaulierOrganizationType(this.model.actualHaulierId, this.selectedHaulier); //get if temporary Haulier
        this.model.driverId = null;
        this.model.additionalDriverId = null;
        this.model.truckId = null;
        this.model.trailerId = null;
    }

    async onDriverChange() {
        this.util.onDriverChange(this.model, this.haulier, this.responsiblehaulier, this.createMode);
    }

    getAvailableSlots(date: Date = new Date()) {
        this.util
            .getAvailableSlots(date)
            .pipe(
                tapSuccessResult((data) => {
                    this.slots = data.results.map((slot) => {
                        return { value: slot.id, label: `${dayjs(slot.timeFrom).format('HH:mm')}`, data: slot };
                    });
                }),
                takeUntilResultFinalize()
            )
            .subscribe();
    }

    confirm() {
        this.util.confirm(this.model).then((res: confirmDriverResult) => {
            switch (res) {
                case confirmDriverResult.nothing:
                    return;
                case confirmDriverResult.saveAndClose: {
                    this.saveChanges();
                    this.close();
                    break;
                }
                case confirmDriverResult.saveGoToPort: {
                    this.saveChanges();
                    this.util.AcceptGoToPort(this.model);
                    break;
                }
            }
        });
    }

    isContainerEqReady(container): boolean {
        return !(
            (container.containerStatusCode == ContainerStatusCodes.NotInPort &&
                container.bookingTypeCode == BookingTypeCodes.PICK_UP) ||
            (container.containerStatusCode == ContainerStatusCodes.InPort &&
                container.bookingTypeCode == BookingTypeCodes.DROP_OFF)
        );
    }

    isSelectorShown(containers: { emptyFullId: 'F' | 'E' }[]): boolean {
        if (!containers || containers.length == 0) return true;
        return containers.some((container) => container.emptyFullId === 'F');
    }

    onTimeSlotSelected(value: { timeFrom: Date; timeTo: Date }) {
        this.model.timeFrom = value.timeFrom;
        this.model.timeTo = value.timeTo;
    }

    changeTime() {
        this.util.changeTime(this.model);
    }

    changePlates() {
        this.util.changePlates(this.model);
    }

    addSecondDriver() {
        this.util.addSecondDriver(this.model);
    }

    mergeBooking() {
        this.util.mergeBooking(this.model);
    }

    unmergeBooking() {
        this.util.unmergeBooking(this.model.id);
    }

    cancel() {
        this.util.cancel();
    }

    close() {
        this.util.close();
    }

    isPropertyEditable(property: EditablePropertyCode) {
        return this.util.isPropertyEditable(this.def.editablePropertyMap, this.model, property);
    }
}
