import { fakedCodelists } from '@administration/codelists/codelists.interface';
import { codelists } from '@administration/codelists/codelists.service';
import { inject, Injectable } from '@angular/core';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { IconCellComponent, IconCellInputs } from '@common/components/app-grid/columns/icon-cell/icon-cell.component';
import { ColumnSettings } from '@common/interfaces/column-settings.interface';
import { BookingStatusCodes } from '@common/known-types/booking-status.codes';
import { CargoTypeCodes } from '@common/known-types/cargo-type.codes';
import { CommonService } from '@common/services/common.service';
import { CellSizeComponent } from './booking-container-view/cell-size.component';
import { CellCheckButtonComponent } from './booking-container-view/check-button.component';
import { CellEnterLeavePortButtonComponent } from './booking-private-view/check-button.component';
import { BookingViewUtilsService } from './booking-view-utils.service';
import { WrapCellComponent } from './truck-visit-bar/wrap-cell.component';

export enum EditablePropertyCode {
    slot = 'slot',
    haulier = 'haulier',
    driver = 'driver',
    truck = 'truck',
    trailer = 'trailer',
    additionalDriver = 'additionalDriver',
    cargo = 'cargo'
}

export type EditablePropertyMap = {
    [key in CargoTypeCodes]?: {
        [key in BookingStatusCodes]?: {
            [key in EditablePropertyCode]: boolean;
        };
    };
};

@Injectable({
    providedIn: 'root'
})
export class BookingViewDefinitionsService {
    utils = inject(BookingViewUtilsService);
    constructor(private common: CommonService) {}
    //#region Column Def

    bookingIdColumn: ColumnSettings = { field: 'id', title: _('Booking') };

    pinColumn: ColumnSettings = { field: 'pin', title: _('PIN') };

    eqReadyColumn: ColumnSettings = {
        field: 'eqReady',
        title: '',
        width: 40,
        templateComponent: IconCellComponent,
        templateInputs: (row) =>
            ({
                icons: [
                    {
                        icon: 'faSolidCircle',
                        iconClassCon: this.utils.setEqReadyBooleanIconColor(row)
                    }
                ]
            }) as IconCellInputs
    };

    eqReady2Column: ColumnSettings = {
        field: 'eqReady',
        title: '',
        width: 40,
        templateComponent: IconCellComponent,
        templateInputs: (row) =>
            ({
                icons: [
                    {
                        icon: 'faSolidCircle',
                        iconClassCon: this.utils.setEqReadyIconColor(row)
                    }
                ]
            }) as IconCellInputs
    };

    cargoTypeIconColumn: ColumnSettings = {
        field: 'cargoType',
        title: _('Type'),
        templateComponent: IconCellComponent,
        templateInputs: (row) =>
            ({
                icons: [
                    {
                        tooltip: _('Cargo Type'),
                        icon: this.utils.showIconForPrivateCargoType(row),
                        iconClass: 'text-lg text-muted'
                    }
                ]
            }) as IconCellInputs
    };

    bookingTypeColumn: ColumnSettings = {
        field: 'bookingType',
        title: _('Type'),
        type: 'codelist',
        codelistName: codelists.bookingType
    };

    bookingTypeIdColumn: ColumnSettings = {
        field: 'bookingTypeId',
        title: _('Type'),
        type: 'codelist',
        codelistName: codelists.bookingType
    };

    vehicleColumn: ColumnSettings = { field: 'vehicle', title: _('Vehicle') };

    containerCodeColumn: ColumnSettings = {
        field: 'containerCode',
        width: 150,
        title: _('Container')
    };

    serviceRequestColumn: ColumnSettings = {
        field: 'serviceRequest',
        title: _('Service request')
    };

    forwarderColumn: ColumnSettings = {
        field: 'forwarderId',
        title: _('Forwarder'),
        codelistName: 'Organization',
        type: 'codelist'
    };

    emptyFullColumn: ColumnSettings = {
        field: 'emptyFull',
        title: _('E/F'),
        type: 'codelist',
        codelistName: codelists.emptyFull
    };

    scheduledTimeColumn: ColumnSettings = {
        field: 'timeFrom',
        field2: 'timeTo',
        type: 'datetimespan',
        title: _('Scheduled time'),
        width: 200
    };
    //#endregion

    //#region Table Definitions
    containerColumns: ColumnSettings[] = [
        this.eqReadyColumn,
        this.containerCodeColumn,
        {
            title: '',
            templateComponent: CellCheckButtonComponent,
            templateInputs: (row) => ({
                id: row.id,
                codelistName: 'ContainerStatus',
                code: row.containerStatusId
            })
        },
        {
            field: 'emptyFullId',
            title: _('E/F'),
            type: 'codelist',
            codelistName: codelists.emptyFull
        },
        this.bookingTypeIdColumn,
        { field: 'loadedOnId', title: _('Loaded on') },
        { field: 'location', title: _('Location') },
        { field: 'errorMessage', title: _('Error') }
    ];

    containerEditColumns: ColumnSettings[] = [
        { field: 'bookingTypeId', title: _('Type'), type: 'codelist', codelistName: codelists.bookingType },
        {
            field: 'containerId',
            width: 150,
            title: _('Container')
        },
        { field: 'serviceRequest', title: _('Order') },
        {
            title: _('Size'),
            templateComponent: CellSizeComponent,
            templateInputs: (row) => ({
                length: row.containerLengthId,
                height: row.containerHeightId
            })
        },
        {
            field: 'emptyFullId',
            title: _('E/F'),
            type: 'codelist',
            codelistName: codelists.emptyFull
        },
        this.eqReady2Column
    ];

    generalCargoColumns: ColumnSettings[] = [
        this.bookingTypeColumn,
        { field: 'cargoDescription', title: _('Description') }
    ];

    privateColumns: ColumnSettings[] = [
        this.cargoTypeIconColumn,
        this.bookingIdColumn,
        this.scheduledTimeColumn,
        {
            field: 'bookingStatus',
            title: _('Status'),
            type: 'codelist',
            codelistName: codelists.bookingStatus
        },
        {
            title: 'Action',
            templateComponent: CellEnterLeavePortButtonComponent,
            templateInputs: (row) => ({
                id: row.id,
                status: row.bookingStatus
            })
        }
    ];

    privateEditColumns: ColumnSettings[] = [this.cargoTypeIconColumn, this.bookingIdColumn, this.pinColumn];

    carColumns: ColumnSettings[] = [
        this.vehicleColumn,
        this.serviceRequestColumn,
        this.bookingTypeColumn,
        this.forwarderColumn
    ];

    carEditColumns: ColumnSettings[] = [this.vehicleColumn, this.serviceRequestColumn, this.forwarderColumn];

    eventColumns: ColumnSettings[] = [
        { field: 'badge', title: _('Badge') },
        this.pinColumn,
        {
            field: 'bookingType',
            title: _('Status'),
            type: 'codelist',
            codelistName: codelists.bookingStatus
        },
        {
            field: 'truckPlate',
            title: _('Truck plate')
        },
        {
            field: 'TrailerPlate',
            title: _('Trailer plate')
        },
        {
            field: 'eventDate',
            type: 'datetime',
            title: _('Event date')
        },
        {
            field: 'gateStatus',
            title: _('Gate status'),
            type: 'codelist',
            codelistName: codelists.gate
        },
        {
            field: 'lane',
            title: _('Lane')
        }
    ];

    revisionsColumns: ColumnSettings[] = [
        { field: 'id', title: _('Revision'), width: 80 },
        {
            field: 'bookingStatusId',
            title: _('Status'),
            type: 'codelist',
            codelistName: codelists.bookingStatus,
            codelistPill: true,
            width: 130
        },
        {
            field: 'revisionEventId',
            title: _('Type'),
            type: 'codelist',
            codelistName: codelists.revisionEvent
        },
        {
            field: 'haulierId',
            type: 'codelist',
            codelistName: fakedCodelists.organization,
            title: _('Haulier'),
            width: 120
        },
        {
            field: 'responsibleHaulier',
            title: _('Responsible haulier')
        },
        {
            field: 'driverId',
            type: 'codelist',
            codelistName: fakedCodelists.driver,
            title: _('Driver')
        },
        {
            field: 'additionalDriverId',
            type: 'codelist',
            codelistName: fakedCodelists.driver,
            title: _('Additional driver	')
        },
        {
            field: 'truckPlate',
            title: _('Truck')
        },
        {
            field: 'trailerPlate',
            title: _('Trailer')
        },
        {
            field: 'cargo',
            title: _('Cargo'),
            fieldTooltip: 'cargo',
            templateComponent: WrapCellComponent,
            templateInputs: (row) => ({ content: row.cargo }),
            width: 170
        },
        {
            field: 'mergedFromBooking',
            title: _('Merged from')
        },

        {
            field: 'mergedToBooking',
            title: _('Merged to')
        },
        {
            field: 'releasedContainer',
            title: _('Released CNT')
        },
        this.scheduledTimeColumn,
        {
            field: 'cvsr',
            title: _('CVSR')
        },
        {
            field: 'sdokDate',
            type: 'datetime',
            title: _('SDOK date')
        },
        {
            field: 'createdDate',
            type: 'datetime',
            title: _('Created date'),
            width: 150
        }
    ];
    //#endregion

    //#region EditablePropertyStatus
    editablePropertyMap: EditablePropertyMap = {
        [CargoTypeCodes.CAR]: {
            [BookingStatusCodes.New]: {
                [EditablePropertyCode.slot]: true,
                [EditablePropertyCode.haulier]: true,
                [EditablePropertyCode.driver]: true,
                [EditablePropertyCode.truck]: true,
                [EditablePropertyCode.trailer]: true,
                [EditablePropertyCode.additionalDriver]: true,
                [EditablePropertyCode.cargo]: true
            },
            [BookingStatusCodes.Reserved]: {
                [EditablePropertyCode.slot]: true,
                [EditablePropertyCode.haulier]: true,
                [EditablePropertyCode.driver]: true,
                [EditablePropertyCode.truck]: true,
                [EditablePropertyCode.trailer]: true,
                [EditablePropertyCode.additionalDriver]: true,
                [EditablePropertyCode.cargo]: true
            },
            [BookingStatusCodes.Booked]: {
                [EditablePropertyCode.slot]: true,
                [EditablePropertyCode.haulier]: true,
                [EditablePropertyCode.driver]: true,
                [EditablePropertyCode.truck]: true,
                [EditablePropertyCode.trailer]: true,
                [EditablePropertyCode.additionalDriver]: true,
                [EditablePropertyCode.cargo]: true
            },
            [BookingStatusCodes.InPort]: {
                [EditablePropertyCode.slot]: false,
                [EditablePropertyCode.haulier]: false,
                [EditablePropertyCode.driver]: false,
                [EditablePropertyCode.truck]: true,
                [EditablePropertyCode.trailer]: true,
                [EditablePropertyCode.additionalDriver]: true,
                [EditablePropertyCode.cargo]: false
            },
            [BookingStatusCodes.InPortDelayed]: {
                [EditablePropertyCode.slot]: false,
                [EditablePropertyCode.haulier]: false,
                [EditablePropertyCode.driver]: false,
                [EditablePropertyCode.truck]: true,
                [EditablePropertyCode.trailer]: true,
                [EditablePropertyCode.additionalDriver]: true,
                [EditablePropertyCode.cargo]: false
            }
        },
        [CargoTypeCodes.CONTAINER]: {
            [BookingStatusCodes.New]: {
                [EditablePropertyCode.slot]: true,
                [EditablePropertyCode.haulier]: true,
                [EditablePropertyCode.driver]: true,
                [EditablePropertyCode.truck]: true,
                [EditablePropertyCode.trailer]: true,
                [EditablePropertyCode.additionalDriver]: true,
                [EditablePropertyCode.cargo]: true
            },
            [BookingStatusCodes.Reserved]: {
                [EditablePropertyCode.slot]: true,
                [EditablePropertyCode.haulier]: true,
                [EditablePropertyCode.driver]: true,
                [EditablePropertyCode.truck]: true,
                [EditablePropertyCode.trailer]: true,
                [EditablePropertyCode.additionalDriver]: true,
                [EditablePropertyCode.cargo]: true
            },
            [BookingStatusCodes.Booked]: {
                [EditablePropertyCode.slot]: true,
                [EditablePropertyCode.haulier]: true,
                [EditablePropertyCode.driver]: true,
                [EditablePropertyCode.truck]: true,
                [EditablePropertyCode.trailer]: true,
                [EditablePropertyCode.additionalDriver]: true,
                [EditablePropertyCode.cargo]: true
            },
            [BookingStatusCodes.InPort]: {
                [EditablePropertyCode.slot]: false,
                [EditablePropertyCode.haulier]: false,
                [EditablePropertyCode.driver]: false,
                [EditablePropertyCode.truck]: true,
                [EditablePropertyCode.trailer]: true,
                [EditablePropertyCode.additionalDriver]: true,
                [EditablePropertyCode.cargo]: true
            },
            [BookingStatusCodes.InPortDelayed]: {
                [EditablePropertyCode.slot]: false,
                [EditablePropertyCode.haulier]: false,
                [EditablePropertyCode.driver]: false,
                [EditablePropertyCode.truck]: true,
                [EditablePropertyCode.trailer]: true,
                [EditablePropertyCode.additionalDriver]: true,
                [EditablePropertyCode.cargo]: true
            }
        },
        [CargoTypeCodes.PRIVATE]: {
            [BookingStatusCodes.New]: {
                [EditablePropertyCode.slot]: true,
                [EditablePropertyCode.haulier]: true,
                [EditablePropertyCode.driver]: true,
                [EditablePropertyCode.truck]: true,
                [EditablePropertyCode.trailer]: true,
                [EditablePropertyCode.additionalDriver]: true,
                [EditablePropertyCode.cargo]: true
            },
            [BookingStatusCodes.Reserved]: {
                [EditablePropertyCode.slot]: true,
                [EditablePropertyCode.haulier]: true,
                [EditablePropertyCode.driver]: true,
                [EditablePropertyCode.truck]: true,
                [EditablePropertyCode.trailer]: true,
                [EditablePropertyCode.additionalDriver]: true,
                [EditablePropertyCode.cargo]: true
            },
            [BookingStatusCodes.Booked]: {
                [EditablePropertyCode.slot]: true,
                [EditablePropertyCode.haulier]: true,
                [EditablePropertyCode.driver]: true,
                [EditablePropertyCode.truck]: true,
                [EditablePropertyCode.trailer]: true,
                [EditablePropertyCode.additionalDriver]: true,
                [EditablePropertyCode.cargo]: true
            },
            [BookingStatusCodes.InPort]: {
                [EditablePropertyCode.slot]: false,
                [EditablePropertyCode.haulier]: false,
                [EditablePropertyCode.driver]: false,
                [EditablePropertyCode.truck]: true,
                [EditablePropertyCode.trailer]: true,
                [EditablePropertyCode.additionalDriver]: true,
                [EditablePropertyCode.cargo]: false
            },
            [BookingStatusCodes.InPortDelayed]: {
                [EditablePropertyCode.slot]: false,
                [EditablePropertyCode.haulier]: false,
                [EditablePropertyCode.driver]: false,
                [EditablePropertyCode.truck]: false,
                [EditablePropertyCode.trailer]: false,
                [EditablePropertyCode.additionalDriver]: true,
                [EditablePropertyCode.cargo]: false
            },
            [BookingStatusCodes.OutOfPort]: {
                [EditablePropertyCode.slot]: false,
                [EditablePropertyCode.haulier]: false,
                [EditablePropertyCode.driver]: false,
                [EditablePropertyCode.truck]: false,
                [EditablePropertyCode.trailer]: false,
                [EditablePropertyCode.additionalDriver]: true,
                [EditablePropertyCode.cargo]: false
            }
        }
    };
}
