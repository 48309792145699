import { customCondition, fakedCodelists } from '@administration/codelists/codelists.interface';
import { Component } from '@angular/core';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { BaseListComponent } from '@common/classes/base-list';
import { ContainerPermissions } from '@common/classes/permissions';
import { ActionBarGroup } from '@common/components/action-bar/action-bar.interface';
import { FormProperty } from '@common/components/app-control/app-control.component';
import { CellCodeListDashNameComponent } from '@common/components/app-grid/columns/grid-cell-template/cell-codelist-dash-name.component';
import { ColumnSettings } from '@common/interfaces/column-settings.interface';
import { FleetTypeCodes } from '@common/known-types/fleet-type.codes';
import { CommonService } from '@common/services/common.service';
import { SortDescriptor } from '@progress/kendo-data-query';
import { ContainerListModel, EquipmentReadyCodes } from '../container.interface';
import { CellDamagesComponent } from './cell-templates/cell-damages.component';
import { CellDateFromToComponent } from './cell-templates/cell-date-from-to.component';
import { EIRCellComponent } from './cell-templates/eir-cell.component';
import { importContainersDialog } from './container-list.utils';

@Component({
    selector: 'app-container-list',
    templateUrl: './container-list.component.html',
    styleUrl: './container-list.component.scss',
    providers: [CommonService]
})
export class ContainerListComponent extends BaseListComponent<ContainerListModel> {
    override columns: ColumnSettings<ContainerListModel>[];
    override queryName = 'containers';
    override actionBar: ActionBarGroup[];
    selectedContainerIds: string[] = [];
    EquipmentReadyCodes = EquipmentReadyCodes;

    constructor(commonService: CommonService) {
        super(commonService);
        this.initializeActionBar();
        this.initializeColumnSettings();
    }

    gridSort: SortDescriptor[] = [
        {
            field: 'id',
            dir: 'desc'
        }
    ];

    get filterProperties(): FormProperty[] {
        const filters: FormProperty[] = [
            /* Container filters */
            {
                name: 'statusId',
                label: _('Status'),
                codelist: 'ContainerStatus'
            },
            {
                name: 'container',
                label: _('Container'),
                type: 'string'
            },
            {
                name: 'serviceRequest',
                label: _('Service Request'),
                type: 'string'
            },
            {
                name: 'booked',
                label: _('Booked'),
                type: 'boolean'
            },
            {
                name: 'eqReady',
                label: _('EQ Ready'),
                type: 'boolean'
            },

            /* Booking filters */
            {
                name: 'booking',
                label: _('Booking'),
                type: 'string'
            },
            {
                name: 'bookingStatusId',
                label: _('Booking Status'),
                codelist: 'BookingStatus'
            },
            {
                name: 'pin',
                label: _('PIN'),
                type: 'string'
            },
            {
                name: 'timeFrom',
                label: _('Time From'),
                type: 'datetime'
            },
            {
                name: 'timeTo',
                label: _('Time To'),
                type: 'datetime'
            },
            {
                name: 'organizationId',
                label: _('Organization'),
                codelist: 'Organization'
            },
            {
                name: 'haulierId',
                label: _('Haulier'),
                codelist: 'Organization'
            },
            {
                name: 'driverId',
                label: _('Driver'),
                type: 'codelist',
                codelist: fakedCodelists.driver
            },
            {
                name: 'truckPlate',
                label: _('Truck'),
                type: 'codelist',
                codelist: fakedCodelists.fleet,
                codelistCustomCondition: [
                    { name: 'FleetType', condition: customCondition.Equal, value: FleetTypeCodes.Truck }
                ]
            },
            {
                name: 'trailerPlate',
                label: _('Trailer'),
                type: 'codelist',
                codelist: fakedCodelists.fleet,
                codelistCustomCondition: [
                    { name: 'FleetType', condition: customCondition.Equal, value: FleetTypeCodes.Trailer }
                ]
            },
            {
                name: 'tosAllowed',
                label: _('TOS'),
                type: 'boolean'
            }
        ];

        return filters.map((f) => ({
            colSpan: 3, // default
            type: f.codelist ? 'codelist' : null, // if we set the codelist property we can assume the type is codelist
            initialValue: this.filter?.[f.name], // to avoid repeating code.
            nullable: f.type === 'boolean' ? true : null, // for tristate checkboxes
            ...f
        }));
    }

    onEirPrintClick(eir: number) {
        alert('Not yet implemented. Eir: ' + eir);
    }

    initializeColumnSettings = () => {
        this.columns = [
            {
                field: 'containerId',
                title: _('Container'),
                isLink: true,
                customRoute: { path: '..', field: 'id' }
            },
            {
                title: _('EIR'),
                templateComponent: EIRCellComponent,
                templateInputs: (row) => ({
                    eir: row.eir,
                    action: () => this.onEirPrintClick(row.eir)
                })
            },
            {
                title: _('Type'),
                templateComponent: CellCodeListDashNameComponent,
                templateInputs: (row) => ({
                    codelistName: 'BookingType',
                    code: row.bookingTypeId
                })
            },
            {
                field: 'eqReady',
                title: _('EQ Ready'),
                icon: 'faSolidCircle',
                type: 'boolean',
                iconClass: ({ eqReady }) => ({
                    'text-success': eqReady === EquipmentReadyCodes.Ready,
                    'text-warning': eqReady === EquipmentReadyCodes.Announceable,
                    'text-danger': eqReady === EquipmentReadyCodes.Blocked
                })
            },
            {
                title: _('Container Status'),
                templateComponent: CellCodeListDashNameComponent,
                templateInputs: (row) => ({
                    codelistName: 'ContainerStatus',
                    code: row.containerStatusId
                })
            },
            {
                field: 'bookingId',
                title: _('Booking'),
                isLink: true,
                customRoute: { path: '/booking', field: 'bookingId' }
            },
            { field: 'bookingPin', title: _('PIN') },
            {
                title: _('Scheduled Time'),
                templateComponent: CellDateFromToComponent,
                templateInputs: (row) => ({
                    timeFrom: row.bookingTimeFrom,
                    timeTo: row.bookingTimeTo
                })
            },
            {
                title: _('Haulier'),
                templateComponent: CellCodeListDashNameComponent,
                templateInputs: (row) => ({
                    codelistName: 'Organization',
                    code: row.haulierId
                })
            },
            { field: 'serviceRequest', title: _('Service Request') },
            {
                field: 'containerHeightId',
                title: _('Height'),
                codelistName: 'ContainerHeight',
                type: 'codelist'
            },
            {
                field: 'containerLengthId',
                title: _('Length'),
                type: 'codelist',
                codelistName: 'ContainerLength'
            },
            {
                field: 'containerTypeId',
                title: _('Type'),
                type: 'codelist',
                codelistName: 'ContainerType',
                codelistTooltip: true
            },
            { field: 'owner', title: _('Owner') },
            {
                field: 'emptyFullId',
                title: _('E/F'),
                type: 'codelist',
                codelistName: 'EmptyFull',
                codelistTooltip: true
            },
            { field: 'imdgclass', title: _('IMDG Class') },
            {
                field: 'temperature',
                title: _('°C'),
                type: 'number'
            },
            {
                title: _('Damage'),
                templateComponent: CellDamagesComponent,
                templateInputs: (row) => ({
                    codelistName: 'Damage',
                    codes: row.damageIds
                })
            },
            {
                field: 'customsAllowed',
                title: _('Customs'),
                type: 'boolean'
            },
            {
                field: 'tosAllowed',
                title: _('TOS'),
                type: 'boolean'
            },
            { field: 'location', title: _('Location') },
            // Client wish is to show a tooltip with the error message here, if there is one, but I don't know why not just show the message directly?
            { field: 'errorMessage', title: _('Error') }
        ];
    };

    initializeActionBar = () => {
        this.actionBar = [
            {
                label: _('Container'),
                items: [
                    {
                        icon: 'faSolidUpload',
                        label: _('Import Containers'),
                        onClick: () => {
                            importContainersDialog(this.dialogService);
                        }
                    },
                    {
                        icon: 'faSolidUpload',
                        label: _('Import & Book'),
                        permissions: [ContainerPermissions.Default.ImportAndBook],
                        onClick: () => {
                            // TODO implement
                            alert('Not yet implemented');
                        }
                    },
                    {
                        icon: 'faSolidDownload',
                        label: _('Export'),
                        onClick: () => {
                            // TODO implement
                            alert('Not yet implemented');
                        }
                    }
                ]
            }
        ];
    };

    gridActions: ActionBarGroup[] = [
        {
            label: 'Actions',
            items: [
                {
                    label: _('Create Booking'),
                    icon: 'faSolidPlus',
                    iconOnly: true,
                    variant: 'primary',
                    isVisible: true,
                    isDisabled: () => !this.selectedContainerIds.length,
                    onClick: () => {
                        if (this.selectedContainerIds.length === 0) {
                            alert('Please select at least one container');
                            return;
                        }
                        alert('Selected Container IDs: ' + this.selectedContainerIds.join(', '));
                    }
                },
                {
                    label: _('Delete'),
                    icon: 'faSolidTrash',
                    iconOnly: true,
                    variant: 'danger',
                    isVisible: true,
                    isDisabled: () => !this.selectedContainerIds.length,
                    onClick: () => {
                        // TODO implement
                        alert('Not yet implemented');
                    }
                },
                {
                    label: _('Release'),
                    icon: 'faSolidDownload',
                    iconOnly: true,
                    variant: 'primary',
                    isVisible: true,
                    isDisabled: () => !this.selectedContainerIds.length,
                    onClick: () => {
                        // TODO implement
                        alert('Not yet implemented');
                    }
                }
            ]
        }
    ];

    /**
     * Handles the selection change event from the grid
     * @param selected - The selected container items
     */
    onSelectionChange(selectedContainerIds: string[]) {
        this.selectedContainerIds = selectedContainerIds;
        // required to recalculate the isDisabled in the actionbar buttons
        this.initializeActionBar();
    }
}
