<div *subscribe="query$ as query">
    <app-loader [isBusy]="query?.isLoading">
        <ng-template>
            @if (isDriver) {
                <app-panel-driver-button-view
                    [model]="model"
                    (confirm)="confirm()"
                    (changeTime)="changeTime()"
                    (changePlates)="changePlates()"
                    (addSecondDriver)="addSecondDriver()"
                    (mergeBooking)="mergeBooking()"
                    (unmergeBooking)="unmergeBooking()"
                    (cancel)="cancel()" />
            }
            <app-content-header [title]="getTitle()" [icon]="icon" [actionBar]="actionBar" />
            <app-card>
                <div class="grid grid-cols-12 gap-x-4 gap-y-2" setEditMode [isEditMode]="false">
                    <div class="col-span-3">
                        <app-codelist-input
                            label="Booking status"
                            id="bookingStatus"
                            codelist="BookingStatus"
                            [(value)]="model.bookingStatusId" />
                    </div>
                    <div class="col-span-3">
                        <app-datetime-input label="Scheduled time" id="timeFrom" [(value)]="model.timeFrom" />
                    </div>
                    <div class="col-span-3">
                        <app-text-input label="Terminal" id="terminal" [(value)]="model.terminal" />
                    </div>
                    <div class="col-span-1">
                        <app-boolean-input label="Blocked" id="blocked" [(value)]="model.blocked" />
                    </div>

                    <div class="col-span-3">
                        <app-codelist-input
                            label="Responsible haulier"
                            id="responsibleHaulier"
                            codelist="Organization"
                            [(value)]="model.responsibleHaulierId" />
                    </div>
                    <div class="col-span-3">
                        <app-codelist-input
                            label="Actual haulier"
                            id="actualHaulier"
                            codelist="Organization"
                            [(value)]="model.actualHaulierId" />
                    </div>
                    <div class="col-span-3">
                        <app-text-input label="Driver" id="driverId" [isEditMode]="false" [(value)]="model.driver" />
                    </div>
                    <div class="col-span-3">
                        <app-text-input
                            label="Additional driver"
                            id="additionalDriver"
                            [isEditMode]="false"
                            [(value)]="model.additionalDriver" />
                    </div>
                    <div class="col-span-3">
                        <app-number-input
                            label="Service Request"
                            id="serviceRequest"
                            format="#"
                            [(value)]="model.serviceRequest" />
                    </div>
                    <div class="col-span-3">
                        <app-text-input label="Work order" id="workOrder" [(value)]="model.workOrder" />
                    </div>
                    <div class="col-span-3">
                        <app-text-input label="Truck" id="truckPlate" [(value)]="model.truckPlateNumber" />
                    </div>
                    <div class="col-span-3">
                        <app-text-input label="Trailer" id="trailerPlate" [(value)]="model.trailerPlateNumber" />
                    </div>
                </div>
            </app-card>
            <app-truck-visit-bar [model]="model"></app-truck-visit-bar>
            <app-panel-booking-data
                class="mt-2"
                bookingDataTitle="General cargo list"
                [model]="model.cargo"
                [dataColumns]="generalCargoColumns"></app-panel-booking-data>
        </ng-template>
    </app-loader>
</div>
