import { Component, inject, Injector, signal } from '@angular/core';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { CommonModule } from '@common/common.module';
import { CellCodeListDashNameComponent } from '@common/components/app-grid/columns/grid-cell-template/cell-codelist-dash-name.component';
import { ColumnSettings } from '@common/interfaces/column-settings.interface';
import { CommonService } from '@common/services/common.service';
import { QueryService } from '@common/services/query.service';
import { ToastrNotificationService } from '@common/services/toastr-notification.service';
import { SignalQueryResult } from '@ngneat/query';
import { DialogContentBase } from '@progress/kendo-angular-dialog';
import { RowSelectableFn } from '@progress/kendo-angular-grid';
import { CellDamagesComponent } from '../container-list/cell-templates/cell-damages.component';

type ImportContainerModel = {
    containerCode: string;
    releaseOrder: string;
    srvNumber: string;
    bookingTos: string;
    lengthCode: string;
};

type ImportContainerDataModel = {
    containerCode: string;
    containerStatus: string;
    owner: string;
    bookingType: string;
    vgmWeighing: string;
    bookingNumber: string;
    releaseNumber: string;
    srvNumber: string;
    lineCode: string;
    emptyFullCode: string;
    vgm: boolean;
    imdgClasses: string;
    bookingTypeCode: string;
    blocked: boolean;
    blockedMessage: string;
    dueDateFrom: string;
    dueDateTo: string;
    actualLocationCode: string;
    plannedLocationCode: string;
    containerTypeCode: string;
    lengthCode: string;
    heightCode: string;
    freightForwarderCode: string;
    freightForwarderId: string;
    exists: boolean;
    externalNumber: number;
    tosOrder: number;
    customsAllowed: boolean;
    tosAllowed: boolean;
    pendingCntmIn: boolean;
    cargoDirection: string;
    unNumberCode: string;
    damageTypeCode: string;
    temperature: string;
    location: string;
};

@Component({
    selector: 'app-import-container-dialog',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './import-container-dialog.component.html'
})
export class ImportContainerDialogComponent extends DialogContentBase {
    commonService = inject(CommonService);
    queryService = inject(QueryService);
    injector = inject(Injector);
    toasterService = inject(ToastrNotificationService);
    model: ImportContainerModel = {
        containerCode: null,
        releaseOrder: null,
        srvNumber: null,
        bookingTos: null,
        lengthCode: null
    };
    containerQuery: SignalQueryResult<ImportContainerDataModel[]> = signal(null);
    options = {
        title: this.commonService.translateService.instant('Import Containers')
    };
    selection = [];

    columns: ColumnSettings[] = [
        {
            field: 'containerCode',
            title: _('Container')
        },
        {
            field: 'customsAllowed',
            title: _('EQ Ready'),
            icon: 'faSolidCircle',
            type: 'boolean',
            iconClass: (cnt: ImportContainerDataModel) => this.eqReadyClass(cnt)
        },
        {
            title: _('Type'),
            templateComponent: CellCodeListDashNameComponent,
            templateInputs: (row) => ({
                codelistName: 'BookingType',
                code: row.bookingType
            })
        },
        {
            field: 'bookingNumber',
            title: _('Booking'),
            isLink: true,
            customRoute: { path: '/booking', field: 'bookingId' }
        },
        { field: 'srvNumber', title: _('Service Request') },
        { field: 'lineCode', title: _('Line') },
        { field: 'vgm', title: _('VGM'), type: 'boolean' },
        { field: 'imdgclass', title: _('IMDG Class') },
        { field: 'dueDateFrom', title: _('Due Date From'), type: 'date' },
        { field: 'dueDateTo', title: _('Due Date To'), type: 'date' },
        {
            field: 'freightForwarderCode',
            title: _('Freight Forwarder')
        },
        { field: 'location', title: _('Location') },
        {
            field: 'temperature',
            title: _('°C'),
            type: 'number'
        },
        {
            title: _('Damage'),
            templateComponent: CellDamagesComponent,
            templateInputs: (row) => ({
                codelistName: 'Damage',
                codes: row.damageIds
            })
        }
    ];

    close() {
        this.dialog.close();
    }

    confirm() {
        if (this.selection.length === 0) {
            this.toasterService.show({ type: 'error', message: 'Please select at least one container' });
        } else {
            this.queryService.getCommandMutation().mutateAsync(
                {
                    command: `ImportContainers`,
                    data: {
                        containers: this.containerQuery().data.filter((container) =>
                            this.selection.some((id) => id == container.containerCode)
                        )
                    },
                    invalidate: 'containers'
                },
                {
                    onSuccess: () => {
                        this.toasterService.show({ type: 'success', message: 'Containers imported successfully' });
                        this.close();
                    }
                }
            );
        }
    }

    async queryContainers() {
        const { containerCode, releaseOrder, srvNumber, bookingTos, lengthCode } = this.model;
        if (!containerCode && !releaseOrder && !srvNumber && !bookingTos) {
            this.toasterService.show({ type: 'error', message: 'Please enter at least one search criteria' });
            return;
        }
        this.containerQuery = await this.queryService.getQuery<ImportContainerDataModel[]>(
            'importContainers',
            {
                containerCode,
                releaseOrder,
                srvNumber,
                bookingTos,
                lengthCode
            },
            {
                injector: this.injector,
                refetchOnWindowFocus: false
            }
        ).result;
    }

    eqReadyClass(cnt: ImportContainerDataModel) {
        const customsAllowed = cnt.customsAllowed && !cnt.blocked;
        if (cnt.pendingCntmIn || (!customsAllowed && !cnt.tosAllowed)) return 'text-danger';
        if ((customsAllowed && !cnt.tosAllowed) || (!customsAllowed && cnt.tosAllowed)) return 'text-warning';

        return 'text-success';
    }

    onSelectedKeysChange(keys: any[]) {
        this.selection = keys;
    }

    isRowSelectable: RowSelectableFn = (context) => {
        return !context.dataItem.exists;
    };
}
