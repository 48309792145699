import { customCondition } from '@administration/codelists/codelists.interface';
import { Component, inject, ViewChild } from '@angular/core';
import { IconKey } from '@common/classes/icons';
import { CodelistInputComponent } from '@common/components/input/codelist-input/codelist-input.component';
import { TranslateService } from '@ngx-translate/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

export interface DialogSecondDriverOptions {
    title?: string;
    titleIcons?: IconKey[];
    message?: string;
    cancelText?: string;
    cancelIcon?: IconKey;
    cancelAction?: Function;
    confirmText?: string;
    confirmIcon?: IconKey;
    confirmMessage?: string;
    model?: any[];
}

@Component({
    selector: 'app-dialog-add-additional-driver',
    templateUrl: './dialog-add-additional-driver.component.html'
})
export class DialogAddAdditionalDriverComponent extends DialogContentBase {
    override dialog = inject<DialogRef>(DialogRef);
    translateService = inject(TranslateService);

    model: any;
    options: DialogSecondDriverOptions = {
        title: this.translateService.instant('Add second driver'),
        message: ''
    };
    @ViewChild('driver') truck: CodelistInputComponent;

    public initialize(options: DialogSecondDriverOptions) {
        for (const [key, value] of Object.entries(options)) {
            if (![undefined, null].includes(value)) this.options[key] = value;
        }
        this.model = this.options.model;
    }

    confirm(): void {
        // if (this.appControls.some((x) => !!x.error)) {
        //     this.toastrNotificationService.show({ type: 'error', message: 'Some required fields are empty' });
        //     return;
        // }
        this.model.additionalDriver = this.truck.formatedSelectedOptions;
        this.dialog.close(this.model);
    }

    close(result: boolean | undefined = undefined) {
        this.dialog.close(result);
    }

    organizationCondition = () => {
        return [{ name: 'Organization', condition: customCondition.Equal, value: this.model.organizationId }];
    };
}
