<div *subscribe="query$ as query">
    <app-loader [isBusy]="query?.isLoading">
        <ng-template>
            @if (isDriver) {
                <app-panel-driver-button-view
                    [model]="model"
                    (confirm)="confirm()"
                    (changeTime)="changeTime()"
                    (changePlates)="changePlates()"
                    (addSecondDriver)="addSecondDriver()"
                    (mergeBooking)="mergeBooking()"
                    (unmergeBooking)="unmergeBooking()"
                    (cancel)="cancel()" />
            }
            <app-content-header [title]="getTitle()" [icon]="icon" [actionBar]="actionBar" />
            @if (viewMode) {
                <app-card>
                    <div class="grid grid-cols-12 gap-x-4 gap-y-2">
                        <div class="col-span-3">
                            <app-codelist-input
                                label="Booking status"
                                codelist="BookingStatus"
                                [(value)]="model.bookingStatusId" />
                        </div>
                        <div class="col-span-3">
                            <app-datetime-input label="Scheduled time" id="timeFrom" [(value)]="model.timeFrom" />
                        </div>
                        <div class="col-span-3">
                            <app-text-input label="Terminal" id="terminal" [(value)]="model.terminal" />
                        </div>
                        <div class="col-span-1">
                            <app-boolean-input label="Blocked" id="blocked" [(value)]="model.blocked" />
                        </div>

                        <div class="col-span-3">
                            <app-codelist-input
                                label="Responsible haulier"
                                id="responsibleHaulier"
                                codelist="Organization"
                                [(value)]="model.responsibleHaulierId" />
                        </div>
                        <div class="col-span-3">
                            <app-codelist-input
                                label="Actual haulier"
                                id="actualHaulier"
                                codelist="Organization"
                                [(value)]="model.actualHaulierId" />
                        </div>
                        <div class="col-span-3">
                            <app-text-input label="Driver" id="driver" [(value)]="model.driver" />
                        </div>
                        <div class="col-span-3">
                            <app-text-input
                                label="Additional driver"
                                id="additionalDriver"
                                [(value)]="model.additionalDriver" />
                        </div>
                        <div class="col-span-3">
                            <app-text-input label="Truck" id="truckPlate" [(value)]="model.truckPlateNumber" />
                        </div>
                        <div class="col-span-3">
                            <app-text-input label="Trailer" id="trailerPlate" [(value)]="model.trailerPlateNumber" />
                        </div>
                    </div>
                </app-card>
                <app-truck-visit-bar [model]="model"></app-truck-visit-bar>
                <app-panel-booking-data
                    class="mt-2"
                    bookingDataTitle="Containers list"
                    [model]="model.containers"
                    [dataColumns]="containerColumns"></app-panel-booking-data>
            } @else {
                <div class="flex flex-col gap-2">
                    <app-card class="w-full">
                        <div class="flex w-full flex-col gap-2">
                            <h3 class="text-xl font-semibold text-foreground">
                                {{ 'Slots' | translate }}
                            </h3>
                            <app-date-input
                                label="Booking date"
                                id="lastModifiedDate"
                                [isEditMode]="editMode"
                                [isDisabled]="!isPropertyEditable('slot')"
                                [(value)]="model.bookingDate"
                                (valueChange)="onDateChange($event)" />
                            @if (isSelectorShown(this.model?.containers)) {
                                <div class="swiper-wrapper">
                                    <app-scroll-slot-selector [slots]="slots" (slotSelect)="onSlotChange($event)" />
                                </div>
                            } @else {
                                <app-dropdown-slot-selector
                                    [date]="this.model.bookingDate"
                                    (valueChange)="onTimeSlotSelected($event)"></app-dropdown-slot-selector>
                            }
                        </div>
                    </app-card>
                    <div class="flex gap-2">
                        <app-card class="w-3/5">
                            <form class="mb-4 flex flex-col gap-2" (ngSubmit)="search()">
                                <h3 class="text-xl font-semibold text-foreground">
                                    {{ 'Search containers' | translate }}
                                </h3>
                                <div class="flex w-full items-end gap-2">
                                    <app-text-input
                                        label="Container"
                                        id="container"
                                        [isEditMode]="editMode"
                                        [(value)]="filter.container" />
                                    <app-text-input
                                        label="SVR Number"
                                        id="svrNumber"
                                        [isEditMode]="editMode"
                                        [(value)]="filter.serviceRequest" />
                                    <app-button
                                        class="mt-6 px-2"
                                        size="sm"
                                        icon="faSolidMagnifyingGlass"
                                        variant="primary"
                                        tooltip="Search for container"
                                        (click)="search()">
                                        Search
                                    </app-button>
                                </div>
                            </form>
                            <app-grid
                                [data]="containerData"
                                [cols]="containerEditColumns"
                                [defaultViewMode]="defaultViewMode"
                                [queryData]="search"
                                [autoFitColumns]="false"
                                [showToolbar]="false"
                                [pageable]="false"
                                (saveGridSettings)="saveGridSettings($event)"
                                (deleteGridSettings)="deleteGridSettings()" />
                        </app-card>
                        <app-card class="w-2/5">
                            <div class="flex flex-col gap-6">
                                <!-- Booking Details section -->
                                <div>
                                    <h3 class="text-xl font-semibold text-foreground">
                                        {{ 'Booking details' | translate }}
                                    </h3>
                                    <div class="mb-4 mt-2 flex w-full flex-col rounded-md px-4 py-2 ring-1 ring-danger">
                                        <h5 class="text-xs text-danger">{{ 'Reserved Slot' | translate }}</h5>
                                        <span class="text-md font-medium text-danger">
                                            @if (model.timeFrom && model.timeTo) {
                                                {{ model.timeFrom | datetime | empty }} -
                                                {{ model.timeTo | time | empty }}
                                            } @else {
                                                {{ 'No slot reserved' | translate }}
                                            }
                                        </span>
                                    </div>
                                    <div class="grid grid-cols-4 gap-2">
                                        <div class="col-span-4 md:col-span-2">
                                            <app-codelist-input
                                                label="Responsible haulier"
                                                #responsiblehaulier
                                                id="responsibleHaulier"
                                                codelist="Organization"
                                                [isEditMode]="editMode"
                                                [isDisabled]="createMode"
                                                [(value)]="model.responsibleHaulierId" />
                                        </div>
                                        <div class="col-span-4 md:col-span-2">
                                            <app-codelist-input
                                                label="Actual haulier"
                                                #haulier
                                                id="actualHaulier"
                                                codelist="Organization"
                                                [isEditMode]="editMode"
                                                [isDisabled]="!isPropertyEditable('haulier')"
                                                [isRequired]="true"
                                                [(value)]="model.actualHaulierId"
                                                (valueChange)="onActualHaulierChange($event)" />
                                        </div>
                                        <div class="col-span-4 md:col-span-2">
                                            <app-codelist-input
                                                label="Driver"
                                                #driver
                                                id="driverId"
                                                codelist="Driver"
                                                [customCondition]="util.organizationCondition(model)"
                                                [isDisabled]="!isPropertyEditable('driver')"
                                                [isEditMode]="editMode"
                                                [isRequired]="true"
                                                [(value)]="model.driverId"
                                                (valueChange)="onDriverChange($event)" />
                                        </div>
                                        <div class="col-span-4 md:col-span-2">
                                            <app-codelist-input
                                                label="Additional Driver"
                                                #driverAdditional
                                                id="additionalDriverId"
                                                codelist="Driver"
                                                [customCondition]="util.organizationCondition(model)"
                                                [isDisabled]="
                                                    !isPropertyEditable('additionalDriver') && !model.driverId
                                                "
                                                [isEditMode]="editMode"
                                                [(value)]="model.additionalDriverId" />
                                        </div>
                                        @if (selectedHaulier.isTemporary) {
                                            <div class="col-span-4 md:col-span-2">
                                                <app-text-input
                                                    label="Truck"
                                                    #truck
                                                    id="truckTmp"
                                                    [isDisabled]="
                                                        !isPropertyEditable('truck') && !model.actualHaulierId
                                                    "
                                                    [isEditMode]="editMode"
                                                    [(value)]="model.tempTruck" />
                                            </div>
                                            <div class="col-span-4 md:col-span-2">
                                                <app-text-input
                                                    label="Trailer"
                                                    id="trailerTmp"
                                                    [isDisabled]="
                                                        !isPropertyEditable('trailer') && !model.actualHaulierId
                                                    "
                                                    [isEditMode]="editMode"
                                                    [(value)]="model.tempTrailer" />
                                            </div>
                                        } @else {
                                            <div class="col-span-4 md:col-span-2">
                                                <app-codelist-input
                                                    label="Truck"
                                                    #truck
                                                    id="truck"
                                                    codelist="Fleet"
                                                    [customCondition]="util.plateTruckCodelistCondition(model)"
                                                    [isDisabled]="
                                                        !isPropertyEditable('truck') && !model.actualHaulierId
                                                    "
                                                    [isEditMode]="editMode"
                                                    [(value)]="model.truckId" />
                                            </div>
                                            <div class="col-span-4 md:col-span-2">
                                                <app-codelist-input
                                                    label="Trailer"
                                                    #trailer
                                                    id="trailer"
                                                    codelist="Fleet"
                                                    [customCondition]="util.plateTrailerCodelistCondition(model)"
                                                    [isDisabled]="
                                                        !isPropertyEditable('trailer') && !model.actualHaulierId
                                                    "
                                                    [isEditMode]="editMode"
                                                    [(value)]="model.trailerId" />
                                            </div>
                                        }
                                        <div class="col-span-4 md:col-span-2">
                                            <app-text-input
                                                label="Announced Mot no"
                                                id="announcedMotNo"
                                                [isEditMode]="editMode"
                                                [isDisabled]="true"
                                                [(value)]="model.announcedMotNo" />
                                        </div>
                                    </div>
                                </div>
                                <!--  Containers section  -->
                                <div class="flex w-full flex-wrap gap-2">
                                    <h3 class="mb-2 text-xl font-semibold text-foreground">
                                        {{ 'Containers' | translate }}
                                    </h3>
                                    <div class="w-full">
                                        <span class="text-lg font-medium text-primary">
                                            {{ 'For discharge' | translate }}
                                        </span>
                                        @if (containerDischarge.length === 0) {
                                            <div
                                                class="rounded-md border border-dashed border-muted p-3 text-center italic text-muted">
                                                {{ 'No containers for discharge' | translate }}
                                            </div>
                                        } @else {
                                            <table
                                                class="w-full border-separate border-spacing-x-0.5 border-spacing-y-1">
                                                <tr class="text-xs text-neutral-400 *:px-3 *:text-start *:font-normal">
                                                    <th translate>Code</th>
                                                    <th translate>Length</th>
                                                    <th translate>E/F</th>
                                                    <th translate>Loaded on</th>
                                                    <th translate>Plate</th>
                                                    <th></th>
                                                </tr>
                                                @for (item of containerDischarge; track item) {
                                                    <tr class="*:bg-background *:py-2">
                                                        <td class="rounded-bl-md rounded-tl-md px-3">
                                                            {{ item.containerCode }}
                                                        </td>
                                                        <td class="px-3">
                                                            {{ item?.length }}
                                                        </td>
                                                        <td class="px-3">
                                                            {{ item?.emptyFullId }}
                                                        </td>
                                                        <td class="w-32 px-3">
                                                            <app-select-input
                                                                label=""
                                                                id="loadedOn"
                                                                [isEditMode]="true"
                                                                [isDisabled]="false"
                                                                [options]="optionsLocation"
                                                                [(value)]="item.loadedOnId" />
                                                        </td>
                                                        <td class="px-3">
                                                            @if (item?.loadedOnId === 'K') {
                                                                <div class="ml-2">
                                                                    {{ model?.truckId | codelist: 'Fleet' | async }}
                                                                </div>
                                                            } @else if (item?.loadedOnId === 'P') {
                                                                <div class="ml-2">
                                                                    {{ model?.trailerId | codelist: 'Fleet' | async }}
                                                                </div>
                                                            } @else {
                                                                <div class="ml-2">
                                                                    {{ '-' }}
                                                                </div>
                                                            }
                                                        </td>
                                                        <td class="w-5 rounded-br-md rounded-tr-md">
                                                            <app-button
                                                                class="min-w-fit px-3 shadow-none"
                                                                icon="faSolidXmark"
                                                                tooltip="Remove container"
                                                                size="sm"
                                                                variant="link"
                                                                [disabled]="!isPropertyEditable('cargo')"
                                                                (click)="removeDischargeContainer(item)" />
                                                        </td>
                                                    </tr>
                                                }
                                            </table>
                                        }
                                    </div>
                                    <div class="w-full">
                                        <span class="text-lg font-medium text-primary">
                                            {{ 'For loading' | translate }}
                                        </span>
                                        @if (containerLoad?.length === 0) {
                                            <div
                                                class="rounded-md border border-dashed border-muted p-3 text-center italic text-muted">
                                                {{ 'No containers for loading' | translate }}
                                            </div>
                                        } @else {
                                            <table
                                                class="w-full border-separate border-spacing-x-0.5 border-spacing-y-1">
                                                <tr class="text-xs text-neutral-400 *:px-3 *:text-start *:font-normal">
                                                    <th translate>Code</th>
                                                    <th translate>Length</th>
                                                    <th translate>E/F</th>
                                                    <th translate>Loaded on</th>
                                                    <th translate>Plate</th>
                                                    <th></th>
                                                </tr>
                                                @for (item of containerLoad; track item) {
                                                    <tr class="*:bg-background *:py-2">
                                                        <td class="rounded-bl-md rounded-tl-md px-3">
                                                            {{ item.containerCode }}
                                                        </td>
                                                        <td class="px-3">
                                                            {{ item?.length }}
                                                        </td>
                                                        <td class="px-3">
                                                            {{ item?.emptyFullId }}
                                                        </td>
                                                        <td class="w-20 px-3">
                                                            <app-select-input
                                                                label=""
                                                                id="loadedOn"
                                                                [isEditMode]="true"
                                                                [isDisabled]="false"
                                                                [options]="optionsLocation"
                                                                [(value)]="item.loadedOnId" />
                                                        </td>
                                                        <td class="px-3">
                                                            @if (item?.loadedOnId === 'K') {
                                                                <div class="ml-2">
                                                                    {{ model?.truckId | codelist: 'Fleet' | async }}
                                                                </div>
                                                            } @else if (item?.loadedOnId === 'P') {
                                                                <div class="ml-2">
                                                                    {{ model?.trailerId | codelist: 'Fleet' | async }}
                                                                </div>
                                                            } @else {
                                                                <div class="ml-2">
                                                                    {{ '-' }}
                                                                </div>
                                                            }
                                                        </td>
                                                        <td class="w-5 rounded-br-md rounded-tr-md">
                                                            <app-button
                                                                class="min-w-fit px-3 shadow-none"
                                                                icon="faSolidXmark"
                                                                tooltip="Remove container"
                                                                size="sm"
                                                                variant="link"
                                                                [disabled]="!isPropertyEditable('cargo')"
                                                                (click)="removeLoadContainer(item)" />
                                                        </td>
                                                    </tr>
                                                }
                                            </table>
                                        }
                                    </div>
                                </div>
                            </div>
                        </app-card>
                    </div>
                </div>
            }
        </ng-template>
    </app-loader>
</div>
