<div *ngIf="listOfActiveFilters.length > 0" class="relative flex items-start pb-2">
    <div
        class="scrollbar-hide flex w-full origin-top scale-y-0 flex-col flex-wrap items-start overflow-x-auto whitespace-nowrap transition-all"
        #scrollContainer
        [ngClass]="{ 'scale-y-0': listOfActiveFilters.length === 0, 'scale-y-1': listOfActiveFilters.length > 0 }"
        (scroll)="checkScrollPosition(scrollContainer)">
        <span class="absolute text-xs text-gray-500">{{ 'Active Filters' | translate }}</span>
        <div class="mt-4 flex flex-row space-x-1">
            @for (filter of listOfActiveFilters; track $index) {
                <app-button
                    class="rounded-md border-2 border-tertiary bg-light"
                    variant="link"
                    size="sm"
                    icon="faSolidXmark"
                    iconPosition="end"
                    (clicked)="clearFilter.emit(filter.label)">
                    {{ addSpacesBetweenWords(filter.label) }}: {{ filter.value }}
                </app-button>
            }
        </div>
    </div>
    <app-button
        *ngIf="showLeftScroll"
        class="absolute mt-2 h-8 rounded-none bg-background-lighter hover:bg-popover"
        variant="link"
        size="sm"
        icon="faSolidAngleLeft"
        iconPosition="start"
        tooltip="Scroll"
        (clicked)="scrollToPreviousFilter(scrollContainer)"></app-button>
    <app-button
        *ngIf="showRightScroll"
        class="absolute right-0 mt-2 h-8 whitespace-nowrap rounded-none bg-background-lighter hover:bg-popover"
        variant="link"
        size="sm"
        icon="faSolidAngleRight"
        iconPosition="end"
        tooltip="Scroll"
        (clicked)="scrollToNextFilter(scrollContainer)"></app-button>
</div>
