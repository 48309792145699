import { Component } from '@angular/core';
import { BaseViewComponent } from '@common/classes/base-view';
import { ContainerPermissions } from '@common/classes/permissions';
import { CommonService } from '@common/services/common.service';
import { Breadcrumb } from '@common/services/navigation.service';
import { NavItem } from 'app/navigation';
import { ContainerViewModel } from '../container.interface';

@Component({
    selector: 'app-container-view',
    templateUrl: './container-view.component.html',
    styleUrl: './container-view.component.scss',
    providers: [CommonService]
})
export class ContainerViewComponent extends BaseViewComponent<ContainerViewModel> {
    override entityName = 'Container';
    override editPermission = ContainerPermissions.Default.Edit;
    override currentNavItem: NavItem = { icon: 'faSolidCube', title: 'Container', id: null };
    override breadcrumbs: Breadcrumb[] = [
        {
            title: 'Containers',
            icon: 'faSolidCubes',
            route: '/containers/list'
        },
        {
            title: 'Container',
            icon: 'faSolidCube'
        }
    ];

    // TODO: implement, then remove this
    override canEdit(): boolean {
        return false;
    }

    constructor(commonService: CommonService) {
        super(commonService);
    }
}
