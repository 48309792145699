import { FakedCodelists } from '@administration/codelists/codelists.interface';
import { Codelists } from '@administration/codelists/codelists.service';
import { inject, Injector, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { QueryService } from '@common/services/query.service';
import { mapResultData, takeUntilResultFinalize } from '@ngneat/query';
import { map, of, Subscription } from 'rxjs';

@Pipe({
    name: 'codelist'
})
export class CodelistPipe implements PipeTransform, OnDestroy {
    private codelistSubscription: Subscription = new Subscription();
    injector = inject(Injector);

    constructor(private queryService: QueryService) {}

    ngOnDestroy(): void {
        this.codelistSubscription.unsubscribe();
    }

    /**
     * Transforms a given codelist item ID to its corresponding display text.
     *
     * @param id The id of the codelist item to be transformed.
     * @param codelistName The name of the codelist from which to fetch the display value.
     * @param prefixItemWithId (Optional) Whether to prefix the display value with the item's code or ID. Defaults to true.
     * @returns An observable that emits the transformed display text, or a default value in case of error or if not found.
     *
     * @example
     * {{ row[column.field] | codelist: column.codelistName : false | async }}
     */
    transform(id: string, codelistName: Codelists | FakedCodelists, prefixItemWithId: boolean = false) {
        if (!codelistName || typeof codelistName !== 'string') return of(null);
        if (!id) return of('-');

        // Unsubscribe previous subscription if any before creating a new one
        // to avoid memory leaks on rapid value changes
        this.codelistSubscription.unsubscribe();

        const codelistQuery$ = this.queryService.getCodelistQuery<string>({
            name: codelistName,
            selectedIds: [id],
            take: 1,
            filter: null,
            injector: this.injector
        }).result$;

        //if (asString) {
        return codelistQuery$.pipe(
            mapResultData((data) => {
                return this.getText(data?.[0], id, codelistName, prefixItemWithId);
            }),
            takeUntilResultFinalize(),
            map((result) => result.data)
        );
    }

    private getText(item: any, id: string, codelist: string, prefixItemWithId: boolean): string {
        if (!item) {
            console.error(`${id} not found in codelist ${codelist}`);
            return '-';
        }

        const itemPrefix = prefixItemWithId ? `${item.code || item.id} - ` : '';
        return item.customText || `${itemPrefix}${item.name}`;
    }
}
