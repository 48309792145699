import { AfterViewInit, Component } from '@angular/core';
import { Setting } from '@common/interfaces/setting.interface';
import { SettingKeyCodes } from '@common/known-types/setting-key.codes';
import { CommonService } from '@common/services/common.service';
import { LanguageIds } from '@common/services/i18n.service';
import { environment } from '@environments/environment';
import { LoginDriver, LoginUser } from './login.interface';

@Component({ template: '' })
export abstract class BaseLoginComponent implements AfterViewInit {
    protected user: LoginUser = { username: '', password: '' };
    protected driver: LoginDriver = { badgeId: '', pin: '', date: new Date() };

    errorMessage: string;
    currentLanguage = this.commonService.i18nService.currentLanguage;
    isBusy = false;
    environment = environment.name;
    settings = environment.settings;
    environmentColor = environment.settings.header.environmentColor;
    title = environment.title;
    logo = environment.settings.header.logo;
    languages = this.commonService.i18nService.languages;
    version = environment.version;

    showTermsAndConditions = false;
    showRegister = false;
    showForgetPassword = false;
    showLanguageFlag = false;
    certificateLogin = false;
    googleLogin = false;
    appleLogin = false;
    facebookLogin = false;

    constructor(protected commonService: CommonService) {
        commonService.userService.isAuthenticatedSubject.next(false);
        //on refresh always load default language even if another selected
        //change to selected language
        const enumCurrentLanguage = LanguageIds[this.currentLanguage().languageId as keyof typeof LanguageIds];
        commonService.i18nService.changeDisplayLanguage(enumCurrentLanguage);
    }

    ngAfterViewInit() {
        this.getAppSettings();
    }

    toggleTheme() {
        this.commonService.darkModeService.toggle();
    }

    onSubmit(driverLogin: boolean = false) {
        if (driverLogin) this.signInDriver();
        else this.signIn();
    }

    isDarkTheme() {
        return this.commonService.darkModeService.get();
    }

    private signIn() {
        this.isBusy = true;
        this.errorMessage = null;
        const returnUrl = this.commonService.router.routerState.snapshot.root.queryParams.returnUrl ?? '/';

        if (!this.user.username || !this.user.password) {
            this.commonService.toastrNotificationService.show({
                type: 'error',
                message: 'Username and Password are required fields',
                title: 'Error'
            });
            this.isBusy = false;
        } else {
            this.commonService.userService
                .login(this.user.username, this.user.password)
                .then(() => {
                    this.commonService.router.navigateByUrl(returnUrl);
                })
                .catch((errorMessage) => {
                    this.commonService.toastrNotificationService.show({
                        type: 'error',
                        title: 'Error',
                        message: errorMessage
                    });
                })
                .finally(() => (this.isBusy = false));
        }
    }

    private signInDriver() {
        this.isBusy = true;
        this.errorMessage = null;
        if (!this.driver.badgeId || !this.driver.pin) {
            this.commonService.toastrNotificationService.show({
                type: 'error',
                message: 'Badge ID and Pin are required fields',
                title: 'Error'
            });
            this.isBusy = false;
        } else {
            this.commonService.userService
                .loginDriver(this.driver.badgeId, this.driver.pin, this.driver.date)
                .catch((errorMessage) => {
                    this.commonService.toastrNotificationService.show({
                        type: 'error',
                        title: 'Error',
                        message: errorMessage
                    });
                })
                .finally(() => (this.isBusy = false));
        }
    }

    protected getAppSettings() {
        this.commonService.queryService.queryClient
            .ensureQueryData({ queryKey: ['loginSettings'] })
            .then((result: Setting[]) => {
                this.showTermsAndConditions = this.getSettingValueBoolean(result, SettingKeyCodes.TermsAndConditions);
                this.showForgetPassword = this.getSettingValueBoolean(result, SettingKeyCodes.ForgetPassword);
                this.showLanguageFlag = this.getSettingValueBoolean(result, SettingKeyCodes.LanguageFlag);
                this.title = this.getSettingValue(result, SettingKeyCodes.LongCompanyName);
            });
    }

    private getSettingValue(result: Setting[], settingKey: SettingKeyCodes): string | undefined {
        const setting = result?.find((s: Setting) => s.key === settingKey);
        return setting?.value;
    }

    private getSettingValueBoolean(result: Setting[], settingKey: SettingKeyCodes): boolean {
        const value = this.getSettingValue(result, settingKey);
        return value === 'true';
    }

    private handleLogo(imageContent: string) {
        const decodedData = atob(imageContent);
        const uint8Array = new Uint8Array(decodedData.length);
        for (let i = 0; i < decodedData.length; ++i) {
            uint8Array[i] = decodedData.charCodeAt(i);
        }
        const blob = new Blob([uint8Array]);
        this.logo = URL.createObjectURL(blob);
    }
}
