import { fakedCodelists } from '@administration/codelists/codelists.interface';
import { codelists } from '@administration/codelists/codelists.service';
import { inject, Injectable } from '@angular/core';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { FormProperty } from '@common/components/app-control/app-control.component';
import { IconCellComponent, IconCellInputs } from '@common/components/app-grid/columns/icon-cell/icon-cell.component';
import { ColumnSettings } from '@common/interfaces/column-settings.interface';
import { CommonService } from '@common/services/common.service';
import { BookingListUtilsService } from './booking-list-utils.service';

@Injectable({
    providedIn: 'root'
})
export class BookingListDefinitionsService {
    common = inject(CommonService);
    util = inject(BookingListUtilsService);
    constructor() {}

    //#region Column Definitions
    public cargoTypeColumn: ColumnSettings = {
        field: 'cargoTypeId',
        title: _('Type'),
        width: 85,
        templateComponent: IconCellComponent,
        templateInputs: (row) =>
            ({
                icons: [
                    {
                        tooltip: _('Cargo Type'),
                        icon: this.util.showIconForCargoType(row),
                        iconClass: 'text-lg text-muted'
                    },
                    {
                        tooltip: _('Pick up'),
                        icon: this.util.showIconPickUp(row),
                        iconClass: 'text-lg text-muted'
                    },
                    {
                        tooltip: _('Drop off'),
                        icon: this.util.showIconDropOff(row),
                        iconClass: 'text-lg text-muted'
                    }
                ]
            }) as IconCellInputs
    };

    public bookingStatusColumn: ColumnSettings = {
        field: 'bookingStatusId',
        title: _('Status'),
        type: 'codelist',
        codelistName: codelists.bookingStatus,
        codelistPill: true,
        class: 'min-w-32'
    };

    public pinColumn: ColumnSettings = { field: 'pin', title: _('Pin'), width: 50 };

    public bookingColumn: ColumnSettings = {
        field: 'id',
        isLink: true,
        title: _('Booking'),
        width: 60
    };

    public organizationColumn: ColumnSettings = {
        field: 'organizationId',
        title: _('Organization'),
        type: 'codelist',
        codelistName: fakedCodelists.organization,
        width: 80
    };

    public timeFromColumn: ColumnSettings = {
        field: 'timeFrom',
        field2: 'timeTo',
        title: _('Scheduled'),
        type: 'datetimespan',
        width: 210
    };

    public gateColumn: ColumnSettings = {
        field: 'gateId',
        title: _('Gate'),
        width: 100,
        type: 'codelist',
        codelistName: codelists.gate,
        class: 'min-w-32'
    };

    public haulierColumn: ColumnSettings = {
        field: 'haulierId',
        title: _('Haulier'),
        width: 100,
        type: 'codelist',
        class: 'min-w-32',
        codelistName: fakedCodelists.organization,
        codelistTooltip: true
    };

    public driverColumn: ColumnSettings = {
        field: 'driver',
        title: _('Driver'),
        width: 100
    };

    public truckColumn: ColumnSettings = {
        field: 'truckPlateNumber',
        title: _('Truck'),
        width: 100
    };

    public trailerColumn: ColumnSettings = {
        field: 'trailerPlateNumber',
        title: _('Trailer'),
        width: 100
    };

    public blockedColumn: ColumnSettings = {
        field: 'blocked',
        title: _('Blocked'),
        width: 60,
        type: 'boolean'
    };
    //#endregion

    //#region Filter Parameter Definitions
    public bookingFilterProperty: FormProperty = {
        name: 'bookingId', //name for backend, also keep filter.{name} same
        label: this.common.translateService.instant('Booking'),
        type: 'number',
        colSpan: 4
    };

    public statusFilterProperty: FormProperty = {
        name: 'status',
        label: this.common.translateService.instant('Status'),
        type: 'codelist',
        codelist: codelists.bookingStatus,
        colSpan: 4
    };

    public pinFilterProperty: FormProperty = {
        name: 'pin',
        label: this.common.translateService.instant('Pin'),
        colSpan: 4,
        type: 'string',
        multi: true
    };

    public organizationFilterProperty: FormProperty = {
        name: 'organizationId',
        label: this.common.translateService.instant('Organization'),
        type: 'codelist',
        codelist: fakedCodelists.organization,
        codelistLabelFormat: 'LongCode',
        colSpan: 4
    };

    public dateFromFilterProperty: FormProperty = {
        name: 'timeFrom',
        label: this.common.translateService.instant('Time From'),
        type: 'datetime',
        colSpan: 4
    };

    public dateToFilterProperty: FormProperty = {
        name: 'timeTo',
        label: this.common.translateService.instant('Time To'),
        type: 'datetime',
        colSpan: 4
    };

    public haulierFilterProperty: FormProperty = {
        name: 'haulier',
        label: this.common.translateService.instant('Haulier'),
        colSpan: 4,
        codelistLabelFormat: 'OnlyName',
        type: 'codelist',
        codelist: fakedCodelists.organization
    };

    public driverFilterProperty: FormProperty = {
        name: 'driver',
        label: this.common.translateService.instant('Driver'),
        colSpan: 4,
        type: 'codelist',
        codelist: fakedCodelists.driver
    };

    public truckFilterProperty: FormProperty = {
        name: 'truck',
        label: this.common.translateService.instant('Truck'),
        colSpan: 2,
        type: 'codelist',
        codelist: fakedCodelists.fleet
    };

    public trailerFilterProperty: FormProperty = {
        name: 'trailer',
        label: this.common.translateService.instant('Trailer'),
        colSpan: 2,
        type: 'codelist',
        codelist: fakedCodelists.fleet
    };

    public plateFilterProperty: FormProperty = {
        name: 'plate',
        label: this.common.translateService.instant('Truck/trailer plate number'),
        colSpan: 4,
        type: 'string'
    };

    public commonFilterProperty: FormProperty = {
        name: 'common',
        label: this.common.translateService.instant('Common'),
        colSpan: 4,
        type: 'boolean',
        nullable: true
    };

    public blockedFilterProperty: FormProperty = {
        name: 'blocked',
        label: this.common.translateService.instant('Blocked'),
        colSpan: 4,
        type: 'boolean',
        nullable: true
    };

    public moveDischargeFilterProperty: FormProperty = {
        name: 'moveDischarge',
        label: this.common.translateService.instant('Move discharge'),
        colSpan: 4,
        type: 'boolean',
        nullable: true
    };

    public moveLoadFilterProperty: FormProperty = {
        name: 'moveLoad',
        label: this.common.translateService.instant('Move Load'),
        colSpan: 4,
        type: 'boolean',
        nullable: true
    };

    public vinFilterProperty: FormProperty = {
        name: 'vin',
        label: this.common.translateService.instant('Vin'),
        colSpan: 4,
        type: 'string',
        multi: true
    };
    public serviceRequestFilterProperty: FormProperty = {
        name: 'serviceRequest',
        label: this.common.translateService.instant('Service Request'),
        colSpan: 4,
        type: 'string',
        multi: true
    };

    public announcedMotFilterProperty: FormProperty = {
        name: 'announcedMot',
        label: this.common.translateService.instant('Announced Mot no.'),
        colSpan: 4,
        type: 'string'
    };
    //#endregion
}
