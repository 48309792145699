import { Component, inject } from '@angular/core';
import { IconKey } from '@common/classes/icons';
import { CommonService } from '@common/services/common.service';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { BookingsBase } from 'app/booking/bookings.interface';
import { BookingViewUtilsService } from '../booking-view-utils.service';

export interface DialogMergeOptions {
    title?: string;
    titleIcons?: IconKey[];
    message?: string;
    cancelText?: string;
    cancelIcon?: IconKey;
    cancelAction?: Function;
    confirmText?: string;
    confirmIcon?: IconKey;
    confirmMessage?: string;
    model?: BookingsBase;
}

@Component({
    selector: 'app-dialog-merge',
    templateUrl: './dialog-merge.component.html'
})
export class DialogMergeComponent extends DialogContentBase {
    override dialog = inject<DialogRef>(DialogRef);
    common = inject(CommonService);
    util = inject(BookingViewUtilsService);

    model: BookingsBase;
    lookUpNumber: any = null;
    options: DialogMergeOptions = {
        title: this.common.translateService.instant('Merge'),
        message: ''
    };

    public initialize(options: DialogMergeOptions) {
        for (const [key, value] of Object.entries(options)) {
            if (![undefined, null].includes(value)) this.options[key] = value;
        }
        this.model = this.options.model;
    }

    async confirm(): Promise<void> {
        const today = new Date();
        const start = this.util.startOfDay(today);
        const end = this.util.endOfDay(today);

        this.common.queryService
            .getQuery(
                'MergeBookings',
                { pin: this.lookUpNumber, timeFrom: start, timeTo: end },
                { injector: this.common.injector }
            )
            .result$.subscribe((result: any) => {
                if (result.data) {
                    this.done(result.data?.results);
                }
            });
    }

    done(ids: BookingsBase[]) {
        if (ids.length == 0) {
            this.common.toastrNotificationService.show({
                type: 'error',
                title: this.common.translateService.instant('Error'),
                message: this.common.translateService.instant('No booking found')
            });
        } else {
            this.dialog.close(ids[0]); //Will taking first be alright, as in original VBS
        }
    }

    close(result: boolean | undefined = undefined) {
        this.dialog.close(result);
    }
}
