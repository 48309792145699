import { Routes } from '@angular/router';
import { LoginComponent } from '@common/components/login/login.component';
import { ZktUserLoginComponent } from '@common/components/login/zkt-user-login.component';
import { UnauthorizedComponent } from '@common/components/unauthorized.component';
import { zktOnlyLoginGuard } from '@common/guards/zkt-only-login.guard';
import { zktRedirectLoginGuard } from '@common/guards/zkt-redirect-login.guard';
import { BookingModule } from './booking/booking.module';
import { LogoutComponent } from './common/components/logout.component';
import { AuthGuard } from './common/guards/auth.guard';
import { ContainerModule } from './container/container.module';
import { HomeComponent } from './home/home.component';
import { InfoModule } from './info/info.module';
import { UserAccountModule } from './user-account/user-account.module';
import { UserSettingsModule } from './user-settings/user-settings.module';

export const ROUTES: Routes = [
    { path: 'login', component: LoginComponent, canActivate: [zktRedirectLoginGuard] },
    { path: 'login/zkt', component: ZktUserLoginComponent, canActivate: [zktOnlyLoginGuard] },
    { path: '401', component: UnauthorizedComponent },
    { path: 'logout', component: LogoutComponent },
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            { path: '', redirectTo: '/home', pathMatch: 'full' },
            {
                path: 'administration',
                loadChildren: () =>
                    import('./administration/administration.module').then((m) => m.AdministrationModule),
                data: { title: 'Administration' }
            },
            {
                path: 'info',
                loadChildren: () => InfoModule,
                data: { title: 'Information' }
            },
            {
                path: 'containers',
                loadChildren: () => ContainerModule,
                data: { title: 'Containers' }
            },
            {
                path: 'booking',
                loadChildren: () => import('./booking/booking.module').then((m) => m.BookingModule),
                data: { title: 'Bookings' }
            },
            {
                path: 'booking',
                loadChildren: () => BookingModule,
                data: { title: 'Bookings' }
            },
            { path: 'home', component: HomeComponent },
            {
                path: 'user-account',
                loadChildren: () => UserAccountModule,
                data: { title: 'Account' }
            },
            {
                path: 'user-settings',
                loadChildren: () => UserSettingsModule,
                data: { title: 'User Settings' }
            },
            {
                path: 'faq',
                loadChildren: () => import('./faq/faq.module').then((m) => m.FaqModule),
                data: { title: 'FAQ' }
            },
            {
                path: 'jobs',
                loadComponent: () => import('./jobs/jobs-list/jobs-list.component').then((c) => c.JobsListComponent),
                data: { title: 'Jobs' }
            },
            {
                path: 'slots',
                loadComponent: () =>
                    import('./slots/slots-overview/slots-overview.component').then((m) => m.SlotsOverviewComponent),
                data: { title: 'Slots' }
            },
            {
                path: 'events',
                loadChildren: () => import('./events/events.module').then((m) => m.EventsModule),
                data: { title: 'Events' }
            },
            {
                path: 'truck-visit',
                loadChildren: () => import('./truck-visit/truck-visit.module').then((m) => m.TruckVisitModule),
                data: { title: 'Truck Visits' }
            },
            {
                path: 'spica-badge',
                loadChildren: () => import('./spica-badge/spica-badge.module').then((m) => m.SpicaBadgeModule),
                data: { title: 'Spica Badge' }
            },
            { path: '**', redirectTo: '/home' }
        ]
    }
];
