import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
    selector: 'app-loader',
    template: `
        @if (inline) {
            @if (isBusy) {
                <div class="flex flex-row gap-2">
                    <div class="h-4 w-4 animate-bounce rounded-full bg-primary"></div>
                    <div class="h-4 w-4 animate-bounce rounded-full bg-primary [animation-delay:-.3s]"></div>
                    <div class="h-4 w-4 animate-bounce rounded-full bg-primary [animation-delay:-.5s]"></div>
                </div>
            }
        } @else {
            @if (isBusy) {
                <div class="loader"></div>
            } @else {
                <ng-container *ngTemplateOutlet="detailRef"></ng-container>
            }
        }
    `,
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
    @ContentChild(TemplateRef, { static: true }) detailRef;
    @Input() isBusy = true;
    @Input() inline = false;
}
