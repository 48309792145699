import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { CommonModule } from '@common/common.module';
import dayjs from 'dayjs';

type TimeSlot = {
    label: string;
    timeFrom: Date;
    timeTo: Date;
    value: string;
};

@Component({
    selector: 'app-dropdown-slot-selector',
    standalone: true,
    imports: [CommonModule],
    template: `
        <app-select-input
            label="Slot"
            placeholder="Select a time slot"
            id="slot-select"
            [options]="slots()"
            [isEditMode]="true"
            [(value)]="selected"
            (valueChange)="handleValueChange($event)" />
    `,
    styleUrl: './dropdown-slot-selector.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownSlotSelectorComponent {
    selected = null;
    date = input<Date>(new Date());
    valueChange = output<{ timeFrom: Date; timeTo: Date }>();
    slots = computed(() => {
        return this.generateSlots(this.date());
    });

    generateSlots = (date: Date): TimeSlot[] => {
        this.selected = null;
        const slots = [];

        if (!date) {
            return slots;
        }

        const start = dayjs(date).isSame(dayjs(), 'day') ? dayjs().startOf('hour') : dayjs(date).startOf('day');
        const end = dayjs(date).startOf('day').add(23, 'hours');
        let current = start;
        while (current.isBefore(end)) {
            slots.push({
                value: current.format('HH'),
                label: `${current.format('HH:mm')} - ${current.add(1, 'hour').format('HH:mm')}`,
                timeFrom: current.toDate(),
                timeTo: current.add(1, 'hour').toDate()
            });
            current = current.add(1, 'hour');
        }
        return slots;
    };

    handleValueChange = (value: string) => {
        const slot = this.slots().find((x) => x.value === value);
        this.valueChange.emit({ timeFrom: slot.timeFrom, timeTo: slot.timeTo });
    };
}
