import { Component, Input } from '@angular/core';
import { CommonModule } from '@common/common.module';
import { GridCellContext, GridTemplateComponent } from '@common/interfaces/column-settings.interface';
import { CargoTypeCodes } from '@common/known-types/cargo-type.codes';

export interface IdCellInputs {
    cargoTypeId: string;
    id: number;
}

@Component({
    standalone: true,
    imports: [CommonModule],
    template: `
        <app-link [route]="getRouteForCargoType(cargoTypeId, id)">{{ id }}</app-link>
    `
})
export class IdCellComponent implements GridTemplateComponent, IdCellInputs {
    @Input() context!: GridCellContext;
    @Input() cargoTypeId!: string;
    @Input() id!: number;

    getRouteForCargoType(type: string, id: number): string {
        switch (type) {
            case CargoTypeCodes.CAR:
                return this.getBookingRoute('car', id);
            case CargoTypeCodes.CONTAINER:
                return this.getBookingRoute('container', id);
            case CargoTypeCodes.GENERAL_CARGO:
                return this.getBookingRoute('cargo', id);
            case CargoTypeCodes.PRIVATE:
                return this.getBookingRoute('evn', id);
            default:
                return '';
        }
    }

    getBookingRoute(type: string, id: number): string {
        return `/booking/${type}/view/${id}`;
    }
}
