export enum BookingStatusCodes {
    New = 'N',
    Booked = 'B',
    Canceled = 'C',
    Expired = 'E',
    InPort = 'P',
    Finished = 'F',
    InPortDelayed = 'D',
    FinishedDelayed = 'S',
    Reserved = 'R',
    OutOfPort = 'O',
    Merged = 'M',
    FinishedUnregistered = 'U',
    FinishedDelayedUnregistered = 'V',
    FinishedRejected = 'H',
    InVerification = 'I',
    OnTerminalFromInPort = 'T',
    OnTerminalFromInPortDelayed = 'A',
    FinishedRejectedDelayed = 'G',
    Rejected = 'Z'
}

export const bookingStatusColors = (status: BookingStatusCodes): string => {
    switch (status) {
        case BookingStatusCodes.New:
            return '#E2D540';
        case BookingStatusCodes.Booked:
            return '#81E052';
        case BookingStatusCodes.Expired:
            return 'grey';
        case BookingStatusCodes.InPort:
            return '#0EA40E';
        case BookingStatusCodes.Finished:
            return '#3899FA';
        case BookingStatusCodes.InPortDelayed:
            return '#E87D30';
        case BookingStatusCodes.FinishedDelayed:
            return '#2E2EB8';
        case BookingStatusCodes.Reserved:
            return '#9947EB';
        case BookingStatusCodes.Merged:
            return '#F075F0';
        case BookingStatusCodes.FinishedUnregistered:
        case BookingStatusCodes.FinishedDelayedUnregistered:
            return '#A11212';
        case BookingStatusCodes.InVerification:
            return '#CF5417';
        case BookingStatusCodes.OutOfPort:
        case BookingStatusCodes.OnTerminalFromInPort:
        case BookingStatusCodes.OnTerminalFromInPortDelayed:
        case BookingStatusCodes.FinishedRejectedDelayed:
            return '#EAA53E';
        case BookingStatusCodes.FinishedRejected:
        case BookingStatusCodes.Rejected:
        case BookingStatusCodes.Canceled:
            return '#DD3C3C';
        default:
            return 'light';
    }
};
