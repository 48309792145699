import { Component, Input } from '@angular/core';
import { CommonModule } from '@common/common.module';
import { GridCellContext, GridTemplateComponent } from '@common/interfaces/column-settings.interface';

export interface WrapCellInput {
    content: string;
}

@Component({
    standalone: true,
    imports: [CommonModule],
    template: `
        <div class="whitespace-normal text-wrap break-all">{{ content }}</div>
    `
})
export class WrapCellComponent implements GridTemplateComponent, WrapCellInput {
    @Input() context!: GridCellContext;
    @Input() content!: string;
}
