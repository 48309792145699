import { Component, OnInit } from '@angular/core';
import { BaseViewComponent } from '@common/classes/base-view';
import { IconKey } from '@common/classes/icons';
import { BookingPermissions } from '@common/classes/permissions';
import { ColumnSettings } from '@common/interfaces/column-settings.interface';
import { CommonService } from '@common/services/common.service';
import { BookingViewDefinitionsService } from '../booking-view-definitions.service';
import { BookingViewUtilsService, confirmDriverResult } from '../booking-view-utils.service';

@Component({
    selector: 'app-booking-general-cargo-view',
    templateUrl: './booking-general-cargo-view.component.html',
    providers: [CommonService]
})
export class BookingGeneralCargoViewComponent extends BaseViewComponent implements OnInit {
    override editPermission = BookingPermissions.Default.Edit;
    override createPermission = BookingPermissions.Default.Edit;
    entityName = 'GeneralCargoBooking';
    icon: IconKey = 'faSolidWarehouse';
    isDriver: boolean = false;

    override getTitle() {
        const entityTitle = this.translateService.instant(this.entityName.replace(/([A-Z])/g, ' $1').trim());
        return this.createMode
            ? `${this.translateService.instant('New')} ${entityTitle}`
            : `${this.translateService.instant('Booking')} ${this.getIdentifier()}`;
    }

    generalCargoColumns: ColumnSettings[] = this.def.generalCargoColumns;

    override canEdit(): boolean {
        return this.util.canEdit(this.model);
    }

    constructor(
        common: CommonService,
        private def: BookingViewDefinitionsService,
        private util: BookingViewUtilsService
    ) {
        super(common);
        this.actionBar.push(this.util.getActionBar(this));
        this.common.userService.getCurrentUser();
        if (this.user.fullName == 'DriverLogin' /* || this.user.isDriver*/) {
            this.actionBar = [];
            this.isDriver = true;
        }
    }

    confirm() {
        this.util.confirm(this.model).then((res: confirmDriverResult) => {
            switch (res) {
                case confirmDriverResult.nothing:
                    return;
                case confirmDriverResult.saveAndClose: {
                    this.saveChanges();
                    this.close();
                    break;
                }
                case confirmDriverResult.saveGoToPort: {
                    this.saveChanges();
                    this.util.AcceptGoToPort(this.model);
                    break;
                }
            }
        });
    }

    changeTime() {
        this.util.changeTime(this.model);
    }

    changePlates() {
        this.util.changePlates(this.model);
    }

    addSecondDriver() {
        this.util.addSecondDriver(this.model);
    }

    mergeBooking() {
        this.util.mergeBooking(this.model);
    }

    unmergeBooking() {
        this.util.unmergeBooking(this.model.id);
    }

    cancel() {
        this.util.cancel();
    }

    close() {
        this.util.close();
    }
}
