<kendo-dialog-titlebar class="bg-background pb-5 pl-5 pr-3 pt-6 font-bold text-primary" (close)="close()">
    <div class="flex flex-row items-center gap-2">
        @for (icon of options?.titleIcons; track $index) {
            <ng-icon [name]="icon" />
        }
        {{ options?.title | translate }}
    </div>
</kendo-dialog-titlebar>
<div class="flex flex-col gap-4 overflow-hidden p-4">
    @if (plateInputType()) {
        <div>
            <app-text-input label="Register truck" id="truck" isEditMode="false" [(value)]="model.truck" />
            <app-text-input label="Register trailer" id="trailer" isEditMode="false" [(value)]="model.trailer" />
        </div>
    } @else {
        <div>
            <app-codelist-input
                label="Truck"
                #truckPlate
                id="truck"
                codelist="Fleet"
                isEditMode="false"
                [isRequired]="true"
                [customCondition]="organizationTruckCondition()"
                [(value)]="model.truckId" />
            <app-codelist-input
                label="Trailer"
                #trailerPlate
                id="trailer"
                isEditMode="false"
                codelist="Fleet"
                [isRequired]="true"
                [customCondition]="organizationTrailerCondition()"
                [(value)]="model.trailerId" />
            <div class="col-span-1">
                <app-boolean-input label="No Trailer" isEditMode="false" id="noTrailer" [(value)]="model.noTrailer" />
            </div>
        </div>
    }
    <kendo-dialog-actions class="px-4" layout="end">
        <div class="flex justify-end space-x-4 sm:space-x-2">
            <app-button variant="secondary" [icon]="options?.cancelIcon" (click)="close()">
                {{ options?.cancelText | translate }}
            </app-button>
            <app-button variant="primary" [icon]="options?.confirmIcon" (click)="confirm()">
                {{ options?.confirmText | translate }}
            </app-button>
        </div>
    </kendo-dialog-actions>
</div>
