import { Component, Input } from '@angular/core';
import { ColumnSettings } from '@common/interfaces/column-settings.interface';

@Component({
    selector: 'app-panel-booking-data',
    templateUrl: './panel-booking-data.component.html'
})
export class PanelBookingDataComponent {
    @Input() bookingDataTitle: string = '';
    @Input() title: string = 'Booking data';
    @Input() model: any[];
    @Input() dataColumns: ColumnSettings[];
    constructor() {}
}
