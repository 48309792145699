import { CustomCondition } from '@administration/codelists/codelists.interface';
import { Component, effect, Input, OnChanges, OnDestroy, OnInit, signal, SimpleChanges } from '@angular/core';
import { CodeList } from '@common/classes/codelist';
import { CodelistPipe } from '@common/pipes/codelist.pipe';
import { CommonService } from '@common/services/common.service';
import { QueryService } from '@common/services/query.service';
import { environment } from '@environments/environment';
import { tapSuccessResult } from '@ngneat/query';
import { debounce } from 'lodash';
import { firstValueFrom, lastValueFrom, Subject, takeUntil } from 'rxjs';
import { BaseInputComponent } from '../base-input/base-input.component';
import { CodelistInputConfig } from '../input.type';

export type CodelistLabelFormat = 'LongId' | 'LongCode' | 'OnlyId' | 'OnlyName' | 'OnlyCode';

/**
 * Codelist input component.
 * @param codelist - The codelist name.
 * @param isMultiple - Whether the input is multiple.
 * @param textField - The text field of the codelist.
 * @param valueField - The value field of the codelist.
 * @param popupSettings - The popup settings of the dropdown.
 * @param labelFormat - Predfined format or custom format using function.
 * @param customFilter - The custom filter function.
 */
@Component({
    selector: 'app-codelist-input',
    templateUrl: './codelist-input.component.html',
    providers: [{ provide: BaseInputComponent, useExisting: CodelistInputComponent }]
})
export class CodelistInputComponent
    extends BaseInputComponent<string | string[]>
    implements OnInit, OnDestroy, OnChanges
{
    @Input({ required: true }) codelist: CodelistInputConfig['codelist'];
    @Input() isMultiple: CodelistInputConfig['isMultiple'];
    @Input() textField: CodelistInputConfig['textField'] = 'label';
    @Input() valueField: CodelistInputConfig['valueField'] = 'value';
    @Input() popupSettings: CodelistInputConfig['popupSettings'] =
        environment.settings.appControl.dropdown.popupSettings;
    @Input() labelFormat: CodelistInputConfig['labelFormat'] = (item: CodeList) => `${item.customText || item.name}`;
    @Input() customFilter: CodelistInputConfig['customFilter'];
    @Input() customCondition: CodelistInputConfig['customCondition'];

    query$;
    options = signal<{ value: string; label: string; [key: string]: any }[]>([]);
    filteredOptions = signal<{ value: string; label: string; [key: string]: any }[]>([]);
    readonly codelistTake = environment.settings.appControl.codelist.take;
    formatedSelectedOptions = '';
    private destroy$ = new Subject<boolean>();

    constructor(
        private queryService: QueryService,
        private codelistPipe: CodelistPipe,
        private commonService: CommonService
    ) {
        super();
        effect(async () => {
            this.formatedSelectedOptions = await this.formatSelectedOptions(this.value());
        });
    }

    ngOnInit() {
        this.queryCodelist('');
    }

    ngOnDestroy() {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    // TODO: Consider replacing customConditions with a signal input
    ngOnChanges(changes: SimpleChanges) {
        if (changes['customCondition']) {
            if (
                !this.compareCustomConditions(
                    changes['customCondition'].currentValue,
                    changes['customCondition'].previousValue
                )
            ) {
                this.queryCodelist('');
            }
        }
    }

    compareCustomConditions(a: CustomCondition[], b: CustomCondition[]) {
        if (a == undefined || b == undefined) return true;
        if (JSON.stringify(a) !== JSON.stringify(b)) return false;
        return true;
    }

    queryCodelist(filter, customCondition: CustomCondition[] = this.customCondition) {
        this.query$ = this.queryService.getCodelistQuery({
            name: this.codelist,
            filter,
            customCondition: customCondition,
            selectedIds: this.value ? [this.value()].flat() : null,
            injector: this.commonService.injector,
            take: this.isEditMode ? this.codelistTake : 1
        }).result$;
        this.query$
            .pipe(
                tapSuccessResult((data: []) => {
                    this.options.set(
                        data.map((x: any) => ({
                            label: this.getCodelistLabelFormat(this.labelFormat, x),
                            value: x.id,
                            name: x.name
                        }))
                    );

                    const currentValue = this.isMultiple ? this.value()?.[0] : this.value();
                    if (this.customFilter) {
                        this.filteredOptions.set(this.options().filter((x: any) => this.customFilter(x, filter)));
                        return;
                    }
                    this.filteredOptions.set(
                        this.options().filter((x, i) => x.value === currentValue || i < this.codelistTake + 1)
                    );
                }),
                takeUntil(this.destroy$)
            )
            .subscribe();
    }

    queryCodelistDebounced = debounce((filter) => this.queryCodelist(filter), 500);

    async formatSelectedOptions(selected: string | string[]): Promise<string> {
        if (!this.options() || !selected) return null;

        if (this.isMultiple) {
            if (!Array.isArray(selected) || selected.length === 0) {
                return null;
            }
            return await Promise.all(
                selected.map(
                    async (item) => await firstValueFrom(this.codelistPipe.transform(item, this.codelist, false))
                )
            ).then((res) => res.join(', '));
        } else {
            if (Array.isArray(selected)) return null;
            return await lastValueFrom(this.codelistPipe.transform(selected, this.codelist, false));
        }
    }

    private getCodelistLabelFormat(labelFormat: CodelistInputConfig['labelFormat'], item?: CodeList) {
        if (typeof labelFormat === 'function') {
            return labelFormat(item);
        }
        switch (labelFormat) {
            case 'LongCode':
                return `${item.code} - ${item.customText || item.name}`;
            case 'LongId':
                return `${item.id} - ${item.customText || item.name}`;
            case 'OnlyId':
                return `${item.id}`;
            case 'OnlyCode':
                return `${item.code}`;
            case 'OnlyName':
                return `${item.name || item.customText}`;
            default:
                return `${item.customText || item.name}`;
        }
    }
}
