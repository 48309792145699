import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { IconKey } from '@common/classes/icons';
import {
    AdministrationPermissions,
    BookingPermissions,
    CodeListPermissions,
    ContainerPermissions,
    EventsPermissions,
    FaqPermissions,
    NoticeBoardPermissions,
    SpicabadgesPermissions,
    TruckVisitPermissions
} from '@common/classes/permissions';
import { PermissionType } from '@common/models/user';
import { PropertyFunction } from '@common/models/util.interface';
import { SVGIcon } from '@progress/kendo-svg-icons';

export interface NavItem extends PanelBarItemModel {
    readonly permissions?: PermissionType | PermissionType[];
}

export const NAVIGATION: NavItem[] = [
    {
        title: _('Dashboard'),
        icon: 'faSolidInbox',
        path: '/home',
        id: 'dashboard',
        selected: true,
        content: 'Tets'
    },
    {
        title: _('Information'),
        icon: 'faSolidCircleInfo',
        id: 'Information',
        children: [
            {
                title: _('Notifications'),
                icon: 'faSolidBell',
                path: '/info/notification',
                id: 'notifications',
                permissions: [NoticeBoardPermissions.View]
            },
            {
                title: _('Manuals'),
                icon: 'faSolidList',
                path: '/info/manuals',
                id: 'manuals'
                //permissions: [AdministrationPermissions.User]
            },
            {
                title: _('Faq'),
                icon: 'faSolidCircleQuestion',
                path: '/info/faq',
                id: 'faq',
                permissions: [FaqPermissions.View]
            }
        ]
    },
    {
        title: _('Bookings'),
        icon: 'faSolidFolder',
        expanded: true,
        id: 'Bookings',
        permissions: [
            BookingPermissions.Default.List ||
                BookingPermissions.Car.List ||
                BookingPermissions.Evn.List ||
                BookingPermissions.GeneralCargo.List ||
                BookingPermissions.Container.List
        ],
        children: [
            {
                title: _('All'),
                icon: 'faSolidList',
                path: '/booking/list',
                id: 'bookingsAll',
                permissions: [BookingPermissions.Default.List]
            },
            {
                title: _('Cars'),
                icon: 'faSolidCarSide',
                path: '/booking/car/list',
                id: 'bookingsCar',
                permissions: [BookingPermissions.Car.List]
            },
            {
                title: _('Containers'),
                icon: 'faSolidCube',
                path: '/booking/container/list',
                id: 'bookingsContainer',
                permissions: [BookingPermissions.Container.List]
            },
            {
                title: _('General Cargo'),
                icon: 'faSolidWarehouse',
                path: '/booking/cargo/list',
                id: 'bookingsGCargo',
                permissions: [BookingPermissions.GeneralCargo.List]
            },
            {
                title: _('Private'),
                icon: 'faSolidHouseUser',
                path: '/booking/evn/list',
                id: 'bookingsPrivate',
                permissions: [BookingPermissions.Evn.List]
            }
        ]
    },
    {
        title: _('Truck Visit'),
        icon: 'faSolidTruck',
        id: 'truck-visit',
        path: '/truck-visit',
        permissions: [TruckVisitPermissions.Default.List]
    },
    {
        title: _('Containers'),
        icon: 'faSolidCubes',
        path: '/containers/list',
        id: 'containers',
        permissions: [ContainerPermissions.Default.List]
    },
    {
        title: _('Jobs'),
        icon: 'faSolidListCheck',
        path: 'jobs',
        id: 'jobs',
        permissions: [] //TODO
    },
    {
        title: _('Slots'),
        icon: 'faSolidCalendarDays',
        path: 'slots',
        id: 'slots',
        permissions: []
    },
    {
        title: _('Spica Badge'),
        icon: 'faSolidAddressCard',
        id: 'spicaBadge',
        path: '/spica-badge',
        permissions: [SpicabadgesPermissions]
    },
    {
        title: _('Events'),
        icon: 'faSolidCalendarDays',
        id: 'events',
        path: '/events',
        permissions: [EventsPermissions.Default.List]
    },
    {
        title: _('Administration'),
        icon: 'faSolidGear',
        id: 'administration',
        permissions: [AdministrationPermissions, CodeListPermissions, FaqPermissions],
        children: [
            {
                title: _('My Organization'),
                icon: 'faSolidUsers',
                path: '/administration/myorganization',
                id: 'adminMyOrganization',
                permissions: [AdministrationPermissions.User]
            },
            {
                title: _('Users'),
                icon: 'faSolidUsers',
                path: '/administration/user',
                id: 'adminUsers',
                permissions: [AdministrationPermissions.User]
            },
            {
                title: _('Drivers'),
                icon: 'faSolidUsers',
                path: '/administration/driver',
                id: 'adminDrivers',
                permissions: [AdministrationPermissions.User]
            },
            {
                title: _('Organizations'),
                icon: 'faSolidBuilding',
                path: '/administration/organization',
                id: 'adminOrganizations',
                permissions: [AdministrationPermissions.Organization]
            },
            {
                title: _('Roles'),
                icon: 'faSolidKey',
                path: '/administration/role',
                id: 'adminRoles',
                permissions: [AdministrationPermissions.Role]
            },
            {
                title: _('Codelists'),
                icon: 'faSolidList',
                path: '/administration/codelists',
                id: 'codelists',
                permissions: [CodeListPermissions.View]
            },
            {
                title: _('Application Settings'),
                icon: 'faSolidGear',
                path: '/administration/application-setting',
                id: 'application-settings',
                permissions: [AdministrationPermissions.Settings.View]
            },
            {
                title: _('Faq Categories'),
                icon: 'faSolidTags',
                path: '/administration/faq/categories',
                id: 'faq-categories',
                permissions: [FaqPermissions.Action.Save]
            }
        ]
    }
];

const flatten = (members: NavItem[]): NavItem[] => {
    let children = [];

    return members
        .map((m) => {
            if (m.children && m.children.length) {
                children = [...children, ...m.children];
            }
            return m;
        })
        .concat(children.length ? flatten(children) : children);
};

export const NAVIGATION_FLAT = flatten(NAVIGATION);

export interface PanelBarItemModel {
    // Sets the title of the PanelBar item.
    title: string;

    // Allows the component to set the `"id"` property of each item.
    id: string;

    //Defines the icon that will be rendered next to the title.
    icon: IconKey;

    // Defines the icon that will be rendered next to the title by using a custom CSS class.
    iconClass?: string;

    // Defines an SVGIcon to be rendered.
    // The input can take either an [existing Kendo SVG icon](slug:svgicon_list) or a custom one.
    svgIcon?: SVGIcon;

    // Defines the location of the image that will be displayed next to the title.
    imageUrl?: string;

    // When set to `true`, disables a PanelBar item.
    disabled?: boolean;

    // When set to `true`, expands the PanelBar item.
    expanded?: boolean;

    // Sets the focused state of a PanelBar item.
    focused?: boolean;

    // Sets the selected state of a PanelBar item.
    selected?: boolean;

    // Specifies if the item will be hidden.
    hidden?: boolean | PropertyFunction<boolean>;

    // Defines the child items as an array.
    children?: PanelBarItemModel[];

    // Sets the content of the PanelBar item.
    content?: any;

    // Sets permissions for the PanelBar item.
    readonly permissions?: PermissionType | PermissionType[];

    // Sets the path of the PanelBar item.
    path?: string;
}
