<app-card>
    <div class="mb-2 flex items-center gap-2">
        <ng-icon class="text-primary" name="faSolidList" />
        <span class="text-lg">{{ bookingDataTitle | translate }}</span>
    </div>
    <div class="w-full">
        <app-grid
            gridSize="small"
            [data]="model"
            [cols]="dataColumns"
            [filterable]="false"
            [autoFitColumns]="false"
            [applyLocalPagination]="true"
            [allowGridSave]="false"
            [showToolbar]="false"
            [pageable]="false"
            [autoFitColumns]="true" />
    </div>
</app-card>
