import { customCondition } from '@administration/codelists/codelists.interface';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { BaseListComponent } from '@common/classes/base-list';
import { BookingPermissions } from '@common/classes/permissions';
import { ActionBarGroup } from '@common/components/action-bar/action-bar.interface';
import { FormProperty } from '@common/components/app-control/app-control.component';
import { ColumnSettings } from '@common/interfaces/column-settings.interface';
import { FleetTypeCodes } from '@common/known-types/fleet-type.codes';
import { CommonService } from '@common/services/common.service';
import { BookingService } from '../../booking.service';
import { BookingListDefinitionsService } from '../booking-list-definitions.service';
import { BookingListUtilsService } from '../booking-list-utils.service';

@Component({
    selector: 'app-booking-car-list',
    templateUrl: './booking-car-list.component.html',
    providers: [CommonService]
})
export class BookingCarListComponent extends BaseListComponent implements OnInit, OnDestroy {
    queryName = 'CarBookings';

    @ViewChild('openFileDialog') openFileDialog: ElementRef<HTMLInputElement>;

    columns: ColumnSettings[] = [
        this.def.bookingColumn,
        this.def.cargoTypeColumn,
        this.def.bookingStatusColumn,
        this.def.pinColumn,
        this.def.blockedColumn,
        {
            field: 'sdDischarge',
            title: _('SD'),
            width: 60,
            type: 'boolean'
        },
        { field: 'damaged', title: _('DM'), width: 60, type: 'boolean' },
        {
            field: 'vinIn',
            title: _('Vin IN'),
            class: 'max-w-40',
            fieldTooltip: 'vinIn'
        },
        {
            field: 'vinOut',
            title: _('Vin OUT'),
            class: 'max-w-40',
            fieldTooltip: 'vinOut'
        },
        {
            field: 'serviceRequestIn',
            title: _('Service Request IN'),
            class: 'max-w-40',
            fieldTooltip: 'serviceRequestIn'
        },
        {
            field: 'serviceRequestOut',
            title: _('Service Request OUT'),
            class: 'max-w-40',
            fieldTooltip: 'serviceRequestOut'
        },
        this.def.organizationColumn,
        this.def.timeFromColumn,
        this.def.gateColumn,
        this.def.haulierColumn,
        this.def.driverColumn,
        this.def.truckColumn,
        this.def.trailerColumn,
        {
            field: 'announcedMotNo',
            title: _('Annoucened Mot. No'),
            width: 100
        }
    ];

    get filterProperties(): FormProperty[] {
        return [
            { initialValue: this.filter?.bookingId, ...this.def.bookingFilterProperty },
            { initialValue: this.filter?.status, ...this.def.statusFilterProperty },
            { initialValue: this.filter?.pin, ...this.def.pinFilterProperty },
            { initialValue: this.filter?.organizationId, ...this.def.organizationFilterProperty },
            { initialValue: this.filter?.plate, ...this.def.plateFilterProperty },
            { initialValue: this.filter?.timeFrom, ...this.def.dateFromFilterProperty },
            { initialValue: this.filter?.timeTo, ...this.def.dateToFilterProperty },
            { initialValue: this.filter?.vin, ...this.def.vinFilterProperty },
            { initialValue: this.filter?.serviceRequest, ...this.def.serviceRequestFilterProperty },
            { initialValue: this.filter?.announcedMot, ...this.def.announcedMotFilterProperty },
            { name: '', type: 'empty', colSpan: 8 },
            { initialValue: this.filter?.haulier, ...this.def.haulierFilterProperty },
            { initialValue: this.filter?.driver, ...this.def.driverFilterProperty },
            {
                initialValue: this.filter?.truck,
                isDisabled: (model) => this.isPlateDisabled(model),
                codelistCustomCondition: [
                    { name: 'FleetType', condition: customCondition.Equal, value: FleetTypeCodes.Truck }
                ],
                ...this.def.truckFilterProperty
            },
            {
                initialValue: this.filter?.trailer,
                isDisabled: (model) => this.isPlateDisabled(model),
                codelistCustomCondition: [
                    { name: 'FleetType', condition: customCondition.Equal, value: FleetTypeCodes.Trailer }
                ],
                ...this.def.trailerFilterProperty
            },
            { initialValue: this.filter?.common, ...this.def.commonFilterProperty },
            { initialValue: this.filter?.blocked, ...this.def.blockedFilterProperty },
            { name: '', type: 'empty', colSpan: 4 },
            {
                name: 'myCargo',
                label: _('My cargo'),
                colSpan: 4,
                type: 'boolean',
                nullable: true,
                initialValue: this.filter?.myCargo
            },
            { initialValue: this.filter?.moveDischarge, ...this.def.moveDischargeFilterProperty },
            { initialValue: this.filter?.moveLoad, ...this.def.moveLoadFilterProperty },
            {
                name: 'damageDischarge',
                label: _('Damage Discharge'),
                colSpan: 4,
                type: 'boolean',
                nullable: true,
                initialValue: this.filter?.damageDischarge
            },
            {
                name: 'damageLoad',
                label: _('Damage Load'),
                colSpan: 4,
                type: 'boolean',
                nullable: true,
                initialValue: this.filter?.damageLoad
            }
        ];
    }

    public override actionBar: ActionBarGroup[] = [
        {
            label: 'Tesla',
            items: [
                {
                    label: _('Tesla'),
                    icon: 'simpleTesla',
                    variant: 'primary',
                    isVisible: true,
                    onClick: () => this.router.navigate([this.parentRoute + '/tesla', 'list'])
                }
            ]
        },
        {
            label: 'Export',
            items: [
                {
                    label: 'Export',
                    icon: 'faSolidFileArrowDown',
                    onClick: () => this.booking.exportToExcel(this.appGrid)
                },
                {
                    label: 'Import & Book',
                    icon: 'faSolidFileArrowUp',
                    isVisible: () => this.actionHasPermission(BookingPermissions.Car.Edit), //TODO BookingPermission.importAndBookTa
                    onClick: () => this.openFileDialog.nativeElement.click()
                }
            ]
        },
        {
            label: 'Actions',
            items: [
                {
                    label: 'New',
                    icon: 'faSolidPlus',
                    iconOnly: true,
                    variant: 'primary',
                    isVisible: () => this.canCreateNew(),
                    onClick: () => this.createNew()
                }
            ]
        }
    ];

    public gridActions: ActionBarGroup[] = [
        {
            label: 'Actions',
            items: [
                {
                    label: _('Merge'),
                    icon: 'faSolidCompress',
                    iconOnly: true,
                    variant: 'primary',
                    isVisible: () => true,
                    isDisabled: () => this.selection.length <= 1,
                    onClick: () => this.booking.mergeBookings(this.selection as number[])
                },
                {
                    label: _('Delete'),
                    icon: 'faSolidTrash',
                    iconOnly: true,
                    variant: 'danger',
                    isDisabled: () => !this.booking.canDelete(this.selection),
                    isVisible: () => this.actionHasPermission(BookingPermissions.Default.Edit),
                    onClick: () => this.booking.cancelBookings(this.selection.map((item) => item.id))
                },
                {
                    label: _('Change'),
                    icon: 'faSolidPenToSquare',
                    iconOnly: true,
                    variant: 'primary',
                    isVisible: () => true,
                    isDisabled: () => this.selection.length !== 1,
                    onClick: () =>
                        this.util.changeBookingDialog(this.dialogService, {
                            title: this.translateService.instant('Change booking data'),
                            cancelAction: () => this.clearFilter(),
                            cancelText: 'Close',
                            cancelIcon: 'faSolidXmark',
                            confirmText: 'Save',
                            confirmIcon: 'faSolidFloppyDisk',
                            model: this.selection
                        })
                }
            ]
        }
    ];

    filterCommunication$;

    constructor(
        commonService: CommonService,
        protected def: BookingListDefinitionsService,
        protected booking: BookingService,
        private util: BookingListUtilsService
    ) {
        super(commonService);
    }

    override ngOnInit(): void {
        super.ngOnInit();
        this.actionBar = [...this.actionBar]; // this triggers change detection in actionbar so that buttons can update (isHidden, isVisible, etc.)
        this.filterCommunication$ = this.util.subscribeToFilterLogic();
    }

    isPlateDisabled(model): boolean {
        return model?.driverId == null || model?.haulier == null;
    }

    override getDefaultFilter() {
        return {
            common: null,
            blocked: null,
            myCargo: null,
            moveDischarge: null,
            moveLoad: null,
            damageDischarge: null,
            damageLoad: null
        };
    }

    override clearFilter(name?: string) {
        this.util.clearFilter();
        super.clearFilter(name);
    }

    ngOnDestroy(): void {
        if (this.filterCommunication$) {
            this.filterCommunication$.unsubscribe();
        }
    }

    /**
     * Open FileDialog where you can select CSV with car booking data
     */
    importAndBookCarsFromCSV($event) {
        const data = [];
        const file = $event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (evt: any) => {
                const csvContent = evt.target.result;
                const array = csvContent.split('\r\n');
                array.forEach((row) => {
                    const rowData = row.split(';');
                    if (rowData[0]) {
                        data.push(rowData[0]);
                    }
                });
                this.booking.importAndBookCars(data);
            };
            reader.onerror = (_evt) => {
                this.toastrNotificationService.show({
                    type: 'error',
                    title: this.common.translateService.instant('Error'),
                    message: this.common.translateService.instant('An error occurred while reading the file')
                });
            };
            reader.readAsText(file, 'UTF-8');
        } else {
            this.toastrNotificationService.show({
                type: 'error',
                title: this.common.translateService.instant('Error'),
                message: this.common.translateService.instant('No file selected or file is not valid')
            });
        }
    }
}
