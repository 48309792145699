import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BaseViewComponent } from '@common/classes/base-view';
import { IconKey } from '@common/classes/icons';
import { BookingPermissions } from '@common/classes/permissions';
import { CodelistInputComponent } from '@common/components/input/codelist-input/codelist-input.component';
import { ColumnSettings } from '@common/interfaces/column-settings.interface';
import { PaginatedQuery } from '@common/interfaces/query.interface';
import { BookingStatusCodes } from '@common/known-types/booking-status.codes';
import { CargoTypeCodes } from '@common/known-types/cargo-type.codes';
import { CommonService } from '@common/services/common.service';
import { takeUntilResultFinalize, tapSuccessResult } from '@ngneat/query';
import { BookingHaulier, BookingsPrivate } from 'app/booking/bookings.interface';
import { AddButtonCellComponent } from '../booking-car-view/add-button-cell.component';
import { BookingViewDefinitionsService, EditablePropertyCode } from '../booking-view-definitions.service';
import { BookingViewUtilsService, confirmDriverResult } from '../booking-view-utils.service';

@Component({
    selector: 'app-booking-private-view',
    templateUrl: './booking-private-view.component.html',
    providers: [CommonService],
    encapsulation: ViewEncapsulation.None
})
export class BookingPrivateViewComponent extends BaseViewComponent implements OnInit {
    override editPermission = BookingPermissions.Default.Edit;
    override createPermission = BookingPermissions.Default.Edit;
    entityName = 'PrivateBooking';
    icon: IconKey = 'faSolidHouseUser';
    filter: any = { vin: '', bookingId: '', pin: '', container: '' };
    data: BookingsPrivate[];
    isDriver: boolean = false;

    @ViewChild('haulier') haulier: CodelistInputComponent;
    @ViewChild('responsiblehaulier') responsiblehaulier: CodelistInputComponent;

    override initialModel: Partial<BookingsPrivate> = {
        bookingStatusId: BookingStatusCodes.New,
        cargoTypeId: CargoTypeCodes.PRIVATE
    };
    selectedHaulier: BookingHaulier = {
        //used to get organization type
        id: 0,
        types: [],
        isTemporary: false
    };

    override getTitle() {
        const entityTitle = this.translateService.instant(this.entityName.replace(/([A-Z])/g, ' $1').trim());
        return this.createMode
            ? `${this.translateService.instant('New')} ${entityTitle}`
            : `${this.translateService.instant('Private Booking')} ${this.getIdentifier()}`;
    }

    privateColumns: ColumnSettings[] = this.def.privateColumns;
    privateEditColumns: ColumnSettings[] = [
        ...this.def.privateEditColumns,
        {
            field: 'actions',
            title: '',
            templateComponent: AddButtonCellComponent,
            templateInputs: (row) => ({
                tooltip: 'Add Container',
                row,
                onClick: () => this.addBooking(row),
                isDisabled: !this.isPropertyEditable(EditablePropertyCode.cargo)
            })
        }
    ];

    override canEdit(): boolean {
        if (this.mode === 'create') {
            return true;
        }
        return this.util.canEdit(this.model);
    }

    constructor(
        common: CommonService,
        private def: BookingViewDefinitionsService,
        private util: BookingViewUtilsService
    ) {
        super(common);
        this.actionBar.push(this.util.getActionBar(this));
        this.common.userService.getCurrentUser();
        if (this.user.fullName == 'DriverLogin' /* || this.user.isDriver*/) {
            this.actionBar = [];
            this.isDriver = true;
        }
    }

    search() {
        this.common.queryService
            .getQuery<PaginatedQuery<BookingsPrivate>>('PrivateLinkBookings', this.filter, {
                injector: this.common.injector
            })
            .result$.pipe(
                tapSuccessResult((result: PaginatedQuery<BookingsPrivate>) => {
                    if (result) {
                        this.data = result.results;
                    }
                }),
                takeUntilResultFinalize()
            )
            .subscribe();
    }

    confirm() {
        this.util.confirm(this.model).then((res: confirmDriverResult) => {
            switch (res) {
                case confirmDriverResult.nothing:
                    return;
                case confirmDriverResult.saveAndClose: {
                    this.saveChanges();
                    this.close();
                    break;
                }
                case confirmDriverResult.saveGoToPort: {
                    this.saveChanges();
                    this.util.AcceptGoToPort(this.model);
                    break;
                }
            }
        });
    }

    changeTime() {
        this.util.changeTime(this.model);
    }

    changePlates() {
        this.util.changePlates(this.model);
    }

    addSecondDriver() {
        this.util.addSecondDriver(this.model);
    }

    mergeBooking() {
        this.util.mergeBooking(this.model);
    }

    unmergeBooking() {
        this.util.unmergeBooking(this.model.id);
    }

    cancel() {
        this.util.cancel();
    }

    close() {
        this.util.close();
    }

    onHaulierChange() {
        if (this.createMode) {
            this.model.responsibleHaulierId = this.model.actualHaulierId;
            this.responsiblehaulier.value.set(this.model.actualHaulierId);
            this.responsiblehaulier.queryCodelist('');
        }
        if (this.model.actualHaulierId)
            this.util.getHaulierOrganizationType(this.model.actualHaulierId, this.selectedHaulier); //get if temporary Haulier
        this.model.driverId = null;
        this.model.additionalDriverId = null;
        this.model.truckId = null;
        this.model.trailerId = null;
    }

    async onDriverChange() {
        this.util.onDriverChange(this.model, this.haulier, this.responsiblehaulier, this.createMode);
    }

    removeBooking(booking) {
        this.model.bookings = this.model.bookings.filter((x) => x.id != booking.id);
    }

    addBooking(booking) {
        if (!this.model.bookings) this.model.bookings = [];
        this.model.bookings.push(booking);
    }

    isPropertyEditable(property: EditablePropertyCode) {
        return this.util.isPropertyEditable(this.def.editablePropertyMap, this.model, property);
    }
}
