import { inject, Injectable } from '@angular/core';
import { AppGridComponent } from '@common/components/app-grid/app-grid.component';
import { BookingStatusCodes } from '@common/known-types/booking-status.codes';
import { DialogService } from '@common/services/dialog.service';
import { QueryService } from '@common/services/query.service';
import { ToastrNotificationService } from '@common/services/toastr-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { BookingsBase, GetCarsFromTinoParams, GetCarsFromTinoReturn } from '../bookings.interface';
@Injectable({
    providedIn: 'root'
})
export class BookingService {
    queryService = inject(QueryService);
    dialog = inject(DialogService);
    t = inject(TranslateService);
    toastrNotificationService = inject(ToastrNotificationService);

    /**
     * Validation if selected rows can be deleted
     * @param selection Currently selected rows from grid
     * @returns return status if those rows can be deleted
     */
    canDelete(selection: BookingsBase[]): boolean {
        const deleteStatus = [BookingStatusCodes.New, BookingStatusCodes.Reserved, BookingStatusCodes.Booked];
        return selection.length > 0 && selection.every((item) => deleteStatus.includes(item.bookingStatusId));
    }

    /**
     * Create Excel file from selectde rows in grid
     * @param appGrid reference on current grid
     * @param fileName Name of newly created file
     */
    public exportToExcel(appGrid: AppGridComponent, fileName: String = 'TableExport.xlsx'): void {
        appGrid.excelFileName = fileName;
        appGrid.grid.saveAsExcel();
    }

    /**
     * Merge two or more bookings into one
     * @param ids Selected bookings ids
     * @returns server response
     */
    async mergeBookings(ids: number[]): Promise<unknown> {
        return this.queryService
            .command('MergeBookings', {
                //TODO not implemented backend
                ids: ids
            })
            .catch((error: any) => {
                console.log(error);
                this.toastrNotificationService.show({
                    type: 'error',
                    title: this.t.instant('Error'),
                    message: this.t.instant('Merge Failed!')
                });
                return false;
            });
    }

    /**
     * Split merget booking on previous unmerged bookings
     * @param bookingId id of merged booking
     * @returns server response
     */
    async unmergeBooking(bookingId: number): Promise<unknown> {
        return this.queryService
            .command('UnmergeBooking', {
                //TODO not implemented backend
                id: bookingId
            })
            .catch((error: any) => {
                console.log(error);
                this.toastrNotificationService.show({
                    type: 'error',
                    title: this.t.instant('Error'),
                    message: this.t.instant('Unmerge Failed!')
                });
            });
    }

    /**
     * Try to remove, change status to canceled for selected bookings
     * @param ids Selected bookings ids
     */
    async cancelBookings(ids: number[]) {
        this.dialog
            .confirm({
                options: {
                    title: this.t.instant('Delete selected bookings!'),
                    message: this.t.instant(
                        'Are you sure you want to delete selected bookings? Action cannot be undone!'
                    )
                }
            })
            .then((result) => {
                if (result === true) {
                    this.queryService
                        .command('CancelBookings', {
                            //TODO not implemented backend
                            ids: ids
                        })
                        .catch((error: any) => {
                            console.log(error);
                            this.toastrNotificationService.show({
                                type: 'error',
                                title: this.t.instant('Error'),
                                message: this.t.instant('Cancel Booking Failed!')
                            });
                        });
                }
            });
    }

    /**
     * Try to create bookings with DispositionNumbers
     * @param data Parsed DispositionNumbers from csv
     * @returns response from server
     */
    async importAndBookCars(data: string[]): Promise<unknown> {
        return this.queryService
            .command('ImportAndBookTa', {
                //TODO not implemented backend
                data: data
            })
            .then((response: any) => {
                return response['data'];
            })
            .catch((error: any) => {
                console.log(error);
                this.toastrNotificationService.show({
                    type: 'error',
                    title: this.t.instant('Error'),
                    message: this.t.instant('Could not import booking data!')
                });
            });
    }

    async getCarsFromTino(params: GetCarsFromTinoParams): Promise<GetCarsFromTinoReturn[]> {
        return this.queryService
            .command<GetCarsFromTinoReturn[], GetCarsFromTinoParams>('GetCarsQuery', { ...params })
            .then((res) => {
                return res;
            });
    }
}

// sendTruckAnnoucement(bookingId: any) {
//     return this.$http
//         .post(this.serviceController + "SendTruckAnnoucement", {
//             id: bookingId
//         })
//         .catch((error: any) => {
//             console.log(error);
//         });
// }

// sendTruckAnnoucementForManifest(manifestId: any) {
//     return this.$http
//         .post(this.serviceController + "SendTruckAnnoucementForManifest", {
//             id: manifestId
//         })
//         .catch((error: any) => {
//             console.log(error);
//         });
// }

// getVbsAcarVins(serviceRequest: string, vin: string) {
//     return this.$http
//         .post(this.serviceController + "GetVbsAcarVins", {
//             serviceRequest: serviceRequest,
//             vin: vin ? vin : "",
//         })
//         .catch((error: any) => {
//             console.log(error);
//         });
// }

// updateBookingsData(ids, haulier, driver, truck, trailer) {
//     return this.$http
//         .post(this.serviceController + "UpdateBookingsData", {
//             ids: ids,
//             haulier: haulier,
//             driver: driver,
//             truck: truck,
//             trailer: trailer
//         })
//         .catch((error: any) => {
//             console.log(error);
//         });
// }

// enteredThePort(bookingId) {
//     return this.$http
//         .post(this.serviceController + "EnteredThePort", {
//             id: bookingId
//         })
//         .catch((error: any) => {
//             console.log(error);
//         });
// }

// leftThePort(bookingId) {
//     return this.$http
//         .post(this.serviceController + "LeftThePort", {
//             id: bookingId
//         })
//         .catch((error: any) => {
//             console.log(error);
//         });
// }
