import { Component, inject } from '@angular/core';
import { IconKey } from '@common/classes/icons';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

export interface DialogConfirmOptions {
    title: string;
    titleIcons?: IconKey[];
    message: string;
    hideCancel?: boolean;
    cancelText?: string;
    confirmText?: string;
}

@Component({
    template: `
        <kendo-dialog-titlebar class="bg-background pb-5 pl-5 pr-3 pt-6 font-bold text-primary">
            <div class="flex flex-row items-center gap-2">
                @for (icon of options?.titleIcons; track $index) {
                    <ng-icon [name]="icon" />
                }
                {{ options?.title | translate }}
            </div>
        </kendo-dialog-titlebar>
        <div class="flex h-full items-center justify-center p-4">
            {{ options?.message | translate }}
        </div>
        <kendo-dialog-actions class="px-4" layout="end">
            @if (!options.hideCancel) {
                <app-button class="w-20" variant="danger" (clicked)="dialog.close(false)">
                    {{ options?.cancelText | translate }}
                </app-button>
            }
            <app-button class="w-20" variant="primary" (clicked)="dialog.close(true)">
                {{ options?.confirmText | translate }}
            </app-button>
        </kendo-dialog-actions>
    `
})
export class DialogConfirmComponent extends DialogContentBase {
    override dialog = inject<DialogRef>(DialogRef);
    options: DialogConfirmOptions = {
        title: 'Dialog',
        message: 'Message',
        hideCancel: false,
        cancelText: 'Cancel',
        confirmText: 'Confirm'
    };

    public initialize(options: DialogConfirmOptions) {
        for (const [key, value] of Object.entries(options)) {
            if (value !== undefined && value !== null) this.options[key] = value;
        }
    }
}
