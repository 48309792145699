import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconKey } from '@common/classes/icons';
import { CommonModule } from '@common/common.module';
import { GridCellContext, GridTemplateComponent } from '@common/interfaces/column-settings.interface';

export interface IconCellInputs {
    icons?: IconProperties[];
}

/**
 * @param icon - One of icons in the column Ex:. iconFunction: PropertyFunctionExt<IconKey> = (rowdata: any) => {return 'faIcon' as IconKey;};
 * @param iconClass - The class of the icon Ex:. twJoin('text-xl')
 * @param tooltip - Display tooltip over icon
 */
export type IconProperties = {
    icon: IconKey;
    iconClass?: string | string[] | Set<string> | { [key: string]: any };
    iconClassCon?: string;
    tooltip?: string;
};

@Component({
    selector: 'app-icon-cell',
    standalone: true,
    imports: [CommonModule],
    template: `
        <div class="flex w-full items-center gap-1">
            @for (icon of icons; track $index) {
                <div class="flex h-full items-center" kendoTooltip title="{{ icon.tooltip | translate }}">
                    <ng-icon [name]="icon.icon" [ngClass]="icon.iconClassCon ?? icon.iconClass"></ng-icon>
                </div>
            }
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconCellComponent implements GridTemplateComponent {
    @Input() context: GridCellContext<any>;
    @Input() icons: IconProperties[] = [];
}
