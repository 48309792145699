import { customCondition } from '@administration/codelists/codelists.interface';
import { Component, inject, ViewChild } from '@angular/core';
import { IconKey } from '@common/classes/icons';
import { CodelistInputComponent } from '@common/components/input/codelist-input/codelist-input.component';
import { FleetTypeCodes } from '@common/known-types/fleet-type.codes';
import { OrganizationTypeCodes } from '@common/known-types/organization-type.codes';
import { TranslateService } from '@ngx-translate/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

export interface DialogChangePlateOptions {
    title?: string;
    titleIcons?: IconKey[];
    message?: string;
    cancelText?: string;
    cancelIcon?: IconKey;
    cancelAction?: Function;
    confirmText?: string;
    confirmIcon?: IconKey;
    confirmMessage?: string;
    model?: any[];
    textMode?: boolean;
}

@Component({
    selector: 'app-dialog-change-plate',
    templateUrl: './dialog-change-plate.component.html'
})
export class DialogChangePlateComponent extends DialogContentBase {
    override dialog = inject<DialogRef>(DialogRef);
    translateService = inject(TranslateService);
    model: any;
    textMode: boolean = false;
    options: DialogChangePlateOptions = {
        title: this.translateService.instant('Change plate'),
        message: ''
    };

    @ViewChild('trailerPlate') trailer: CodelistInputComponent;
    @ViewChild('truckPlate') truck: CodelistInputComponent;

    public initialize(options: DialogChangePlateOptions) {
        for (const [key, value] of Object.entries(options)) {
            if (![undefined, null].includes(value)) this.options[key] = value;
        }
        this.model = this.options.model;
        this.textMode = this.options.textMode;
    }

    confirm(): void {
        // if (this.appControls.some((x) => !!x.error)) {
        //     this.toastrNotificationService.show({ type: 'error', message: 'Some required fields are empty' });
        //     return;
        // }

        this.model.truckPlateNumber = this.truck.formatedSelectedOptions;
        this.model.trailerPlateNumber = this.trailer.formatedSelectedOptions;
        this.dialog.close(this.model);
    }

    plateInputType() {
        if (!this.model?.haulierOrganizationType) return true;
        return this.model?.haulierOrganizationType == OrganizationTypeCodes.Temporary;
    }

    close(result: boolean | undefined = undefined) {
        this.dialog.close(result);
    }

    organizationTruckCondition = () => {
        return [
            { name: 'FleetType', condition: customCondition.Equal, value: FleetTypeCodes.Truck },
            { name: 'Organization', condition: customCondition.Equal, value: this.model.organizationId }
        ];
    };

    organizationTrailerCondition = () => {
        return [
            { name: 'FleetType', condition: customCondition.Equal, value: FleetTypeCodes.Trailer },
            { name: 'Organization', condition: customCondition.Equal, value: this.model.organizationId }
        ];
    };
}
