<kendo-dialog-titlebar class="bg-background pb-5 pl-5 pr-3 pt-6 font-bold text-primary" (close)="close()">
    <div class="flex flex-row items-center gap-2">
        {{ options?.title | translate }}
    </div>
</kendo-dialog-titlebar>
<div class="grid w-full grid-cols-12 gap-2 p-4">
    <div class="col-span-2">
        <app-text-input label="Container Code" id="terminal" [isEditMode]="true" [(value)]="model.containerCode" />
    </div>

    <div class="col-span-2">
        <app-text-input label="Release Order" id="terminal" [isEditMode]="true" [(value)]="model.releaseOrder" />
    </div>
    <div class="col-span-2">
        <app-text-input label="SRV Number" id="terminal" [isEditMode]="true" [(value)]="model.srvNumber" />
    </div>
    <div class="col-span-2">
        <app-text-input label="Booking TOS" id="terminal" [isEditMode]="true" [(value)]="model.bookingTos" />
    </div>
    <div class="col-span-2">
        <app-text-input label="Length Code" id="terminal" [isEditMode]="true" [(value)]="model.lengthCode" />
    </div>
    <div class="col-span-2 flex items-end">
        <app-button
            class="px-2 py-1.5"
            type="submit"
            icon="faSolidMagnifyingGlass"
            variant="primary"
            size="sm"
            [tooltip]="'Search for containers' | marker"
            (clicked)="queryContainers()">
            {{ 'Search' | translate }}
        </app-button>
    </div>
</div>
@if (!containerQuery()?.status) {
    <div class="flex min-h-40 w-full items-center justify-center">
        <div class="text-center">
            <p class="text-lg font-bold text-primary">{{ 'Search for containers' | translate }}</p>
        </div>
    </div>
} @else if (containerQuery()?.isLoading) {
    <div class="flex min-h-40 w-full items-center justify-center">
        <app-loader [inline]="true" [isBusy]="true" />
    </div>
} @else if (containerQuery()?.data?.length > 0) {
    <div class="px-8">
        <app-grid
            size="small"
            [showToolbar]="false"
            [pageable]="false"
            [applyLocalPagination]="true"
            [autoFitColumns]="true"
            [data]="containerQuery().data"
            [selectable]="true"
            [cols]="columns"
            [isRowSelectable]="isRowSelectable"
            (selectedKeysChange)="onSelectedKeysChange($event)" />
    </div>
} @else {
    <div class="flex min-h-40 w-full items-center justify-center">
        <div class="text-center">
            <p class="text-lg font-bold text-primary">{{ 'No data found' | translate }}</p>
        </div>
    </div>
}
<kendo-dialog-actions class="px-4" layout="end">
    <div class="flex justify-end space-x-4 sm:space-x-2">
        <app-button variant="light" (click)="close()">
            {{ 'Cancel' | translate }}
        </app-button>
        <app-button variant="primary" (click)="confirm()">
            {{ 'Import' | translate }}
        </app-button>
    </div>
</kendo-dialog-actions>
