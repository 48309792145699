{
    "name": "vbs2",
    "version": "0.0.0",
    "license": "MIT",
    "scripts": {
        "ng": "ng",
        "start": "node generate-git-version-info.js && ng serve --hmr",
        "format": "npx prettier . --write",
        "build": "node generate-git-version-info.js && ng build",
        "build:stats": "node --max_old_space_size=8192 ./node_modules/@angular/cli/bin/ng build --stats-json",
        "analyze": "webpack-bundle-analyzer -p 5678 dist/vbs2/stats.json",
        "extract-i18n": "ngx-translate-extract -i ./src -o ./src/assets/i18n/en.json -o ./src/assets/i18n/sl.json --sort --null-as-default-value  --cache-file --format json && prettier --write \"src/assets/i18n/*.json\"",
        "test": "ng test",
        "lint": "eslint -c .eslintrc.js --ext .ts ./src",
        "e2e": "ng e2e"
    },
    "private": true,
    "dependencies": {
        "@angular/animations": "18.2.1",
        "@angular/common": "18.2.1",
        "@angular/compiler": "18.2.1",
        "@angular/core": "18.2.1",
        "@angular/forms": "18.2.1",
        "@angular/localize": "18.2.1",
        "@angular/platform-browser": "18.2.1",
        "@angular/platform-browser-dynamic": "18.2.1",
        "@angular/router": "18.2.1",
        "@colsen1991/ngx-translate-extract-marker": "^2.0.8",
        "@microsoft/signalr": "^8.0.7",
        "@ng-icons/bootstrap-icons": "^29.5.0",
        "@ng-icons/core": "^29.5.0",
        "@ng-icons/font-awesome": "^29.5.0",
        "@ng-icons/simple-icons": "^29.10.0",
        "@ngneat/query": "^3.0.0",
        "@ngneat/query-devtools": "^2.0.0",
        "@ngneat/subscribe": "^4.0.0",
        "@ngx-loading-bar/core": "^6.0.2",
        "@ngx-translate/core": "^15.0.0",
        "@ngx-translate/http-loader": "^8.0.0",
        "@progress/kendo-angular-buttons": "16.11.0",
        "@progress/kendo-angular-dateinputs": "16.11.0",
        "@progress/kendo-angular-dialog": "16.11.0",
        "@progress/kendo-angular-dropdowns": "16.11.0",
        "@progress/kendo-angular-editor": "16.11.0",
        "@progress/kendo-angular-excel-export": "16.11.0",
        "@progress/kendo-angular-grid": "16.11.0",
        "@progress/kendo-angular-indicators": "16.11.0",
        "@progress/kendo-angular-inputs": "16.11.0",
        "@progress/kendo-angular-intl": "16.11.0",
        "@progress/kendo-angular-label": "16.11.0",
        "@progress/kendo-angular-layout": "16.11.0",
        "@progress/kendo-angular-listview": "16.11.0",
        "@progress/kendo-angular-navigation": "16.11.0",
        "@progress/kendo-angular-pdf-export": "16.11.0",
        "@progress/kendo-angular-scheduler": "^16.11.0",
        "@progress/kendo-angular-tooltip": "^16.11.0",
        "@progress/kendo-angular-upload": "16.11.0",
        "@progress/kendo-data-query": "1.7.0",
        "@progress/kendo-licensing": "1.3.5",
        "@progress/kendo-svg-icons": "^3.3.0",
        "@progress/kendo-theme-core": "^9.1.0",
        "@progress/kendo-theme-default": "^9.1.0",
        "core-js": "^3.38.1",
        "date-fns": "^3.6.0",
        "dayjs": "^1.11.13",
        "flag-icons": "^7.2.3",
        "fuse.js": "^7.0.0",
        "lodash": "^4.17.21",
        "ngx-json-viewer": "^3.2.1",
        "ngx-toastr": "^18.0.0",
        "rxjs": "7.8.1",
        "swiper": "^11.2.0",
        "tailwind-merge": "^2.5.2",
        "zone.js": "~0.14.6"
    },
    "devDependencies": {
        "@angular-devkit/build-angular": "~18.2.1",
        "@angular-eslint/eslint-plugin": "^17.5.2",
        "@angular-eslint/eslint-plugin-template": "^17.5.2",
        "@angular-eslint/template-parser": "^17.4.1",
        "@angular/cli": "18.2.1",
        "@angular/compiler-cli": "18.2.1",
        "@types/jasmine": "~5.1.4",
        "@types/lodash": "^4.17.7",
        "@types/node": "^20.12.12",
        "@typescript-eslint/eslint-plugin": "^7.11.0",
        "@typescript-eslint/parser": "^7.11.0",
        "@vendure/ngx-translate-extract": "^9.2.1",
        "autoprefixer": "^10.4.20",
        "eslint": "^8.57.0",
        "eslint-config-prettier": "^9.1.0",
        "eslint-plugin-import": "^2.29.1",
        "eslint-plugin-jsdoc": "^48.2.6",
        "jasmine-core": "~5.2.0",
        "karma": "~6.4.4",
        "karma-chrome-launcher": "~3.2.0",
        "karma-coverage": "~2.2.1",
        "karma-jasmine": "~5.1.0",
        "karma-jasmine-html-reporter": "~2.1.0",
        "postcss": "^8.4.41",
        "prettier": "^3.3.3",
        "prettier-plugin-organize-attributes": "^1.0.0",
        "prettier-plugin-organize-imports": "^3.2.4",
        "prettier-plugin-tailwindcss": "^0.5.14",
        "tailwindcss": "^3.4.10",
        "typescript": "~5.5.4",
        "webpack-bundle-analyzer": "^4.10.2"
    }
}
