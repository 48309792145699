import { DialogCloseResult } from '@progress/kendo-angular-dialog';
import { ImportContainerDialogComponent } from '../import-container-dialog/import-container-dialog.component';

/**
 * Open dialog to update Bookings: Haulier, Driver, Truck Plate and Trailer Plate
 * @param dialogService current page serviceDialog
 * @param dialogData dialog definition
 * @returns
 */
export const importContainersDialog = (dialogService, dialogData = {}) => {
    const data = dialogService.open({
        template: {
            content: ImportContainerDialogComponent,
            data: dialogData
        },
        dialogSettings: {
            width: '70vw',
            height: 520
        }
    });
    if (data instanceof DialogCloseResult || typeof data !== 'object') return;
};
