import { Component, inject } from '@angular/core';
import { IconKey } from '@common/classes/icons';
import { TimePipe } from '@common/pipes/time.pipe';
import { CommonService } from '@common/services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { BookingViewUtilsService } from '../booking-view-utils.service';

export interface DialogChangeEnterTimeOptions {
    title?: string;
    titleIcons?: IconKey[];
    message?: string;
    cancelText?: string;
    cancelIcon?: IconKey;
    cancelAction?: Function;
    confirmText?: string;
    confirmIcon?: IconKey;
    confirmMessage?: string;
    model?: any[];
    enableSlotSelection?: boolean;
}

@Component({
    selector: 'app-dialog-change-enter-time',
    templateUrl: './dialog-change-enter-time.component.html'
})
export class DialogChangeEnterTimeComponent extends DialogContentBase {
    override dialog = inject<DialogRef>(DialogRef);
    translateService = inject(TranslateService);
    common = inject(CommonService);
    time = inject(TimePipe);
    util = inject(BookingViewUtilsService);

    model: any = {};
    enableSlotSelection: boolean = false;
    slotOptions = [];

    options: DialogChangeEnterTimeOptions = {
        title: this.translateService.instant('Change Enter time'),
        message: ''
    };

    public initialize(options: DialogChangeEnterTimeOptions) {
        for (const [key, value] of Object.entries(options)) {
            if (![undefined, null].includes(value)) this.options[key] = value;
        }
        this.model = this.options.model;
        this.model.date = new Date();
        //this.enableSlotSelection = this.options.enableSlotSelection;
        this.getAvailableSlots();
    }

    getAvailableSlots() {
        const today = new Date();
        const start = this.util.startOfDay(today);
        const end = this.util.endOfDay(today);
        // const start = this.util.reduceDateByYears(this.util.startOfDay(today), 4);
        // const end = this.util.reduceDateByYears(this.util.endOfDay(today), 3);
        this.common.queryService
            .getQuery('Slots', { timeFrom: start, timeTo: end, take: 50 }, { injector: this.common.injector })
            .result$.subscribe((result: any) => {
                if (result.data) {
                    result.data?.results.map((data) => {
                        if (data.freeCapacity > 0)
                            this.slotOptions.push({
                                value: data.id,
                                label: `${this.time.transform(data.timeFrom)} - ${this.time.transform(data.timeTo)}`,
                                data: data
                            });
                    });
                    this.enableSlotSelection = this.slotOptions.length > 0;
                }
            });
    }

    confirm(): void {
        if (this.enableSlotSelection) {
            try {
                const slot = this.slotOptions.find((opt) => opt.value == this.model.slotId).data;
                this.model.timeFrom = slot.timeFrom;
                this.model.timeTo = slot.timeTo;
                if (this.model.mergedBookings == 0) this.model.containerCargo.slot = slot;
            } catch (error) {
                this.common.toastrNotificationService.show({
                    type: 'error',
                    message: 'Some required fields are empty'
                });
                return;
            }
        }

        this.dialog.close(this.model);
    }

    close(result: boolean | undefined = undefined) {
        this.dialog.close(result);
    }
}
