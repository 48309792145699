<kendo-dialog-titlebar class="bg-background pb-5 pl-5 pr-3 pt-6 font-bold text-primary" (close)="close()">
    <div class="flex flex-row items-center gap-2">
        @for (icon of options?.titleIcons; track $index) {
            <ng-icon [name]="icon" />
        }
        {{ options?.title | translate }}
    </div>
</kendo-dialog-titlebar>
<div class="flex min-h-full flex-col items-center justify-center">
    <div class="flex w-full justify-center">
        <div class="flex w-1/3 flex-col items-center justify-center">
            <div class="flex flex-col items-start">
                <div class="flex">
                    <div class="text-4xl font-bold text-blue-900">{{ 'Booking:' | translate }}</div>
                    <div class="pl-2 text-4xl text-gray-600">{{ model.id }}</div>
                </div>
                <div class="mt-4 flex">
                    <div class="text-4xl font-bold text-blue-900">{{ 'Pin:' | translate }}</div>
                    <div class="pl-2 text-4xl text-gray-600">{{ model.pin }}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-4 flex w-full justify-center">
        <div class="mx-2 w-1/3">
            <div class="flex w-full items-center p-4">
                <div class="mr-4">
                    <ng-icon class="text-4xl text-blue-900" name="faSolidCalendarDays" />
                </div>
                <div>
                    <div class="mb-4 text-4xl font-bold text-blue-900">{{ 'Enter time:' | translate }}</div>
                    <div class="text-4xl">{{ model.timeFrom | date }} - {{ model.timeTo | time }}</div>
                </div>
            </div>
        </div>
        <div class="mx-2 w-1/3">
            <div class="flex w-full items-center p-4">
                <div class="mr-4">
                    <ng-icon class="text-4xl text-blue-900" name="faSolidTruckMoving" />
                </div>
                <div>
                    <div class="mb-4 text-4xl font-bold text-blue-900">{{ 'Gate:' | translate }}</div>
                    <div class="text-4xl">
                        {{ model.plannedGateId }} - {{ model.plannedGateId | codelist: 'Gate' | async }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<kendo-dialog-actions class="px-4" layout="end">
    <div class="flex justify-end space-x-4 sm:space-x-2">
        <app-button variant="danger" [icon]="options?.cancelIcon" (click)="close()">
            {{ options?.cancelText | translate }}
        </app-button>
    </div>
</kendo-dialog-actions>
