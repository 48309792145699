@if (slots()?.length === 0) {
    <div class="flex h-16 w-full items-center justify-center">
        <div class="text-center">
            <div class="font-semibold text-foreground">No slots available</div>
            <div class="text-sm text-secondary">Please select another date</div>
        </div>
    </div>
} @else {
    <swiper-container
        #swiper
        [grab-cursor]="options.grabCursor"
        [navigation]="options.navigation"
        [free-mode]="options.freeMode"
        [spaceBetween]="options.spaceBetween"
        [breakpoints]="options.breakpoints"
        [slidesPerView]="options.slidesPerView">
        @for (item of slots(); track $index) {
            <swiper-slide class="h-full">
                <div
                    class="flex w-12 flex-col items-center justify-center gap-1 rounded-md border-2 py-3 text-foreground transition-all hover:shadow-sm dark:border-input"
                    [ngClass]="{
                        'border-primary dark:border-primary': selected === item.value,
                        'cursor-not-allowed bg-light opacity-50': item.data.freeCapacity <= 0,
                        'cursor-pointer': item.data.freeCapacity > 0
                    }"
                    (click)="selectedSlot(item)">
                    <div class="">{{ item.label }}</div>
                    <div class="text-center font-semibold">{{ item.data.freeCapacity }}</div>
                    <div
                        class="h-1 w-5 rounded-full"
                        [ngClass]="{
                            'bg-danger': item.data.freeCapacity <= 0,
                            'bg-success': item.data.freeCapacity > 0
                        }"></div>
                </div>
            </swiper-slide>
        }
    </swiper-container>
}
