import { Inject, Injectable, LOCALE_ID, signal } from '@angular/core';
import { User } from '@common/models/user';
import { TranslateService } from '@ngx-translate/core';
import { ListItemModel } from '@progress/kendo-angular-buttons';
import { CldrIntlService, IntlService } from '@progress/kendo-angular-intl';
import { distinctUntilChanged } from 'rxjs';
import { QueryService } from './query.service';
import { RememberStateService } from './remember-state.service';
import { ToastrNotificationService } from './toastr-notification.service';
import { AuthTokens, UserService } from './user.service';

export interface Language extends ListItemModel {
    languageId: string;
}

export enum LanguageIds {
    en = 'en',
    sl = 'sl'
}

@Injectable({
    providedIn: 'root'
})
export class I18nService {
    currentLanguage = signal<Language>(null);
    languages: Language[] = [
        {
            languageId: LanguageIds.en,
            text: 'EN - English',
            iconClass: 'fi fi-us',
            click: () => this.changeDisplayLanguage(LanguageIds.en)
        },
        {
            languageId: LanguageIds.sl,
            text: 'SL - Slovenščina',
            iconClass: 'fi fi-si',
            click: () => this.changeDisplayLanguage(LanguageIds.sl)
        }
    ];
    isAuthenticated = false;

    constructor(
        private translateService: TranslateService,
        private rememberStateService: RememberStateService,
        private queryService: QueryService,
        private userService: UserService,
        private toastrNotificationService: ToastrNotificationService,
        @Inject(LOCALE_ID) private localeId: string,
        private intlService: IntlService
    ) {
        this.userService.isAuthenticatedSubject.pipe(distinctUntilChanged()).subscribe((value) => {
            this.isAuthenticated = value;
        });
        this.initialSetCurrentLanguageId();
    }

    private initialSetCurrentLanguageId() {
        const rememberedLanguageId = this.rememberStateService.get<LanguageIds>('languageId');
        if (rememberedLanguageId) {
            this.currentLanguage.set(this.getLanguageById(rememberedLanguageId));
        } else if (this.translateService.currentLang) {
            this.currentLanguage.set(this.getLanguageById(this.translateService.currentLang as LanguageIds));
        } else {
            this.currentLanguage.set(this.getLanguageById(this.translateService.defaultLang as LanguageIds));
        }
    }

    private getLanguageById(id: LanguageIds) {
        return this.languages.find((language) => {
            return language.languageId === id;
        });
    }

    /**
     * Change display language to the provided languageId.
     * For authenticated users also update the selected languageId in the database.
     */
    changeDisplayLanguage(languageId: LanguageIds): void {
        this.setLanguage(languageId);
        if (this.isAuthenticated) {
            this.setUserLanguageCommand(languageId);
        }
    }

    setLanguageFromUser(currentUser: User): void {
        const currentUserLanguageId = currentUser.languageId.toLowerCase() as LanguageIds;
        this.setLanguage(currentUserLanguageId);
    }

    private async setUserLanguageCommand(languageId: LanguageIds): Promise<void> {
        const result = await this.queryService.getCommandMutation<AuthTokens>().mutateAsync({
            command: 'SetUserLanguage',
            data: { languageId: languageId.toUpperCase() }
        });

        if (!result) {
            this.toastrNotificationService.show({ type: 'error', message: 'Error while setting user language.' });
            return;
        }
        const { refreshToken, accessToken } = result;
        this.userService.setAccessToken(accessToken);
        this.userService.setRefreshToken(refreshToken);

        this.queryService.queryClient.invalidateQueries({ queryKey: [] });
    }

    private setLanguage(languageId: LanguageIds): void {
        this.translateService.use(languageId);
        this.rememberStateService.set('languageId', languageId);
        this.currentLanguage.set(this.getLanguageById(languageId));
        this.localeId = languageId;
        (<CldrIntlService>this.intlService).localeId = languageId;
    }
}
