<div *subscribe="query$ as query">
    <app-loader [isBusy]="query?.isLoading">
        <ng-template>
            @if (isDriver) {
                <app-panel-driver-button-view
                    [model]="model"
                    (confirm)="confirm()"
                    (changeTime)="changeTime()"
                    (changePlates)="changePlates()"
                    (addSecondDriver)="addSecondDriver()"
                    (mergeBooking)="mergeBooking()"
                    (unmergeBooking)="unmergeBooking()"
                    (cancel)="cancel()" />
            }
            <app-content-header [title]="getTitle()" [icon]="icon" [actionBar]="actionBar" />
            @if (viewMode) {
                <app-card>
                    <div class="grid grid-cols-12 gap-x-4 gap-y-2">
                        <div class="col-span-3">
                            <app-codelist-input
                                label="Booking status"
                                id="bookingStatus"
                                labelFormat="LongCode"
                                codelist="BookingStatus"
                                [(value)]="model.bookingStatusId" />
                        </div>
                        <div class="col-span-3">
                            <app-datetime-input
                                label="Scheduled time"
                                id="timeFrom"
                                [isEditMode]="editMode"
                                [(value)]="model.timeFrom" />
                        </div>
                        <div class="col-span-3">
                            <app-text-input
                                label="Terminal"
                                id="terminal"
                                [isEditMode]="editMode"
                                [(value)]="model.terminal" />
                        </div>
                        <div class="col-span-1">
                            <app-boolean-input
                                label="Blocked"
                                id="blocked"
                                [isEditMode]="false"
                                [(value)]="model.blocked" />
                        </div>
                        <div class="col-span-1">
                            <app-boolean-input
                                label="Damage discharge"
                                id="damageDischarge"
                                [isEditMode]="false"
                                [(value)]="model.damagedDischarge" />
                        </div>
                        <div class="col-span-1">
                            <app-boolean-input
                                label="Damage load"
                                id="damageLoad"
                                [isEditMode]="false"
                                [(value)]="model.damagedLoad" />
                        </div>
                        <div class="col-span-3">
                            <app-codelist-input
                                label="Responsible haulier"
                                id="responsibleHaulier"
                                codelist="Organization"
                                [(value)]="model.responsibleHaulierId" />
                        </div>
                        <div class="col-span-3">
                            <app-codelist-input
                                label="Actual haulier"
                                id="actualHaulier"
                                codelist="Organization"
                                [(value)]="model.actualHaulierId" />
                        </div>
                        <div class="col-span-3">
                            <app-text-input
                                label="Driver"
                                id="driver"
                                [isEditMode]="editMode"
                                [(value)]="model.driver" />
                        </div>
                        <div class="col-span-2">
                            <app-text-input
                                label="Additional driver"
                                id="additionalDriver"
                                [isEditMode]="editMode"
                                [(value)]="model.additionalDriver" />
                        </div>
                        <div class="col-span-3">
                            <app-text-input
                                label="Announced Mot no"
                                id="announcedMotNo"
                                [isEditMode]="editMode"
                                [(value)]="model.announcedMotNo" />
                        </div>
                        <div class="col-span-3">
                            <app-text-input
                                label="Truck"
                                id="truckPlate"
                                [isEditMode]="editMode"
                                [(value)]="model.truckPlateNumber" />
                        </div>
                        <div class="col-span-3">
                            <app-text-input
                                label="Trailer"
                                id="trailerPlate"
                                [isEditMode]="editMode"
                                [(value)]="model.trailerPlateNumber" />
                        </div>
                    </div>
                </app-card>
                @if (viewMode) {
                    <app-truck-visit-bar [model]="model"></app-truck-visit-bar>
                }
                <app-panel-booking-data
                    class="mt-2"
                    bookingDataTitle="Vehicles list"
                    [model]="model.vehicles"
                    [dataColumns]="carColumns"></app-panel-booking-data>
            } @else {
                <div class="flex">
                    <app-card class="w-3/5 pr-2">
                        <form class="mb-4" (ngSubmit)="search()">
                            <h3 class="mb-4 text-xl font-semibold text-foreground">
                                {{ 'Search vehicles' | translate }}
                            </h3>
                            <div class="flex w-full items-end gap-4">
                                <app-text-input
                                    class="w-full"
                                    id="vin"
                                    [label]="'Vin' | marker"
                                    [isEditMode]="editMode"
                                    [(value)]="filter.vin" />
                                <app-text-input
                                    class="w-full"
                                    id="serviceRequest"
                                    [label]="'Service request' | marker"
                                    [isEditMode]="editMode"
                                    [(value)]="filter.serviceRequest" />
                                <app-button
                                    class="mt-6 px-2"
                                    type="submit"
                                    icon="faSolidMagnifyingGlass"
                                    variant="primary"
                                    size="sm"
                                    [tooltip]="'Search for car' | marker">
                                    {{ 'Search' | translate }}
                                </app-button>
                            </div>
                        </form>
                        <app-grid
                            [data]="vehiclesDataGrid"
                            [cols]="carEditColumns"
                            [defaultViewMode]="defaultViewMode"
                            [queryData]="search"
                            [autoFitColumns]="true"
                            [showToolbar]="false"
                            [pageable]="false"
                            (saveGridSettings)="saveGridSettings($event)"
                            (deleteGridSettings)="deleteGridSettings()"></app-grid>
                    </app-card>
                    <app-card class="w-2/5">
                        <div class="space-y-6">
                            <!-- Booking Details Section -->
                            <div>
                                <h3 class="mb-4 text-xl font-semibold text-foreground">
                                    {{ 'Booking details' | translate }}
                                </h3>
                                <div class="grid w-full grid-cols-4 gap-4">
                                    <div class="col-span-4 md:col-span-2">
                                        <app-datetime-input
                                            id="lastModifiedDate"
                                            [label]="'Date from' | marker"
                                            [isEditMode]="editMode"
                                            [isDisabled]="!isDateFromEditable()"
                                            [(value)]="model.timeFrom"
                                            (valueChange)="onDateFromChange($event)" />
                                    </div>
                                    <div class="col-span-4 md:col-span-2">
                                        <app-datetime-input
                                            id="lastModifiedDate"
                                            [label]="'Date to' | marker"
                                            [isEditMode]="editMode"
                                            [isDisabled]="!isDateToEditable()"
                                            [(value)]="model.timeTo" />
                                    </div>
                                    <div class="col-span-4 md:col-span-2">
                                        <app-codelist-input
                                            #responsiblehaulier
                                            id="organization"
                                            codelist="Organization"
                                            [label]="'Responsible haulier' | marker"
                                            [isEditMode]="editMode"
                                            [isDisabled]="!isResponsibleHaulierEditable()"
                                            [(value)]="model.responsibleHaulierId" />
                                    </div>
                                    <div class="col-span-4 md:col-span-2">
                                        <app-codelist-input
                                            #haulier
                                            id="organization"
                                            codelist="Organization"
                                            [label]="'Actual haulier' | marker"
                                            [isEditMode]="editMode"
                                            [isDisabled]="!isPropertyEditable('haulier')"
                                            [(value)]="model.actualHaulierId"
                                            (valueChange)="onHaulierChange($event)" />
                                    </div>
                                    <div class="col-span-4 md:col-span-2">
                                        <app-codelist-input
                                            #driver
                                            id="driver"
                                            codelist="Driver"
                                            [label]="'Driver' | marker"
                                            [customCondition]="util.organizationCondition(model)"
                                            [isEditMode]="editMode"
                                            [isDisabled]="!isPropertyEditable('driver')"
                                            [(value)]="model.driverId"
                                            (valueChange)="onDriverChange($event)" />
                                    </div>
                                    <div class="col-span-4 md:col-span-2">
                                        <app-codelist-input
                                            #driver
                                            id="driver"
                                            codelist="Driver"
                                            [label]="'Additional Driver' | marker"
                                            [isDisabled]="!isPropertyEditable('additionalDriver') && !model.driverId"
                                            [customCondition]="util.organizationCondition(model)"
                                            [isEditMode]="editMode"
                                            [(value)]="model.additionalDriverId" />
                                    </div>

                                    @if (selectedHaulier.isTemporary) {
                                        <div class="col-span-4 md:col-span-2">
                                            <app-text-input
                                                label="Truck"
                                                #truck
                                                id="truckTmp"
                                                [isDisabled]="!isPropertyEditable('truck') && !model.actualHaulierId"
                                                [isEditMode]="editMode"
                                                [(value)]="model.tempTruck" />
                                        </div>
                                        <div class="col-span-4 md:col-span-2">
                                            <div class="flex">
                                                <div class="w-3/4">
                                                    <app-text-input
                                                        label="Trailer"
                                                        id="trailerTmp"
                                                        [isDisabled]="
                                                            !isPropertyEditable('trailer') && !model.actualHaulierId
                                                        "
                                                        [isEditMode]="editMode"
                                                        [(value)]="model.tempTrailer" />
                                                </div>
                                                <div class="w-1/4 p-2">
                                                    <app-boolean-input
                                                        [label]="'No Trailer' | marker"
                                                        [isEditMode]="editMode"
                                                        [(value)]="model.active" />
                                                </div>
                                            </div>
                                        </div>
                                    } @else {
                                        <div class="col-span-4 md:col-span-2">
                                            <app-codelist-input
                                                #truck
                                                id="truck"
                                                codelist="Fleet"
                                                [label]="'Truck' | marker"
                                                [isEditMode]="editMode"
                                                [isDisabled]="!isPropertyEditable('truck') && !model.actualHaulierId"
                                                [customCondition]="util.plateTruckCodelistCondition(model)"
                                                [(value)]="model.truckId" />
                                        </div>
                                        <div class="col-span-4 md:col-span-2">
                                            <div class="flex">
                                                <div class="w-3/4">
                                                    <app-codelist-input
                                                        #trailer
                                                        id="trailer"
                                                        codelist="Fleet"
                                                        [label]="'Trailer' | marker"
                                                        [isEditMode]="editMode"
                                                        [isDisabled]="
                                                            !isPropertyEditable('trailer') && !model.actualHaulierId
                                                        "
                                                        [customCondition]="util.plateTrailerCodelistCondition(model)"
                                                        [(value)]="model.trailerId" />
                                                </div>
                                                <div class="w-1/4 p-2">
                                                    <app-boolean-input
                                                        [label]="'No Trailer' | marker"
                                                        [isEditMode]="editMode"
                                                        [(value)]="model.active" />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                            <!-- Vehicles Section -->
                            <div class="flex flex-col gap-2">
                                <h3 class="mb-2 text-xl font-semibold text-foreground">{{ 'Vehicles' | translate }}</h3>
                                <div class="flex w-full gap-4">
                                    <div class="w-1/2">
                                        <h4 class="border-b border-input text-lg font-medium text-primary">
                                            {{ 'For discharge' | translate }}
                                        </h4>
                                        <div class="max-h-48 overflow-y-auto rounded-md">
                                            @if (getVehicles('D').length === 0) {
                                                <div
                                                    class="rounded-md border border-dashed border-muted p-3 text-center italic text-muted">
                                                    {{ 'No vehicles for discharge' | translate }}
                                                </div>
                                            } @else {
                                                <table
                                                    class="w-full border-separate border-spacing-x-0.5 border-spacing-y-1">
                                                    <tr class="text-xs text-neutral-400 *:px-3">
                                                        <th class="w-3/4 text-start font-normal" translate>Vehicle</th>
                                                        <th class="text-start font-normal" translate>SR</th>
                                                        <th></th>
                                                    </tr>
                                                    @for (item of getVehicles('D'); track item.vehicle) {
                                                        <tr class="*:bg-background *:py-2">
                                                            <td class="rounded-bl-md rounded-tl-md px-3">
                                                                {{ item.vehicle }}
                                                            </td>
                                                            <td class="px-3">
                                                                {{ item.serviceRequest }}
                                                            </td>
                                                            <td class="w-5 rounded-br-md rounded-tr-md">
                                                                <app-button
                                                                    class="min-w-fit px-3 shadow-none"
                                                                    icon="faSolidXmark"
                                                                    tooltip="Remove car"
                                                                    size="sm"
                                                                    variant="link"
                                                                    [disabled]="!isPropertyEditable('cargo')"
                                                                    (click)="removeTinoVehicle(item)" />
                                                            </td>
                                                        </tr>
                                                    }
                                                </table>
                                            }
                                        </div>
                                    </div>
                                    <div class="w-1/2">
                                        <h4 class="border-b border-input text-lg font-medium text-primary">
                                            {{ 'For loading' | translate }}
                                        </h4>
                                        <div class="max-h-48 overflow-y-auto rounded-md">
                                            @if (getVehicles('P').length === 0) {
                                                <div
                                                    class="rounded-md border border-dashed border-muted p-3 text-center italic text-muted">
                                                    {{ 'No vehicles for loading' | translate }}
                                                </div>
                                            } @else {
                                                <table
                                                    class="w-full border-separate border-spacing-x-0.5 border-spacing-y-1">
                                                    <tr class="text-xs text-neutral-400 *:px-3">
                                                        <th class="w-3/4 text-start font-normal" translate>Vehicle</th>
                                                        <th class="text-start font-normal" translate>SR</th>
                                                    </tr>
                                                    @for (item of getVehicles('P'); track item.vehicle) {
                                                        <tr class="*:bg-background *:py-2">
                                                            <td class="rounded-bl-md rounded-tl-md px-3">
                                                                {{ item.vehicle }}
                                                            </td>
                                                            <td class="px-3">
                                                                {{ item.serviceRequest }}
                                                            </td>
                                                        </tr>
                                                    }
                                                </table>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </app-card>
                </div>
            }
        </ng-template>
    </app-loader>
</div>
