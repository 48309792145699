<div class="mb-2 grid grid-cols-4 gap-2">
    @if (confirmVisible()) {
        <div class="col-span-1 text-2xl">
            <app-button class="w-full py-5" variant="success" icon="faSolidCheck" (clicked)="confirmBtn()">
                {{ 'Confirm' | translate }}
            </app-button>
        </div>
    }
    @if (changeTimeVisible()) {
        <div class="col-span-1 text-2xl">
            <app-button class="w-full p-5" variant="warning" icon="faSolidClock" (clicked)="changeTimeBtn()">
                {{ 'Change time' | translate }}
            </app-button>
        </div>
    }
    @if (changePlatesVisible()) {
        <div class="col-span-1 text-2xl">
            <app-button class="w-full p-5" variant="warning" icon="faSolidObjectUngroup" (clicked)="changePlatesBtn()">
                {{ 'Change Plates' | translate }}
            </app-button>
        </div>
    }
    @if (addSecondDriverVisible()) {
        <div class="col-span-1 text-2xl">
            <app-button class="w-full p-5" variant="warning" icon="faSolidIdBadge" (clicked)="addSecondDriverBtn()">
                {{ 'Add 2nd Driver' | translate }}
            </app-button>
        </div>
    }
    @if (mergeBookingVisible()) {
        <div class="col-span-1 text-2xl">
            <app-button class="w-full p-5" variant="warning" icon="faSolidCompress" (clicked)="mergeBookingBtn()">
                {{ 'Merge booking' | translate }}
            </app-button>
        </div>
    }
    @if (unmergeBookingVisible()) {
        <div class="col-span-1 text-2xl">
            <app-button class="w-full p-5" variant="warning" icon="faSolidIdBadge" (clicked)="unmergeBookingBtn()">
                {{ 'Unmerge booking' | translate }}
            </app-button>
        </div>
    }
    @if (cancelVisible()) {
        <div class="col-span-1 text-2xl">
            <app-button class="w-full p-5" variant="danger" icon="faSolidXmark" (clicked)="cancelBtn()">
                {{ 'Cancel' | translate }}
            </app-button>
        </div>
    }
</div>
