import { customCondition, fakedCodelists } from '@administration/codelists/codelists.interface';
import { inject, Injectable } from '@angular/core';
import { IconKey } from '@common/classes/icons';
import { BookingTypeCodes } from '@common/known-types/booking-type.codes';
import { CargoTypeCodes } from '@common/known-types/cargo-type.codes';
import { FleetTypeCodes } from '@common/known-types/fleet-type.codes';
import { PropertyFunction } from '@common/models/util.interface';
import { CommonService } from '@common/services/common.service';
import { ChangeData, DialogCommunicationService } from '@common/services/dialog-communication.service';
import { QueryService } from '@common/services/query.service';
import { DialogCloseResult } from '@progress/kendo-angular-dialog';
import { BookingsBase } from 'app/booking/bookings.interface';
import {
    DialogChangeBookingComponent,
    DialogChangeBookingOptions
} from './dialog-change-booking/dialog-change-booking.component';

@Injectable({
    providedIn: 'root'
})
export class BookingListUtilsService {
    queryService = inject(QueryService);
    communicationService = inject(DialogCommunicationService);
    common = inject(CommonService);
    constructor() {}

    /**
     * Get appropriate icon based on cargoType
     * @param data get row from grid
     * @returns returns IconKey for appropriate icon
     */
    showIconForCargoType: PropertyFunction<IconKey> = (data: BookingsBase) => {
        switch (data.cargoTypeId) {
            case CargoTypeCodes.CAR:
                return 'faSolidCarSide' as IconKey;
            case CargoTypeCodes.CONTAINER:
                return 'faSolidTruckMoving' as IconKey;
            case CargoTypeCodes.GENERAL_CARGO:
                return 'faSolidWarehouse' as IconKey;
            case CargoTypeCodes.PRIVATE:
                return 'faSolidHouseUser' as IconKey;
            default:
                return '' as IconKey;
        }
    };

    /**
     * Return ArrowUp icon if cargoType=CAR and bookingType=MIXED OR PICK UP
     * @param data get row from grid
     * @returns icon or empty
     */
    showIconPickUp: PropertyFunction<IconKey> = (data: BookingsBase) => {
        if (
            data.cargoTypeId == CargoTypeCodes.CAR &&
            (data.bookingTypeId == BookingTypeCodes.MIXED || data.bookingTypeId == BookingTypeCodes.PICK_UP)
        )
            return 'faSolidArrowUp' as IconKey;
        return '' as IconKey;
    };

    /**
     * Return ArrowDown icon if cargoType=CAR and bookingType=MIXED OR PICK DOWN
     * @param data get row from grid
     * @returns icon or empty
     */
    showIconDropOff: PropertyFunction<IconKey> = (data: BookingsBase) => {
        if (
            data.cargoTypeId == CargoTypeCodes.CAR &&
            (data.bookingTypeId == BookingTypeCodes.MIXED || data.bookingTypeId == BookingTypeCodes.DROP_OFF)
        )
            return 'faSolidArrowDown' as IconKey;
        return '' as IconKey;
    };

    /**
     * Open dialog to update Bookings: Hailier, Driver, Truck Plate and Trailer Plate
     * @param dialogService current page serviceDialog
     * @param dialogData dialog definition
     * @returns
     */
    changeBookingDialog(dialogService, dialogData: DialogChangeBookingOptions) {
        const data = dialogService.open({
            template: {
                content: DialogChangeBookingComponent,
                data: dialogData
            },
            dialogSettings: {
                width: 720,
                height: 520
            }
        });
        if (data instanceof DialogCloseResult || typeof data !== 'object') return;
        // this.filter = data;
        // this.search();
        // this.toastrNotificationService.show({ type: 'error', message: 'Some required fields are empty' });
    }

    private selectedOrganization = undefined;
    clearFilter() {
        this.selectedOrganization = undefined;
        this.setDriverCodelist(null, true);
    }
    /**
     * With help of communication service, for communication between app-control's
     * Manage next logic:
     * Haulier[orgId] SET Driver[options]
     *
     * @param filter input this.filter or parameter which hold user selected filter data
     * @returns
     */
    subscribeToFilterLogic() {
        return this.communicationService.valueChange$.subscribe((value: ChangeData) => {
            if (value.label == 'Haulier' && value.codelist == fakedCodelists.organization) {
                if (this.selectedOrganization != value.value) {
                    this.selectedOrganization = value.value;
                    this.setDriverCodelist(value.value, true);
                }
            }
            if (value.label == 'Driver' && value.value) {
                if (this.selectedOrganization) {
                    this.setPlate(this.selectedOrganization);
                } else {
                    this.queryService
                        .getQuery<any>('Driver', { id: value.value }, { injector: this.common.injector })
                        .result$.subscribe((value) => {
                            if (value.data != undefined) {
                                if (this.selectedOrganization != value.data.organizationId) {
                                    this.selectedOrganization = value.data.organizationId;
                                    this.communicationService.emitUpdateChange({
                                        label: 'Haulier',
                                        value: value.data.organizationId
                                    });
                                    this.communicationService.emitRefreshChange('Haulier');
                                    this.setDriverCodelist(value.data.organizationId);
                                    this.setPlate(value.data.organizationId);
                                }
                            }
                        });
                }
            }
        });
    }

    private setPlate(organizationId) {
        this.communicationService.emitUpdateChange({
            label: 'Truck',
            customCondition: [
                { name: 'FleetType', condition: customCondition.Equal, value: FleetTypeCodes.Truck },
                { name: 'Organization', condition: customCondition.Equal, value: organizationId }
            ]
        });
        this.communicationService.emitUpdateChange({
            label: 'Trailer',
            customCondition: [
                { name: 'FleetType', condition: customCondition.Equal, value: FleetTypeCodes.Trailer },
                { name: 'Organization', condition: customCondition.Equal, value: organizationId }
            ]
        });
        this.communicationService.emitRefreshChange('Truck');
        this.communicationService.emitRefreshChange('Trailer');
    }

    private setDriverCodelist(organizationId, clear: boolean = false) {
        this.communicationService.emitUpdateChange({
            label: 'Driver',
            clear: clear,
            customCondition: [{ name: 'Organization', condition: customCondition.Equal, value: organizationId }]
        });
        this.communicationService.emitRefreshChange('Driver');
    }
}
