import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, inject, input, Output, ViewChild } from '@angular/core';
import { SwiperContainer } from 'swiper/element';

export class slotSelectorOptions {
    grabCursor: boolean;
    navigation: boolean;
    freeMode: boolean;
    spaceBetween: number;
    slidesPerView: number;
    breakpoints: Record<number, { slidesPerView: number; spaceBetween?: number }> = {};
}

@Component({
    selector: 'app-scroll-slot-selector',
    templateUrl: './scroll-slot-selector.component.html'
})
export class ScrollSlotSelectorComponent {
    @ViewChild('swiper') swiper!: ElementRef<SwiperContainer>;
    slots = input<SlotData[]>([]);
    @Output() slotSelect = new EventEmitter<any>();
    options: slotSelectorOptions = {
        grabCursor: true,
        navigation: true,
        freeMode: true,
        spaceBetween: 5,
        slidesPerView: 24,
        breakpoints: {
            320: {
                slidesPerView: 5
            },
            760: {
                slidesPerView: 10
            },
            1000: {
                slidesPerView: 15
            },
            1600: {
                slidesPerView: 24
            }
        }
    };
    selected = null;
    date = inject(DatePipe);
    refDate = new Date();
    loading = true;

    selectedSlot(item: any) {
        if (this.selected === item.value) {
            this.selected = null;
            this.slotSelect.emit(null);
            return;
        }
        if (item.data.freeCapacity > 0) {
            this.selected = item.value;
            this.slotSelect.emit(item.value);
        }
    }

    index = 0;

    slideChange(swiper: any) {
        this.index = swiper.detail[0].activeIndex;
    }
    onSwiper(swiper: any) {
        this.index = swiper.detail[0].activeIndex;
    }
}

export interface SlotData {
    value: number;
    label: string;
    data: any;
}
