import {
    faSolidAddressCard,
    faSolidAnchor,
    faSolidAngleLeft,
    faSolidAngleRight,
    faSolidAngleUp,
    faSolidAnglesLeft,
    faSolidAnglesRight,
    faSolidArrowDown,
    faSolidArrowLeftLong,
    faSolidArrowRightArrowLeft,
    faSolidArrowRightFromBracket,
    faSolidArrowRightToBracket,
    faSolidArrowRotateLeft,
    faSolidArrowRotateRight,
    faSolidArrowUp,
    faSolidArrowsRotate,
    faSolidArrowsTurnToDots,
    faSolidBan,
    faSolidBars,
    faSolidBatteryEmpty,
    faSolidBatteryFull,
    faSolidBell,
    faSolidBellSlash,
    faSolidBook,
    faSolidBox,
    faSolidBoxArchive,
    faSolidBoxOpen,
    faSolidBoxesStacked,
    faSolidBuilding,
    faSolidBullhorn,
    faSolidCalendarCheck,
    faSolidCalendarDays,
    faSolidCar,
    faSolidCarSide,
    faSolidCaretDown,
    faSolidCaretUp,
    faSolidChartBar,
    faSolidCheck,
    faSolidCircle,
    faSolidCircleArrowRight,
    faSolidCircleCheck,
    faSolidCircleExclamation,
    faSolidCircleInfo,
    faSolidCirclePlay,
    faSolidCircleQuestion,
    faSolidCircleUser,
    faSolidCircleXmark,
    faSolidClipboardList,
    faSolidClock,
    faSolidClone,
    faSolidCompress,
    faSolidCube,
    faSolidCubes,
    faSolidDatabase,
    faSolidDownload,
    faSolidEnvelope,
    faSolidEnvelopeCircleCheck,
    faSolidEnvelopeOpen,
    faSolidExclamation,
    faSolidExpand,
    faSolidEye,
    faSolidFile,
    faSolidFileArrowDown,
    faSolidFileArrowUp,
    faSolidFileCsv,
    faSolidFileExcel,
    faSolidFileInvoiceDollar,
    faSolidFileLines,
    faSolidFilePdf,
    faSolidFilter,
    faSolidFilterCircleXmark,
    faSolidFire,
    faSolidFireFlameCurved,
    faSolidFloppyDisk,
    faSolidFolder,
    faSolidGauge,
    faSolidGear,
    faSolidHammer,
    faSolidHand,
    faSolidHouse,
    faSolidHouseUser,
    faSolidIdBadge,
    faSolidInbox,
    faSolidKey,
    faSolidLanguage,
    faSolidLightbulb,
    faSolidList,
    faSolidListCheck,
    faSolidLocationDot,
    faSolidLock,
    faSolidLockOpen,
    faSolidMagnifyingGlass,
    faSolidMoon,
    faSolidObjectUngroup,
    faSolidPaperclip,
    faSolidPassport,
    faSolidPenToSquare,
    faSolidPersonMilitaryPointing,
    faSolidPlaneArrival,
    faSolidPlaneDeparture,
    faSolidPlay,
    faSolidPlus,
    faSolidPrint,
    faSolidQuestion,
    faSolidScrewdriverWrench,
    faSolidShare,
    faSolidShieldHalved,
    faSolidShip,
    faSolidSun,
    faSolidTableColumns,
    faSolidTags,
    faSolidThumbsDown,
    faSolidThumbsUp,
    faSolidThumbtack,
    faSolidTrain,
    faSolidTrash,
    faSolidTruck,
    faSolidTruckMoving,
    faSolidTruckRampBox,
    faSolidUpload,
    faSolidUser,
    faSolidUserPlus,
    faSolidUsers,
    faSolidWarehouse,
    faSolidXmark
} from '@ng-icons/font-awesome/solid';

import { bootstrapApple, bootstrapFacebook, bootstrapGoogle } from '@ng-icons/bootstrap-icons';

import { simpleTesla } from '@ng-icons/simple-icons';

const icons = {
    bootstrapApple,
    bootstrapFacebook,
    bootstrapGoogle,
    faSolidAddressCard,
    faSolidAnchor,
    faSolidAngleLeft,
    faSolidAngleRight,
    faSolidAnglesLeft,
    faSolidAnglesRight,
    faSolidAngleUp,
    faSolidArrowDown,
    faSolidArrowLeftLong,
    faSolidArrowRightArrowLeft,
    faSolidArrowRightFromBracket,
    faSolidArrowRightToBracket,
    faSolidArrowRotateLeft,
    faSolidArrowRotateRight,
    faSolidArrowsRotate,
    faSolidArrowsTurnToDots,
    faSolidArrowUp,
    faSolidBan,
    faSolidBars,
    faSolidBatteryEmpty,
    faSolidBatteryFull,
    faSolidBell,
    faSolidBellSlash,
    faSolidBook,
    faSolidBox,
    faSolidBoxArchive,
    faSolidBoxOpen,
    faSolidBoxesStacked,
    faSolidBuilding,
    faSolidBullhorn,
    faSolidCar,
    faSolidCarSide,
    faSolidCaretDown,
    faSolidCaretUp,
    faSolidCalendarCheck,
    faSolidCalendarDays,
    faSolidChartBar,
    faSolidCheck,
    faSolidCircle,
    faSolidCircleArrowRight,
    faSolidCircleCheck,
    faSolidCircleExclamation,
    faSolidCircleInfo,
    faSolidCircleQuestion,
    faSolidCirclePlay,
    faSolidCircleUser,
    faSolidCircleXmark,
    faSolidClipboardList,
    faSolidClock,
    faSolidClone,
    faSolidCompress,
    faSolidCube,
    faSolidCubes,
    faSolidDatabase,
    faSolidDownload,
    faSolidEnvelope,
    faSolidEnvelopeCircleCheck,
    faSolidEnvelopeOpen,
    faSolidExclamation,
    faSolidExpand,
    faSolidEye,
    faSolidFile,
    faSolidFileArrowDown,
    faSolidFileArrowUp,
    faSolidFileExcel,
    faSolidFileCsv,
    faSolidFileInvoiceDollar,
    faSolidFileLines,
    faSolidFilePdf,
    faSolidFilter,
    faSolidFilterCircleXmark,
    faSolidFire,
    faSolidFireFlameCurved,
    faSolidFloppyDisk,
    faSolidFolder,
    faSolidGauge,
    faSolidGear,
    faSolidHammer,
    faSolidHand,
    faSolidHouse,
    faSolidHouseUser,
    faSolidIdBadge,
    faSolidInbox,
    faSolidKey,
    faSolidLanguage,
    faSolidLightbulb,
    faSolidList,
    faSolidListCheck,
    faSolidLocationDot,
    faSolidLock,
    faSolidLockOpen,
    faSolidMagnifyingGlass,
    faSolidMoon,
    faSolidObjectUngroup,
    faSolidPaperclip,
    faSolidPassport,
    faSolidPenToSquare,
    faSolidPersonMilitaryPointing,
    faSolidPlaneArrival,
    faSolidPlaneDeparture,
    faSolidPlay,
    faSolidPlus,
    faSolidPrint,
    faSolidQuestion,
    faSolidScrewdriverWrench,
    faSolidShare,
    faSolidShieldHalved,
    faSolidShip,
    faSolidSun,
    faSolidTableColumns,
    faSolidTags,
    faSolidThumbsDown,
    faSolidThumbsUp,
    faSolidThumbtack,
    faSolidTrain,
    faSolidTrash,
    faSolidTruck,
    faSolidTruckMoving,
    faSolidTruckRampBox,
    faSolidUpload,
    faSolidUser,
    faSolidUserPlus,
    faSolidUsers,
    faSolidWarehouse,
    faSolidXmark,
    simpleTesla
};

export default icons;

// Get icon key string from icons object and put it into type
type IconKey = keyof typeof icons;

export { IconKey };
