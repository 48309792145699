<app-content-header
    title="{{ 'Booking List' | marker }}"
    icon="faSolidList"
    [actionBar]="actionBar"></app-content-header>
<app-active-filters [listOfActiveFilters]="listOfActiveFilters" (clearFilter)="clearFilter($event)" />
<app-grid
    selectBy="row"
    [data]="query()"
    [nrOfActiveFilters]="nrOfActiveFilters"
    [cols]="columns"
    [buttons]="gridActions"
    [parentRoute]="parentRoute"
    [defaultViewMode]="defaultViewMode"
    [queryData]="search"
    [autoFitColumns]="true"
    [selectable]="true"
    (toggleFilter)="toggleFilter()"
    (saveGridSettings)="saveGridSettings($event)"
    (deleteGridSettings)="deleteGridSettings()"
    (selectedKeysChange)="onSelectedKeysChange($event)"
    (toggleFilter)="filterDialog(filterProperties)"
    (clearFilter)="clearFilter($event)"></app-grid>
