import { Component, Inject, Input } from '@angular/core';
import { CommonModule } from '@common/common.module';
import { GridCellContext, GridTemplateComponent } from '@common/interfaces/column-settings.interface';
import { ToastrNotificationService } from '@common/services/toastr-notification.service';
import { TranslateService } from '@ngx-translate/core';

export interface SizeInputs {
    height: string;
    length: string;
}

@Component({
    standalone: true,
    imports: [CommonModule],
    template: `
        <span>{{ length }} x {{ height }}</span>
    `
})
export class CellSizeComponent implements GridTemplateComponent, SizeInputs {
    context: GridCellContext<any>;
    @Input() height!: string;
    @Input() length!: string;
    toastrNotificationService = Inject(ToastrNotificationService);
    t = Inject(TranslateService);
}
