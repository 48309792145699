import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseViewComponent } from '@common/classes/base-view';
import { IconKey } from '@common/classes/icons';
import { BookingPermissions } from '@common/classes/permissions';
import { CodelistInputComponent } from '@common/components/input/codelist-input/codelist-input.component';
import { ColumnSettings } from '@common/interfaces/column-settings.interface';
import { BookingStatusCodes } from '@common/known-types/booking-status.codes';
import { BookingTypeCodes } from '@common/known-types/booking-type.codes';
import { CargoTypeCodes } from '@common/known-types/cargo-type.codes';
import { OrganizationTypeCodes } from '@common/known-types/organization-type.codes';
import { CommonService } from '@common/services/common.service';
import { BookingHaulier, BookingsCar, BookingVehicleEdit, GetCarsFromTinoReturn } from 'app/booking/bookings.interface';
import { BookingService } from '../../booking.service';
import { BookingViewDefinitionsService, EditablePropertyCode } from '../booking-view-definitions.service';
import { BookingViewUtilsService, confirmDriverResult } from '../booking-view-utils.service';
import { AddButtonCellComponent } from './add-button-cell.component';

interface VehiclesGridData {
    vehicle: string;
    serviceRequest: string;
    forwarderId: string;
}

@Component({
    selector: 'app-booking-car-view',
    templateUrl: './booking-car-view.component.html',
    providers: [CommonService]
})
export class BookingCarViewComponent extends BaseViewComponent implements OnInit {
    override editPermission = BookingPermissions.Car.Edit;
    override createPermission = BookingPermissions.Car.Edit;
    @ViewChild('haulier') haulier: CodelistInputComponent;
    @ViewChild('responsiblehaulier') responsiblehaulier: CodelistInputComponent;

    entityName = 'CarBooking';
    icon: IconKey = 'faSolidCarSide';
    filter = { vin: '', serviceRequest: '' };
    vehiclesData: GetCarsFromTinoReturn[];
    vehiclesDataGrid: VehiclesGridData[];
    override initialModel: Partial<BookingsCar> = {
        bookingStatusId: BookingStatusCodes.Reserved,
        cargoTypeId: CargoTypeCodes.CAR // R for RoRo
    };
    selectedHaulier: BookingHaulier = {
        //used to get organization type
        id: 0,
        types: [],
        isTemporary: false
    };
    isDriver: boolean = false;

    private orgTypes;

    override getTitle() {
        const entityTitle = this.translateService.instant(this.entityName.replace(/([A-Z])/g, ' $1').trim());
        return this.createMode
            ? `${this.translateService.instant('New')} ${entityTitle}`
            : `${this.translateService.instant('Car Booking')} ${this.getIdentifier()}`;
    }

    carColumns: ColumnSettings[] = this.def.carColumns;
    carEditColumns: ColumnSettings[] = [
        ...this.def.carEditColumns,
        {
            field: 'vehicle',
            title: '',
            templateComponent: AddButtonCellComponent,
            templateInputs: (row) => ({
                tooltip: 'Add Car',
                row,
                onClick: () => this.addCarToDischarge(row),
                isDisabled: !this.isPropertyEditable(EditablePropertyCode.cargo)
            })
        }
    ];

    constructor(
        common: CommonService,
        private def: BookingViewDefinitionsService,
        private util: BookingViewUtilsService,
        private bookingService: BookingService
    ) {
        super(common);
        this.actionBar.push(this.util.getActionBar(this));
        this.common.userService.getCurrentUser();
        this.orgTypes =
            this.common.userService.currentUserSubject.value?.organization?.types.map((x) => x.organizationType?.id) ??
            [];
        if (this.user.fullName == 'DriverLogin' /* || this.user.isDriver*/) {
            this.actionBar = [];
            this.isDriver = true;
        }
    }

    override ngOnInit() {
        super.ngOnInit();
        this.actionBar = [...this.actionBar]; // this triggers change detection in actionbar so that buttons can update (isHidden, isVisible, etc.)
    }

    override saveChanges(customData?: any): Promise<void> {
        if (!this.model.vehicles || this.model.vehicles.length == 0) {
            this.toastrNotificationService.show({
                type: 'error',
                title: this.common.translateService.instant('Error'),
                message: this.common.translateService.instant('At least one vehicle must be specified.')
            });
        } else {
            return super.saveChanges(customData);
        }

        return null;
    }

    addCarToDischarge(row: VehiclesGridData) {
        const vehicle = this.vehiclesData.find((v) => v.serialNumber === row.vehicle);

        if (!vehicle) {
            return;
        }

        // Create new vehicle for discharge
        const newVehicle: BookingVehicleEdit = {
            bookingType: BookingTypeCodes.DROP_OFF,
            vehicle: vehicle.serialNumber,
            serviceRequest: vehicle.serviceRequestNumber,
            carBookingId: 0,
            forwarderId: parseInt(vehicle.customer)
        };

        // Add to model.vehicles if it doesn't already exist
        if (!this.model.vehicles) {
            this.model.vehicles = [];
        }

        const exists = this.model.vehicles.some(
            (v) => v.vehicle === newVehicle.vehicle && v.serviceRequest === newVehicle.serviceRequest
        );

        if (!exists) {
            this.model.vehicles.push(newVehicle);
        }
    }

    removeTinoVehicle(vehicle: BookingVehicleEdit) {
        this.model.vehicles = this.model.vehicles.filter((x) => x.serviceRequest !== vehicle.serviceRequest);
    }

    override canEdit(): boolean {
        if (this.mode === 'create') {
            return true;
        }
        return this.util.canEdit(this.model);
    }

    async search() {
        this.vehiclesData = await this.bookingService.getCarsFromTino({
            serviceRequest: this.filter.serviceRequest,
            vin: this.filter.vin
        });

        this.vehiclesDataGrid = this.vehiclesData.map(
            (v): VehiclesGridData => ({
                forwarderId: v.customer,
                vehicle: v.serialNumber,
                serviceRequest: v.serviceRequestNumber
            })
        );
    }

    getVehicles(type: BookingTypeCodes): BookingVehicleEdit[] {
        return this.model.vehicles?.filter((item) => item.bookingType === type) ?? [];
    }

    confirm() {
        this.util.confirm(this.model).then((res: confirmDriverResult) => {
            switch (res) {
                case confirmDriverResult.nothing:
                    return;
                case confirmDriverResult.saveAndClose: {
                    this.saveChanges();
                    this.close();
                    break;
                }
                case confirmDriverResult.saveGoToPort: {
                    this.saveChanges();
                    this.util.AcceptGoToPort(this.model);
                    break;
                }
            }
        });
    }

    changeTime() {
        this.util.changeTime(this.model);
    }

    changePlates() {
        this.util.changePlates(this.model);
    }

    addSecondDriver() {
        this.util.addSecondDriver(this.model);
    }

    mergeBooking() {
        this.util.mergeBooking(this.model);
    }

    unmergeBooking() {
        this.util.unmergeBooking(this.model.id);
    }

    cancel() {
        this.util.cancel();
    }

    close() {
        this.util.close();
    }

    async onDriverChange() {
        this.util.onDriverChange(this.model, this.haulier, this.responsiblehaulier, this.createMode);
    }

    onHaulierChange() {
        if (this.createMode) {
            this.model.responsibleHaulierId = this.model.actualHaulierId;
            this.responsiblehaulier.value.set(this.model.actualHaulierId);
            this.responsiblehaulier.queryCodelist('');
        }
        if (this.model.actualHaulierId)
            this.util.getHaulierOrganizationType(this.model.actualHaulierId, this.selectedHaulier); //get if temporary Haulier
        this.model.driverId = null;
        this.model.additionalDriverId = null;
        this.model.truckId = null;
        this.model.trailerId = null;
    }

    isPropertyEditable(property: EditablePropertyCode) {
        return this.util.isPropertyEditable(this.def.editablePropertyMap, this.model, property);
    }

    isResponsibleHaulierEditable() {
        const haulierType = [
            OrganizationTypeCodes.Forwarder,
            OrganizationTypeCodes.ForwarderHaulier,
            OrganizationTypeCodes.Port
        ];
        return haulierType.some((type) => this.orgTypes.includes(type));
    }

    isDateFromEditable() {
        if (!this.model.vehicles) return true;
        //if any vehicle for loading('P') ChangeDate Disable
        if (this.model.vehicles?.some((item) => item.bookingType === BookingTypeCodes.PICK_UP)) return false;

        return true;
    }

    isDateToEditable() {
        if (!this.model.vehicles) return true;
        //if any vehicle for loading('P') ChangeDate Disable
        if (this.model.vehicles?.some((item) => item.bookingType === BookingTypeCodes.PICK_UP)) return false;
        if (this.model.vehicles?.every((item) => item.bookingType === BookingTypeCodes.DROP_OFF)) return false;
        return true;
    }

    onDateFromChange(_event) {
        const from = new Date(this.model.timeFrom);
        const to = new Date(this.model.timeFrom);
        const start = new Date(this.model.timeFrom);
        const end = new Date(this.model.timeFrom);
        to.setHours(from.getHours() + 6);
        start.setHours(6);
        end.setHours(20);

        if (from < start) {
            this.toastrNotificationService.show({
                type: 'error',
                title: this.common.translateService.instant('Error'),
                message: this.common.translateService.instant('Time From can not be before 6:00')
            });
            this.model.timeFrom = null;
            this.model.timeTo = null;
            return;
        }

        if (to > end) {
            this.toastrNotificationService.show({
                type: 'error',
                title: this.common.translateService.instant('Error'),
                message: this.common.translateService.instant('Time To can not be later than 20:00')
            });
            this.model.timeFrom = null;
            this.model.timeTo = null;
            return;
        }
        this.model.timeTo = to;
    }
}
